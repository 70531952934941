import React from 'react';
import ReactToPrint from 'react-to-print';
import { IPrintRed } from '../../utils/icons.utils';
import { useDispatch } from 'react-redux';
import { setPrintMod } from '../../features/defaultSlice';

const PrintButton = (props) => {
  const dispatch = useDispatch();
  const { componentRef, documentTitle } = props;

  return (
    <div>
      <ReactToPrint
       {...{documentTitle, onAfterPrint: () => dispatch(setPrintMod({enable: false})), onBeforeGetContent: async() => await dispatch(setPrintMod({enable: true})) }}
        trigger={() => (
					<button
						type="button"
						className="inline-flex h-[2.3rem] items-center rounded-md px-3  text-sm font-semibold text-dark_red bg-[#f5ebeb] hover:bg-[#ffe1e0]">
						<img
							src={IPrintRed}
							alt="IPrint"
							className="mr-1"
						/>
						Print
					</button>)}
        content={() => componentRef.current}
      />
    </div>
  );
};

export default PrintButton;