import Alert from "./alert";
import config from "./config";
import Storage from "./storage";

/**
 * @param {object} data   takes in an object of boolean and number values
 * @param {boolean} data.previewInConsole  whether to preview the data/size in the console, default is true
 * @param {number} data.size  the actual size of the data/file in byte, default is 50000000
 * @returns  {number}  The size of the data/file
 **/
export const getFileSize = function (data = {}) {
  data.previewInConsole = data.previewInConsole ? data.previewInConsole : false;
  data.size = data.size !== (undefined || null || "") ? data.size : 50000000; // 50mb
  data.size = Number(data.size);
  const k = 1000;
  const format = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(data.size) / Math.log(k));
  const size = parseFloat(data.size / Math.pow(k, i)).toFixed(1);

  if (data.previewInConsole === true)
    console.info(data.size, " = ", size + format[i]);
  return size + " " + format[i];
};

export const getAmount = (num) => new Intl.NumberFormat().format(num);

export function currencyFormat(num, currencySymbol) {
  return (
    currencySymbol + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
  );
}

export const Roles = {
  vendor: "vendor",
  admin: "admin",
  bank: "bank_staff",
  superAdmin: "super_admin",
  solicitor: "solicitor",
};

export const AppType = {
  cav: "Customer Address Verification",
  cs: "Corporate Search",
};

export const loggedInUser = Storage.get(config.authProps[1]);
export const getRole = () => {
  let user = {};
  if (loggedInUser) {
    user = JSON.parse(loggedInUser);
  }
  return user?.role;
};

export const getId = () => {
  let user = {};
  if (loggedInUser) {
    user = JSON.parse(loggedInUser);
  }
  return {
    bank: user?.userId,
    admin: user?.userId,
    vendor: user?.vendorId,
    user: user?.userId,
  };
};

export const validateEmail = (email) => {
  const regexExp =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;
  return regexExp.test(email);
};

export const capitalize = (string) => {
  const final = string?.replace(/\w\S*/g, (txt) => {
    let val = txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    return val;
  });
  return final;
};
export const camelCasedPathName = (_) =>
  document.location.pathname
    .replace("/", "")
    .split("-")
    .map((r, i) => (i > 0 ? capitalize(r) : r))
    ?.join("");

export function formatDate(dateStr) {
  const date = new Date(dateStr);
  const options = { month: "short", day: "numeric", year: "numeric" };
  const formattedDate = date.toLocaleDateString("en-US", options);
  return formattedDate;
}

export function formatTime(datetimeStr) {
  const datetime = new Date(datetimeStr);
  const time = datetime.toLocaleTimeString("en-US", {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
  });
  return time;
}

export const handleSearch = ({ value, useCaps, cb }) => {
  const branch = useCaps ? capitalize(value) : value;
  cb(branch.trim());
};

export const handleCopy = (data) => {
  if (!data || data.length === 0) {
    Alert({
      type: "info",
      message: "No data to copy.",
    });
    return;
  }

  const removeUnwantedColumn = (col) => col !== "_data";
  const headers = Object.keys(data[0]).filter(removeUnwantedColumn);
  const copiedData = data.map((d) =>
    headers
      .map((h) => {
        if (h === "SLA Compliance")
          d[h] = d[h]?.props?.children?.[1]?.props?.children || "N/A";
        return d[h];
      })
      .join("\t")
  );

  copiedData.unshift(headers.join("\t"));

  const textarea = document.createElement("textarea");
  textarea.style.cssText = "position: absolute; left: -9999px;";
  document.body.appendChild(textarea);

  textarea.innerHTML = copiedData.join("\n");
  textarea.select();
  document.execCommand("copy");
  document.body.removeChild(textarea);

  Alert({
    type: "success",
    message: "Copied to clipboard",
  });
};

export const jobsQueues = [
  { id: "COMPLETED", name: "Awaiting Payment", url: "#" },
  { id: "PENDING", name: "Pending Jobs", url: "#" },
  { id: "REJECTED", name: "Rejected Jobs", url: "#" },
  { id: "CLOSED", name: "Closed Jobs", url: "#" },
  { id: "PAID", name: "Paid Vendors", url: "#" },
  { id: "PENDING_ACKNOWLEDGEMENT", name: "Pending Acknowledgement", url: "#" },
  {
    id: "AWAITING_BRANCH_CONFIRMATION",
    name: "Awaiting Confirmation",
    url: "#",
  },
  {
    id: "AWAITING_VENDOR_ASSIGNMENT",
    name: "Awaiting Vendors Assignment",
    url: "#",
  },
];

export const JobStatus = [
  { id: "COMPLETED", name: "Awaiting Payment", url: "/awaiting-payment" },
  {
    id: "PENDING_JOBS",
    name: "Pending Jobs",
    url: "/pending-jobs",
  },
  {
    id: "AWAITING_VENDOR_ASSIGNMENT",
    name: "Awaiting Vendors Assignment",
    url: "/awaiting-vendor-assignment",
  },
  { id: "REJECTED", name: "Rejected Jobs", url: "/rejected-jobs" },
  { id: "CLOSED", name: "Closed Jobs", url: "/closed-jobs" },
  { id: "PAID", name: "Paid Vendors", url: "/paid-jobs" },
  {
    id: "AWAITING_MANUAL_ASSIGNMENT",
    name: "Manual Reassignment",
    url: "/manual/reassignment",
  },
  {
    id: "AWAITING_BRANCH_CONFIRMATION",
    name: "Awaiting Confirmation",
    url: "/awaiting-bank-confirmation",
  },
  {
    id: "PENDING_ACKNOWLEDGEMENT",
    name: "Pending Acknowledgement",
    url: "/pending-acknowledgement",
  },
];

export const MSAL_REDIRECT_URI = (() => {
  if (window.location.host.indexOf("localhost") >= 0) {
    return "http://localhost:3002/";
  } else {
    if (process.env.REACT_APP_MSAL_REDIRECT_URI) {
      return `${process.env.REACT_APP_MSAL_REDIRECT_URI}`;
    } else {
      return "https://nibsssandadmin.innovantics.com/";
    }
  }
})();

export const BACKEND_URL = (() => {
  if (window.location.host.indexOf("localhost") >= 0) {
    return "http://localhost:3009/api/v1/";
  } else {
    if (process.env.REACT_APP_BACKEND_URL) {
      return `${process.env.REACT_APP_BACKEND_URL}`;
    } else {
      return "https://nibsssandbackend.innovantics.com/";
    }
  }
})();

const {
  home,
  dashboard,
  pendingJobs,
  pendingAcknowledgment,
  rejectedJobs,
  closedJobs,
  awaitingBranchConfirmation,
  awaitingPayments,
  awaitingVendorAssignment,
  changePassword,
  forgotPassword,
} = config.routes;

export const vendorRoutes = [
  home,
  dashboard,
  pendingJobs,
  pendingAcknowledgment,
  rejectedJobs,
  awaitingPayments,
  changePassword,
  forgotPassword,
];
export const bankerRoutes = [
  ...vendorRoutes,
  closedJobs,
  awaitingBranchConfirmation,
  awaitingVendorAssignment,
];
