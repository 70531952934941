import React from "react";
import { useLocation } from "react-router-dom";
import HomeSidebar from "../components/Sidebars/Home";

const HomeLayout = ({
  children,
  title,
  subtitle,
  handleSearch,
  navigation,
  placeholder,
  value,
}) => {
  const { pathname } = useLocation();
  if (pathname !== "/") document.body.style.background = "#fdfcfc";

  return (
    <HomeSidebar
      {...{
        children,
        title,
        subtitle,
        handleSearch,
        navigation,
        placeholder,
        value,
      }}
    />
  );
};

export default HomeLayout;
