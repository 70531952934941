import { Textarea } from "@material-tailwind/react";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
} from "@mui/material";
import axios from "axios";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAuthData, uploadFile } from "../../features/auth/authSlice";
import {
  closeJob,
  confirmJob,
  getJobsData,
  rejectJob,
  setRejectAndReassignPayload,
} from "../../features/jobs/jobs";
import Alert from "../../utils/alert";
import { AppType, getId } from "../../utils/helper";
import { vrejectReassign } from "../../utils/validators";

const ViewJob = ({ data, refresh, openVendorAssignModal, appType }) => {
  const dispatch = useDispatch(),
    { rejectJobPayload, modal } = useSelector(getJobsData),
    { loading } = useSelector(getAuthData),
    [type, setType] = useState("reject");
  const [showReject, setShowReject] = useState("");

  const {
    values,
    handleBlur,
    handleChange,
    handleFocus,
    handleSubmit,
    errors,
    touched,
    setValues,
  } = useFormik({
    validationSchema: vrejectReassign,
    initialValues: rejectJobPayload,
    onSubmit: async (values) => {
      const payload = {
        ...values,
        accountIds: [data?.AcctRefID],
        bankUserId: getId()?.bank,
      };
      if (type === "reject") {
        const res = await dispatch(rejectJob(payload)).unwrap();
        res?.success && refresh?.();
      } else {
        dispatch(
          setRejectAndReassignPayload({
            comment: values.comment,
            bankRejectionImages: values.bankRejectionImages,
          })
        );
        openVendorAssignModal();
      }
    },
  });

  const handleConfirmJob = async () => {
    const payload = {
      accountIds: [data?.AcctRefID],
      bankUserId: getId()?.bank,
    };
    const res = await dispatch(confirmJob(payload)).unwrap();
    res?.success && refresh?.();
  };
  const handleCloseJob = async (_) => {
    const res = await dispatch(closeJob([data?.AcctRefID])).unwrap();
    res?.success && refresh?.();
  };

  const handleFileUpload = async (e) => {
    const config = {
      onUploadProgress: (e) => {},
      headers: { "Content-Type": "multipart/form-data" },
    };

    const formData = new FormData();
    if (e.target.files.length) {
      for (let file of e.target.files) {
        if (file.size > 6000000) {
          Alert({
            type: "error",
            message: "File size cannot be more than 6MB",
          });
          return;
        }
        formData.append("files[]", file);
      }

      const res = await dispatch(uploadFile({ formData, config })).unwrap();

      if (res?.success) {
        setValues({ ...values, bankRejectionImages: res?.data });
      }
    }
  };

  const handleDownload = async (url) => {
    try {
      const response = await axios.get(url, {
        responseType: "blob", // This is necessary for downloading files
      });

      // Create a Blob from the response data
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      // Create a link element
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "downloaded-file"; // Specify the file name for the download

      // Trigger the download by simulating a click on the link
      link.click();

      // Cleanup the object URL after the download starts
      URL.revokeObjectURL(link.href);

      // Alert the user that the download has started
      Alert({
        type: "success",
        message: "The download has started",
      });
    } catch (error) {
      console.error("Error downloading the file", error);
    }
  };

  return (
    <Box component="form" noValidate id={type} onSubmit={handleSubmit}>
      {!showReject && (
        <>
          <div className="flex justify-between flex-wrap w-full">
            <p className="font-[600]">Account Name</p>
            <p>{data?.AcctName}</p>
          </div>

          <br />
          {AppType.cav === appType ? (
            <>
              <div className="flex justify-between flex-wrap w-full">
                <p className="font-[600]">Customer Address</p>
                <p>{data?.AcctAddress}</p>
              </div>

              <br />
              <div className="flex justify-between flex-wrap w-full">
                <p className="font-[600]">Updated Address</p>
                <p>{data?.UpdatedAcctAddress || "N/A"}</p>
              </div>

              <br />
              <div className="flex justify-between flex-wrap w-full">
                <label htmlFor="" className="font-[600]">
                  Address Description
                </label>
                <p>{data?.AddressDescription || "N/A"}</p>
              </div>

              <br />
              <div className="flex justify-between w-full">
                <p className="font-[600]">Proof of Landmark</p>
                <p>{data?.AddressVerificationPhotos?.length ? "Yes" : "No"}</p>
              </div>

              {data?.AddressVerificationPhotos?.length > 0 && (
                <>
                  <br />
                  <div className="">
                    <p className="font-[600] mb-4">Landmark images</p>
                    <div className="flex">
                      {data?.AddressVerificationPhotos?.map((url, i) => (
                        <a
                          href={url}
                          alt={"landmark image " + i}
                          target="_blank"
                          rel="noreferrer"
                          download={false}
                        >
                          <img
                            key={i}
                            alt={i}
                            src={url}
                            className="mr-4"
                            style={{
                              width: "109px",
                              height: "109px",
                              borderRadius: "5px",
                            }}
                          />
                        </a>
                      ))}
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <div className="flex justify-between w-full">
                <p className="font-[600]">Customer RC Number</p>
                <p>{data?.RCNumber || "N/A"}</p>
              </div>

              <br />
              <div className="w-full">
                <p className="font-[600]">Solicitor's Report</p>
                {data?.SolicitorReport ? (
                  <button
                    onClick={() => handleDownload(data?.SolicitorReport)}
                    className="mt-2 flex items-end"
                    type="button"
                  >
                    <CloudDownloadIcon color="error" />
                    <span className="text-[#D70900] ml-1">
                      {" "}
                      Download Solicitor's Report{" "}
                    </span>
                  </button>
                ) : (
                  <p>Not Available</p>
                )}
              </div>
            </>
          )}

          <br />
          <div className="flex justify-between w-full">
            <p className="font-[600]">Vendor’s Comment</p>
            <p>{data?.CommentsVendor || "N/A"}</p>
          </div>
        </>
      )}
      {showReject && (
        <>
          <div>
            <label htmlFor="comment" className="font-[600] text-sm">
              Rejection Comment
            </label>
            <FormControl className="m-0 p-0">
              <Textarea
                name="comment"
                id="comment"
                value={values?.comment}
                {...{
                  onChange: handleChange,
                  onBlur: handleBlur,
                  onFocus: handleFocus,
                }}
                rows="3"
                type="text"
                className="w-full border border-non outline-none  resize-none  border-[#CBD5E1] placeholder:text-[#CBD5E1] p-0 m-0 rounded"
                placeholder="Placeholder"
                error={errors.comment && touched.comment}
                color={errors.comment && touched.comment && "red"}
                variant="outlined"
                cols={100}
                style={{ backgroundColor: "#F8FAFC", mb: 0 }}
                size="lg"
              />
              {errors.comment && touched.comment && (
                <FormHelperText
                  variant="standard"
                  error={errors.comment && touched.comment}
                  className="py-1 bg-[#F8FAFC] text-[0.75rem] px-[14px] leading-[1.66] font-normal"
                >
                  {errors.comment}
                </FormHelperText>
              )}
            </FormControl>
          </div>
          {AppType.cs === appType && (
            <>
              <br />
              <div className="relative mb-2">
                <input
                  type="file"
                  onChange={handleFileUpload}
                  className="w-0 cursor-pointer invisible absolute"
                  id="bankRejectionImages"
                  name="bankRejectionImages"
                />
                <p className="font-[600] text-sm">Bank Rejection Image</p>
                <div className="flex items-center">
                  <label
                    htmlFor="bankRejectionImages"
                    className="block text-[#D70900] cursor-pointer mr-1"
                  >
                    <CloudUploadIcon color="red" /> <span>Upload Image</span>{" "}
                  </label>
                  <small className="text-[12px] text-[#a5240afa]">
                    - Maximum of 6MB
                  </small>
                </div>
              </div>

              {loading ? (
                <div className="w-[109px] border inline-flex h-[109px]  rounded  justify-center items-center relative transition duration-150 ease-in-out">
                  <CircularProgress variant="indeterminate" size="2em" />
                </div>
              ) : (
                values.bankRejectionImages?.[0] && (
                  <img
                    alt={values.bankRejectionImages?.[0]}
                    src={values.bankRejectionImages?.[0]}
                    style={{
                      width: "109px",
                      height: "109px",
                      borderRadius: "5px",
                    }}
                  />
                )
              )}
            </>
          )}
        </>
      )}

      <br />
      <div className="flex capitalize">
        <div className="inline-block">
          {!showReject && (
            <LoadingButton
              loading={modal.loader.closedJobs}
              onClick={handleCloseJob}
              variant="contained"
              color="error"
              className="capitalize mr-1"
              style={{ textTransform: "capitalize", margin: "1em" }}
            >
              Move to closed jobs
            </LoadingButton>
          )}

          {showReject ? (
            <>
              <Button
                // loading={modal.loader.closedJobs}
                onClick={() => setShowReject(false)}
                variant="contained"
                color="error"
                type="button"
                className="capitalize mr-1"
                style={{ textTransform: "capitalize", margin: "1em" }}
              >
                Back
              </Button>
              {showReject === "reassign" && (
                <Button
                  type="submit"
                  onClick={(_) => setType("reject_reassign")}
                  variant="text"
                  sx={{
                    height: "2.3rem",
                    alignItems: "center",
                    px: "1em",
                    fontSize: "semibold",
                    color: "red",
                    background: "#f5ebeb",
                    textTransform: "capitalize",
                  }}
                >
                  Continue
                </Button>
              )}

              {showReject === "reject" && (
                <LoadingButton
                  loading={modal.loader.reject}
                  onClick={(_) => setType("reject")}
                  type="submit"
                  variant="outlined"
                  color="error"
                  className="capitalize"
                  style={{ textTransform: "capitalize", margin: "1em" }}
                >
                  Submit
                </LoadingButton>
              )}
            </>
          ) : (
            <>
              <Button
                type="button"
                onClick={(_) => setShowReject("reassign")}
                variant="text"
                sx={{
                  height: "2.3rem",
                  alignItems: "center",
                  px: "1em",
                  fontSize: "semibold",
                  color: "red",
                  background: "#f5ebeb",
                  textTransform: "capitalize",
                }}
              >
                {" "}
                Reject & Reassign{" "}
              </Button>

              <LoadingButton
                // loading={modal.loader.reject}
                type="button"
                onClick={(_) => setShowReject("reject")}
                variant="outlined"
                color="error"
                className="capitalize"
                style={{ textTransform: "capitalize", margin: "1em" }}
              >
                Reject
              </LoadingButton>
            </>
          )}

          {!showReject && (
            <LoadingButton
              onClick={handleConfirmJob}
              loading={modal.loader.confirm}
              type="button"
              variant="outlined"
              color="error"
              className="capitalize"
              style={{ textTransform: "capitalize" }}
            >
              Confirm
            </LoadingButton>
          )}
        </div>
      </div>
    </Box>
  );
};

export default ViewJob;
