/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from "@material-tailwind/react";
import CurtainsClosedIcon from "@mui/icons-material/CurtainsClosed";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import RecyclingIcon from "@mui/icons-material/Recycling";
import { Button, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Search from "../../components/forms/Search";
import ConfirmationModal from "../../components/modals/confirmationModal";
import CustomModal from "../../components/modals/Index";
import PrintButton from "../../components/others/Print";
import SelectButton from "../../components/others/SelectBtn";
import TableLayout from "../../components/table/CustomTable";
import { getAuthData, setModal } from "../../features/auth/authSlice";
import { getDefaultState, setPrintMod } from "../../features/defaultSlice";
import { closeJob, getJobsData } from "../../features/jobs/jobs";
import {
  closeModal,
  fetchManualReassignment,
  getManualReassignmentData,
  openModal,
  setCurrentJob,
  setPagination,
} from "../../features/jobs/manualReassignment";
import { clearVendorData } from "../../features/vendor/vendor";
import HomeLayout from "../../layouts/Home";
import {
  AppType,
  Roles,
  capitalize,
  getId,
  getRole,
  handleCopy,
} from "../../utils/helper";
import { ICopy } from "../../utils/icons.utils";
import VendorAssignment from "./VendorAssignment";

const ManualReassignment = () => {
  const tableRef = useRef();
  const dispatch = useDispatch(),
    { ...jobsData } = useSelector(getJobsData),
    {
      manualReassignment,
      loading,
      pagination,
      modal,
      downloadPdfUrl,
      downloadExcelUrl,
      currentJob,
    } = useSelector(getManualReassignmentData),
    { appType, ...authData } = useSelector(getAuthData),
    [checkboxData, setcheckboxData] = useState([]),
    [adminAction, setAdminAction] = useState({}),
    [showText, setShowText] = useState(true),
    { printMod } = useSelector(getDefaultState);

  const { state } = useLocation();

  useEffect(() => {
    dispatch(clearVendorData());
    if (state) {
      dispatch(setPagination({ page: 1, search: state }));
    } else dispatch(setPagination({ page: 1, search: "" }));
    dispatch(setPrintMod({ pageTitle: "Manual Reassignments" }));
  }, []);

  const handleSearch = ({ target: { value } }) => {
    dispatch(setPagination({ ...pagination, search: value }));
  };

  useEffect(() => {
    let cb = () => {};
    if (pagination?.search) {
      dispatch(setPagination({ page: 1 }));
      cb = setTimeout(
        (_) => (async () => await dispatch(fetchManualReassignment()))(),
        700
      );
    } else cb = (async () => await dispatch(fetchManualReassignment()))();

    return () => {
      clearTimeout(cb);
    };
  }, [pagination.search, pagination.page, pagination.pageSize]);

  let data = manualReassignment?.map((d) => {
    let model = {};

    if (AppType.cs === appType) {
      model = {
        "CS ID": d?.AcctRefID,
        "Account Number": d?.AcctNumber || "N/A",
        "Account Name": capitalize(d?.AcctName) || "N/A",
        "RC Number": d?.RCNumber || "N/A",
        "Date of Incorporation": d?.DateOfIncorporation
          ? dayjs(d?.DateOfIncorporation).format("MMM DD, YYYY")
          : "N/A",
        State: capitalize(d?.State?.Name) || "N/A",
        "Vendor's Name":
          capitalize(
            d?.AssignedVendor?.VendorPreferredName ||
              d?.AssignedVendor?.VendorPreferredName ||
              d?.AssignedVendor?.VendorName ||
              d?.VendorJobs?.[0]?.Vendor?.VendorPreferredName ||
              d?.VendorJobs?.[0]?.Vendor?.VendorName
          ) || "N/A",
      };
    } else {
      model = {
        "CAV ID": d?.AcctRefID,
        "Account Number": d?.AcctNumber || "N/A",
        "Account Name": capitalize(d?.AcctName) || "N/A",
        Address: capitalize(d?.AcctAddress) || "N/A",
        State: capitalize(d?.State?.Name) || "N/A",
        "Vendor's Name":
          capitalize(
            d?.AssignedVendor?.VendorPreferredName ||
              d?.AssignedVendor?.VendorName ||
              d?.VendorJobs?.[0]?.Vendor?.VendorPreferredName ||
              d?.VendorJobs?.[0]?.Vendor?.VendorName
          ) || "N/A",

        ...(getRole() !== Roles.vendor && {
          "Assigned By": d?.InitiatedByUser?.firstName
            ? `${d?.InitiatedByUser?.firstName} ${d?.InitiatedByUser?.lastName}`
            : "N/A",
        }),
        "Assigned Date": d?.DateAssigned
          ? dayjs(d?.DateAssigned).format("DD/MM/YY, hh:mm A")
          : "N/A",
      };
    }
    model._data = d;

    return model;
  });

  let pdf = "account/jobs/download/for-manual-reassignment/pdf",
    excel = "account/jobs/download/for-manual-reassignment/excel";
  if (appType === AppType.cs) {
    excel = "corporate/account/admin/manual-reassignment/download/excel";
    pdf = "corporate/account/admin/manual-reassignment/download/pdf";
  }
  const options = [
    {
      name: "PDF",
      url:
        downloadPdfUrl +
        pdf +
        `?searchTerm=${pagination.search}&pageSize=${pagination.pageSize}&page=${pagination.page}`,
    },
    {
      name: "CSV",
      url:
        downloadExcelUrl +
        excel +
        `?searchTerm=${pagination.search}&pageSize=${pagination.pageSize}&page=${pagination.page}`,
    },
  ];

  const dropdownData = {
    visible: true,
    type: "text",
    text: (
      <div>
        <span className={"px-3 py-2 rounded-full text-blue-600 bg-blue-100"}>
          <span
            id="circle__"
            className="rounded-full inline-block mr-2"
            style={{ border: `4px solid blue` }}
          ></span>
          Reassign
        </span>
      </div>
    ),
    action: (e, state) => {
      dispatch(openModal());
      dispatch(setCurrentJob(state?._data));
    },
  };

  const refresh = async () => {
    dispatch(setModal({ open: false }));
    setcheckboxData([]);
    await dispatch(fetchManualReassignment());
  };

  const checkboxAction = {
    setValues: (data) => setcheckboxData(data),
    values: checkboxData,
  };

  const initBulkAction = (meta, idx) => {
    if (idx) {
      setAdminAction(meta);
      dispatch(setModal({ close: true }));
    } else meta.action();
  };
  const buttonMeta = [
    {
      title: "Reassign",
      Icon: RecyclingIcon,
      action: (_) => dispatch(setModal({ open: true })),
    },
    {
      title: "Close",
      Icon: CurtainsClosedIcon,
      action: closeJob,
      loading: jobsData.modal.loader.closedJobs,
    },
  ];

  const buttonStyle = useCallback(
    (i) => ({
      borderRadius: 0,
      minWidth: "50px",
      border: `${showText ? "0px" : "1px"} solid red`,
      borderRight:
        i < buttonMeta.length - 1 && `${showText ? "1" : "0"}px solid red`,
      textTransform: "capitalize",
      fontSize: "26px",
      "&:hover": { backgroundColor: "#D70900", color: "white" },
      ".MuiLoadingButton-loadingIndicatorCenter": {
        color: "var(--C_primary) !important",
      },
      "&:disabled": {
        border: `${showText ? "0px" : "1px"} solid grey`,
        borderRight:
          i < buttonMeta.length - 1 && `${showText ? "1" : "0"}px solid grey`,
        textTransform: "capitalize",
      },
    }),
    [showText]
  );

  return (
    <HomeLayout {...{ handleSearch }}>
      <div className="flex items-end flex-wrap gap-2 justify-between">
        <div className="">
          <Typography className="text-[24px] font-[500]">
            Manual Reassignment
          </Typography>
          <Typography className="text-[18px] font-[400]">
            Accounts pending Manual Reassignment.
          </Typography>
        </div>
        <div className="flex flex-wrap md:justify-end gap-4 md:mt-0 mt-3">
          <button
            onClick={(_) => handleCopy(data)}
            className="h-[2.3rem] flex  items-center rounded-md px-3 border-2 border-[#E6E6E6] text-sm font-semibold text-dark_red "
          >
            <img src={ICopy} alt="ICopy" />
            Copy
          </button>
          <PrintButton
            {...{
              componentRef: tableRef,
              documentTitle: printMod.pageTitle,
            }}
          />
          <SelectButton
            btTextClass="bg-dark_red text-white hover:bg-light_red"
            options={options}
            title="Download"
          />
        </div>
      </div>
      <div>
        <div className="mt-5 flex justify-between items-center">
          {[Roles.admin, Roles.superAdmin].includes(getRole()) && (
            <div className="flex">
              {buttonMeta.map((meta, i) => (
                <Tooltip
                  key={i}
                  className="py-0 px-0"
                  title={meta.title + " Jobs"}
                  placement="top"
                >
                  <span>
                    <Button
                      onClick={(_) => initBulkAction(meta, i)}
                      color="error"
                      disabled={checkboxData.length ? false : true}
                      sx={(_) => buttonStyle(i, showText)}
                    >
                      <meta.Icon />{" "}
                      {showText && (
                        <span className="text-[15px] ml-1"> {meta.title} </span>
                      )}
                    </Button>
                  </span>
                </Tooltip>
              ))}

              <Tooltip
                className="py-0 px-0"
                title={showText ? "Collapse" : "Expand"}
                placement="top"
              >
                <Button
                  color="error"
                  onClick={(_) => setShowText(!showText)}
                  sx={{ minWidth: "20px", borderRadius: "30px", ml: "2em" }}
                  className="ml-5"
                >
                  {showText ? <NavigateBeforeIcon /> : <NavigateNextIcon />}
                </Button>
              </Tooltip>
            </div>
          )}
          <Search
            {...{
              value: pagination.search,
              onSearch: handleSearch,
              width: "300px",
            }}
          />
        </div>

        <TableLayout
          {...{
            printMod: { ...printMod, ref: tableRef },
            pagination,
            setPagination,
            isLoading: loading,
            checkboxAction:
              [Roles.admin, Roles.superAdmin].includes(getRole()) &&
              checkboxAction,
            data,
            action: dropdownData,
            tableMsg: ["No Manual Reassignments", ""],
          }}
        />
      </div>

      <CustomModal
        {...{
          title: "Reassign Manually",
          close: (_) => dispatch(closeModal()),
          open: modal.open,
        }}
      >
        <VendorAssignment
          {...{
            closeModal: (_) => dispatch(closeModal()),
            data: currentJob,
            refresh: (_) => {
              dispatch(closeModal());
              dispatch(fetchManualReassignment());
            },
          }}
        />
      </CustomModal>

      {[Roles.admin, Roles.superAdmin].includes(getRole()) && (
        <>
          <CustomModal
            {...{
              title: `Reassign ${checkboxData.length} ${
                checkboxData.length > 1 ? "Jobs" : "Job"
              } Manually`,
              close: (_) => dispatch(setModal({ open: false })),
              open: authData.modal.open,
            }}
          >
            <VendorAssignment
              {...{
                closeModal: (_) => dispatch(setModal({ open: false })),
                data: checkboxData,
                refresh,
              }}
            />
          </CustomModal>

          <ConfirmationModal
            {...{
              open: authData.modal.close,
              close: (_) => dispatch(setModal({ close: false })),
              refresh,
              data:
                adminAction.title === "Close"
                  ? checkboxData?.map((d) => d?.AcctRefID)
                  : {
                      bankUserId: getId().admin,
                      accountIds: checkboxData?.map((d) => d?.AcctRefID),
                    },
              btnTexts: ["Cancel", adminAction.title],
              title: adminAction.title + " Jobs",
              loading: jobsData.modal.loader.closedJobs,
              subtitle: `Are you sure you want to ${adminAction?.title?.toLowerCase()} ${
                checkboxData.length > 1 ? "these" : "this"
              } ${checkboxData.length > 1 ? checkboxData.length : ""} ${
                checkboxData.length > 1 ? "jobs" : "job"
              }?`,
              action: adminAction.action,
            }}
          />
        </>
      )}
    </HomeLayout>
  );
};

export default ManualReassignment;
