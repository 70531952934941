import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import React, { Fragment } from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function SelectButton({
  options,
  btTextClass,
  title,
  onClick,
  download = true,
}) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div className={`${btTextClass} rounded-md`}>
        <Menu.Button
          className={`inline-flex w-full justify-center gap-x-1.5 rounded-md  px-3 py-2 text-sm font-semibold ${btTextClass} `}
        >
          {title}
          <ChevronDownIcon className="-mr-1 h-5 w-5 " />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 sm:w-56 min-w-[6rem] max-w-[14rem] origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none max-h-56 overflow-y-auto">
          {options?.map((option) => (
            <Menu.Item key={option.name}>
              {({ active }) => (
                <a
                  href={option.url}
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "group flex items-center px-4 py-2 text-sm"
                  )}
                  onClick={(_) => onClick?.(option)}
                  download={download}
                >
                  {option.icon && (
                    <option.icon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                  )}
                  {option.name}
                </a>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default SelectButton;
