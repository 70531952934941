/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import ProtectedRoutes from "./components/others/ProtectedRoutes";
import RBAC from "./components/others/RBAC";
import { logout, logoutFromServer } from "./features/auth/authSlice";
import Pages from "./pages/Index";
import ChangePassword from "./pages/login/ChangePassword";
import ForgotPassword from "./pages/login/ForgotPassword";
import Auth from "./pages/login/Login";
import Alert from "./utils/alert";
import config from "./utils/config";

function App() {
  const { routes } = config;
  window.addEventListener("offline", (_) => console.log("offline"));
  window.addEventListener("online", (_) => console.log("online"));

  const eta = 600; // = 10mins | 900 = 15mins
  const [timer, setTimer] = useState(eta);

  const resetTimer = useCallback(
    (_) => {
      if (window.location.pathname !== routes.login) {
        setTimer((state) => {
          if (state > 0 && state <= 10)
            Alert({
              type: "success",
              message: "Interaction detected: User is now active.",
            });
          return eta;
        });
      }
    },
    [timer]
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (window.location.pathname !== routes.login) {
      if (timer === 10) {
        Alert({
          type: "info",
          timer: 10000,
          message: `Idleness detected: Logging you out in ${timer} seconds!`,
        });
      }

      let idleInterval = setInterval(async () => {
        if (timer > 0) {
          setTimer((timer) => timer - 1);
        } else {
          try {
            Alert({ type: "info", message: "Logging you out now..." });
            const res = await dispatch(logoutFromServer()).unwrap();
            if (res.success) {
              dispatch(logout());
              clearInterval(idleInterval);
              window.location.href = "/";
            }
          } catch (error) {
            console.error(error);
          }
        }
      }, 1000);

      return () => {
        clearInterval(idleInterval);
      };
    }
  }, [timer]);

  document.addEventListener("click", resetTimer);
  document.addEventListener("keypress", resetTimer);
  document.body?.querySelector(".App")?.addEventListener("scroll", resetTimer);

  // Init Role Based Access Control
  RBAC();

  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route
            {...{
              path: routes.login,
              element: <Auth />,
            }}
          />
          <Route
            {...{
              path: routes.changePassword,
              element: <ChangePassword />,
            }}
          />
          <Route
            {...{
              path: routes.forgotPassword,
              element: <ForgotPassword />,
            }}
          />

          <Route element={<ProtectedRoutes />}>
            {/* <Route element={<RBAC />}> */}
            {Pages.map((p, i) => (
              <Route key={i} {...p} />
            ))}
            {/* </Route> */}
          </Route>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
