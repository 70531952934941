/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from "@material-tailwind/react";
import React, { useMemo } from "react";
import CardComp from "../../components/others/CardComp";
import HomeLayout from "../../layouts/Home";
// import { JobsColorData } from "../../utils/fakeData";
import { useSelector } from "react-redux";
import PieChart from "../../components/others/PieChart";
import { getAuthData } from "../../features/auth/authSlice";
import { getDashboardData } from "../../features/dashboard/dashboard";
import { AppType, getAmount } from "../../utils/helper";

const VendorsDash = () => {
  const { stats, loading } = useSelector(getDashboardData),
    { preloading, appType } = useSelector(getAuthData);

  const totalJobs = useMemo(() => {
    if ("totalJobs" in stats) {
      const unwantedJobsTotal =
        appType === AppType.cs
          ? stats?.closedJobs + stats?.jobsAwaitingBranchConfirmation
          : 0;
      return stats?.totalJobs - unwantedJobsTotal;
    }
  }, [loading, stats]);

  const data = useMemo(() => {
    const result = [
      {
        title: "Total Pending Jobs",
        amount: getAmount(stats?.pendingJobs || 0),
        rate: "32",
        id: 1,
      },
      {
        title: "Total Jobs Awaiting Payments",
        amount: getAmount(stats?.jobsAwaitingPayment || 0),
        rate: "35",
        id: 2,
      },
      {
        title: "Total Jobs",
        amount: totalJobs || 0,
        rate: "40",
        id: 3,
      },
      {
        title: "Total Count Of Paid Jobs",
        amount: getAmount(stats?.totalCountOfPaidJobs || 0),
        rate: "34",
        id: 4,
      },
      {
        title: "Total Unsuccessful Jobs",
        amount: getAmount(stats?.jobsAwaitingBranchConfirmation || 0),
        rate: "38",
        id: 5,
      },
      {
        title: "Total Jobs Pending Acknowledgement",
        amount: getAmount(stats?.jobsPendingAcknowledgement || 0),
        rate: "33",
        id: 6,
      },
    ];
    if (AppType.cs === appType) delete result[result.length - 2];
    return result;
  }, [loading, stats]);

  const JobsChartData = useMemo(() => {
    return [
      {
        value: stats?.totalRejectedJobs,
        name: loading
          ? "..."
          : `${stats?.totalRejectedJobs || 0} Rejected Jobs`,
      },
    ];
  }, [loading, stats, preloading]);

  return (
    <HomeLayout>
      <div className="mb-5">
        <Typography className="text-[24px] font-[500]">Dashboard</Typography>
        <Typography className="text-[18px] font-[400]">Overview</Typography>
      </div>
      <div className="mb-8">
        <div className="md:grid lg:grid-cols-3 md:grid-cols-2 gap-6">
          {data?.map((data) =>
            data.id === 3 ? (
              <div className="lg:my-0 my-6 row-span-2 md:h-full h-[220px] px-5 py-4 bg-white rounded-[22px] border border-[#EFF0F6]">
                <h2 className="text-[17px] font-[600] tracking-[0.18px]">
                  Total Jobs
                </h2>
                <h5 className="text-[22px] font-[700] mt-4">
                  {loading ? "..." : getAmount(totalJobs || 0)}
                </h5>
                <div className="w-full">
                  <PieChart
                    id="vendorDash"
                    data={JobsChartData}
                    color={["#D70900"]}
                    pieChartClass="w-[430px] h-[120px]"
                  />
                </div>
              </div>
            ) : (
              <CardComp
                title={data.title}
                amount={data.amount}
                key={data.id}
                rate={data.rate}
                loading={loading}
              />
            )
          )}
        </div>
      </div>
    </HomeLayout>
  );
};

export default VendorsDash;
