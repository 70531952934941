/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from "@material-tailwind/react";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import RecyclingIcon from "@mui/icons-material/Recycling";
import { Button, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Search from "../../components/forms/Search";
import CustomModal from "../../components/modals/Index";
import MoveJobs from "../../components/modals/MoveJobs";
import PrintButton from "../../components/others/Print";
import SelectButton from "../../components/others/SelectBtn";
import TableLayout from "../../components/table/CustomTable";
import { getAuthData } from "../../features/auth/authSlice";
import { getDefaultState, setPrintMod } from "../../features/defaultSlice";
import {
  closeModal,
  fetchData,
  getDownloadData,
  getJobsData,
  openModal,
  setPagination,
} from "../../features/jobs/jobs";
import { clearVendorData } from "../../features/vendor/vendor";
import HomeLayout from "../../layouts/Home";
import {
  AppType,
  Roles,
  camelCasedPathName,
  capitalize,
  getRole,
  handleCopy,
} from "../../utils/helper";
import { ICopy } from "../../utils/icons.utils";
import VendorAssignment from "../manualReassignment/VendorAssignment";

const ClosedJobs = () => {
  const tableRef = useRef();
  const dispatch = useDispatch(),
    { currentJob, modal, loading, pagination, downloadData, ...jobsData } =
      useSelector(getJobsData),
    { pathname, state } = useLocation(),
    { appType } = useSelector(getAuthData),
    { printMod } = useSelector(getDefaultState),
    [checkboxData, setCheckboxData] = useState([]),
    closedJobs = jobsData[camelCasedPathName()];

  useEffect(() => {
    dispatch(clearVendorData());
    if (state) {
      dispatch(setPagination({ page: 1, search: state }));
    } else dispatch(setPagination({ page: 1, search: "" }));
    dispatch(setPrintMod({ pageTitle: "Closed Jobs" }));
  }, []);

  const handleSearch = ({ target: { value } }) => {
    dispatch(setPagination({ ...pagination, search: value }));
  };

  useEffect(() => {
    dispatch(
      getDownloadData({
        url: pathname,
        type: appType === AppType.cs ? "corporate/" : "",
      })
    );
    let cb = () => {};
    if (pagination?.search) {
      dispatch(setPagination({ page: 1 }));
      cb = setTimeout((_) => (async () => await dispatch(fetchData()))(), 700);
    } else cb = (async () => await dispatch(fetchData()))();

    return () => {
      clearTimeout(cb);
    };
  }, [pagination.search, pagination.page, pagination.pageSize]);

  let data = closedJobs?.map((d) => {
    let model = {};

    if (AppType.cs === appType) model["CS ID"] = d?.AcctRefID;
    else model["CAV ID"] = d?.AcctRefID;

    model["Account Number"] = d?.AcctNumber || "N/A";
    model["Account Name"] = capitalize(d?.AcctName) || "N/A";

    if (AppType.cs === appType) model["RC Number"] = d?.RCNumber || "N/A";
    if (AppType.cs === appType)
      model["Date of Incorporation"] = d?.DateOfIncorporation
        ? dayjs(d?.DateOfIncorporation).format("MMM DD, YYYY")
        : "N/A";
    else {
      model["Address"] = capitalize(d?.AcctAddress) || "N/A";
      model["Updated Address"] = capitalize(d?.UpdatedAcctAddress) || "N/A";
    }

    model = {
      ...model,
      State: capitalize(d?.State?.Name) || "N/A",
      "Vendor's Name":
        capitalize(
          d?.AssignedVendor?.VendorPreferredName ||
            d?.AssignedVendor?.VendorName ||
            d?.VendorJobs?.[0]?.Vendor?.VendorPreferredName ||
            d?.VendorJobs?.[0]?.Vendor?.VendorName
        ) || "N/A",
      ...(getRole() !== Roles.vendor && {
        "Assigned By": d?.InitiatedByUser?.firstName
          ? `${d?.InitiatedByUser?.firstName} ${d?.InitiatedByUser?.lastName}`
          : "N/A",
      }),
      _data: d,
    };

    if (AppType.cs !== appType)
      model["Assigned Date"] = d?.DateAssigned
        ? dayjs(d?.DateAssigned).format("DD/MM/YY, hh:mm A")
        : "N/A";

    if (AppType.cs === appType)
      model["Closed Date"] = d?.DateClosed
        ? dayjs(d?.DateClosed).format("MMM DD, YYYY")
        : "N/A";

    model._data = d;

    return model;
  });

  const checkboxAction = {
    setValues: (data) => setCheckboxData(data),
    values: checkboxData,
  };

  const refresh = async () => {
    dispatch(closeModal());
    setCheckboxData([]);
    await dispatch(fetchData());
  };

  const buttonStyle = {
    borderRadius: 0,
    minWidth: "50px",
    textTransform: "capitalize",
    fontSize: "26px",
    "&:hover": { backgroundColor: "#D70900", color: "white" },
  };

  return (
    <HomeLayout {...{ handleSearch, value: pagination.search }}>
      <div className="flex items-end flex-wrap gap-2 justify-between">
        <div className="">
          <Typography className="text-[24px] font-[500]">
            Closed Jobs
          </Typography>
          <Typography className="text-[18px] font-[400]">
            Accounts not awaiting vendor's verification.
          </Typography>
        </div>
        <div className="flex flex-wrap md:justify-end gap-4 md:mt-0 mt-3">
          <button
            onClick={(_) => handleCopy(data)}
            className="h-[2.3rem] flex  items-center rounded-md px-3 border-2 border-[#E6E6E6] text-sm font-semibold text-dark_red "
          >
            <img src={ICopy} alt="ICopy" />
            Copy
          </button>
          <PrintButton
            {...{ componentRef: tableRef, documentTitle: printMod.pageTitle }}
          />
          <SelectButton
            btTextClass="bg-dark_red text-white hover:bg-light_red"
            options={downloadData}
            title="Download"
          />
        </div>
      </div>
      <div className="mt-5">
        <div className="flex justify-between items-center">
          {[Roles.admin, Roles.superAdmin].includes(getRole()) && (
            <div className="flex mt-5 flex-wrap gap-y-5">
              <Tooltip
                className="py-0 px-0"
                title={"Reassign Jobs"}
                placement="top"
              >
                <span>
                  <Button
                    onClick={(_) => dispatch(openModal())}
                    color="error"
                    disabled={checkboxData.length ? false : true}
                    sx={{
                      ...buttonStyle,
                      borderRight: "0px solid transparent",
                    }}
                  >
                    <RecyclingIcon />
                    <span className="text-[15px] ml-1"> Reassign </span>
                  </Button>
                </span>
              </Tooltip>

              <Button
                onClick={(_) => {
                  dispatch(openModal("close"));
                }}
                color="error"
                disabled={checkboxData.length ? false : true}
                sx={{
                  ...buttonStyle,
                  borderLeft: "1px solid red",
                  "&:disabled": {
                    borderLeft: `1px solid grey`,
                  },
                }}
              >
                <DriveFileMoveIcon />{" "}
                <span className="text-[15px] ml-1">Move Jobs</span>
              </Button>
            </div>
          )}

          <Search
            {...{
              value: pagination.search,
              onSearch: handleSearch,
              width: "300px",
            }}
          />
        </div>

        <TableLayout
          {...{
            printMod: { ...printMod, ref: tableRef },
            pagination,
            setPagination,
            isLoading: loading,
            data,
            checkboxAction:
              [Roles.admin, Roles.superAdmin].includes(getRole()) &&
              checkboxAction,
            tableMsg: ["No Closed Jobs"],
          }}
        />
      </div>

      {[Roles.admin, Roles.superAdmin].includes(getRole()) && (
        <>
          <CustomModal
            {...{
              title: `Reassign ${checkboxData.length} ${
                checkboxData.length > 1 ? "Jobs" : "Job"
              } Manually`,
              close: (_) => dispatch(closeModal()),
              open: modal.open,
            }}
          >
            <VendorAssignment
              {...{
                closeModal: (_) => dispatch(closeModal()),
                data: checkboxData,
                refresh,
              }}
            />
          </CustomModal>

          <CustomModal
            {...{
              title: `Move ${checkboxData.length} ${
                checkboxData.length > 1 ? "Jobs" : "Job"
              }`,
              open: modal.close,
              close: (_) => dispatch(closeModal("close")),
            }}
          >
            <MoveJobs
              {...{
                closeModal: (_) => dispatch(closeModal("close")),
                data: checkboxData,
                refresh,
              }}
            />
          </CustomModal>
        </>
      )}
    </HomeLayout>
  );
};

export default ClosedJobs;
