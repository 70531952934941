/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from "@material-tailwind/react";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Search from "../../components/forms/Search";
import ConfirmationModal from "../../components/modals/confirmationModal";
import CustomModal from "../../components/modals/Index";
import MoveJobs from "../../components/modals/MoveJobs";
import PrintButton from "../../components/others/Print";
import SelectButton from "../../components/others/SelectBtn";
import TableLayout from "../../components/table/CustomTable";
import { getAuthData, setModal } from "../../features/auth/authSlice";
import { getDefaultState, setPrintMod } from "../../features/defaultSlice";
import {
  closeModal,
  fetchData,
  getDownloadData,
  getJobsData,
  setPagination,
} from "../../features/jobs/jobs";
import { clearVendorData } from "../../features/vendor/vendor";
import HomeLayout from "../../layouts/Home";
import {
  AppType,
  Roles,
  camelCasedPathName,
  capitalize,
  getId,
  getRole,
  handleCopy,
} from "../../utils/helper";
import { ICopy } from "../../utils/icons.utils";
import VendorAssignment from "../manualReassignment/VendorAssignment";

const PaidJobs = () => {
  const tableRef = useRef();
  const dispatch = useDispatch(),
    { currentJob, loading, pagination, downloadData, modal, ...jobsData } =
      useSelector(getJobsData),
    { appType, ...authData } = useSelector(getAuthData),
    { pathname, state } = useLocation(),
    { printMod } = useSelector(getDefaultState),
    [checkboxData, setcheckboxData] = useState([]),
    // [adminAction, setAdminAction] = useState({}),
    adminAction = {},
    paidJobs = jobsData[camelCasedPathName()];

  useEffect(() => {
    dispatch(clearVendorData());
    if (state) {
      dispatch(setPagination({ page: 1, search: state }));
    } else dispatch(setPagination({ page: 1, search: "" }));
    dispatch(setPrintMod({ pageTitle: "Paid Jobs" }));
  }, []);

  const handleSearch = ({ target: { value } }) => {
    dispatch(setPagination({ ...pagination, search: value }));
  };

  useEffect(() => {
    dispatch(
      getDownloadData({
        url: pathname,
        type: appType === AppType.cs ? "corporate/" : "",
      })
    );
    let cb = () => {};
    if (pagination?.search) {
      dispatch(setPagination({ page: 1 }));
      cb = setTimeout((_) => (async () => await dispatch(fetchData()))(), 700);
    } else cb = (async () => await dispatch(fetchData()))();

    return () => {
      clearTimeout(cb);
    };
  }, [pagination.search, pagination.page, pagination.pageSize]);

  let data = paidJobs?.map((d) => {
    let model = {
      "Account Number": d?.AcctNumber || "N/A",
      "Account Name": capitalize(d?.AcctName) || "N/A",
    };
    if (AppType.cs === appType) model["RC Number"] = d?.RCNumber || "N/A";
    else {
      model["Address"] = capitalize(d?.AcctAddress) || "N/A";
      model["Updated Address"] = capitalize(d?.UpdatedAcctAddress) || "N/A";
    }
    model = {
      ...model,
      State: capitalize(d?.State?.Name) || "N/A",
      "Vendor's Name":
        capitalize(
          d?.AssignedVendor?.VendorPreferredName ||
            d?.AssignedVendor?.VendorName ||
            d?.VendorJobs?.[0]?.Vendor?.VendorPreferredName ||
            d?.VendorJobs?.[0]?.Vendor?.VendorName
        ) || "N/A",
      ...(getRole() !== Roles.vendor
        ? {
            "Bank User": `${d?.InitiatedByUser?.firstName ?? ""} ${
              d?.InitiatedByUser?.lastName ??
              (d?.InitiatedByUser?.role === Roles.admin ? "Admin" : "N/A")
            }`,
          }
        : {}),
      "Verify Result":
        d?.AddedInfo?.toLowerCase() === "successful" ? "Yes" : "No" || "N/A",
      Acknowledged: d?.AddedInfo || "N/A",
      _data: d,
    };
    return model;
  });

  const checkboxAction = {
    setValues: (data) => setcheckboxData(data),
    values: checkboxData,
  };

  const refresh = async () => {
    dispatch(closeModal());
    setcheckboxData([]);
    await dispatch(fetchData());
  };

  return (
    <HomeLayout {...{ handleSearch, value: pagination.search }}>
      <div className="flex items-end flex-wrap gap-2 justify-between">
        <div className="">
          <Typography className="text-[24px] font-[500]">
            Paid {appType === AppType.cav ? "Vendors" : "Solicitors"}
          </Typography>
          <Typography className="text-[18px] font-[400]">
            All paid {appType === AppType.cav ? "vendors" : "solicitors"}.
          </Typography>
        </div>
        <div className="flex flex-wrap md:justify-end gap-4 md:mt-0 mt-3">
          <button
            onClick={(_) => handleCopy(data)}
            className=" h-[2.3rem] flex  items-center rounded-md px-3 border-2 border-[#E6E6E6] text-sm font-semibold text-dark_red "
          >
            <img src={ICopy} alt="ICopy" />
            Copy
          </button>
          <PrintButton
            {...{
              componentRef: tableRef,
              documentTitle: printMod.pageTitle,
            }}
          />
          <SelectButton
            btTextClass="bg-dark_red text-white hover:bg-light_red"
            options={downloadData}
            title="Download"
          />
        </div>
      </div>

      <div className="">
        {[Roles.admin, Roles.superAdmin].includes(getRole()) && (
          <div className="flex justify-between mt-5 flex-wrap gap-y-5 items-center">
            <button
              onClick={(_) => {
                dispatch(setModal({ close: true }));
              }}
              disabled={checkboxData.length ? false : true}
              className={`px-2 py-1 hover:border-[#D70900] shadow disabled:text-grey disabled:bg-transparent disabled:border-grey flex items-center rounded border hover:bg-[#D70900] text-[#D70900] hover:text-white`}
            >
              <DriveFileMoveIcon />{" "}
              <span className="text-[15px] ml-1">Move Jobs</span>
            </button>

            <Search
              {...{
                value: pagination.search,
                onSearch: handleSearch,
                width: "300px",
              }}
            />
          </div>
        )}
        <TableLayout
          {...{
            printMod: { ...printMod, ref: tableRef },
            pagination,
            setPagination,
            isLoading: loading,
            checkboxAction:
              [Roles.admin, Roles.superAdmin].includes(getRole()) &&
              checkboxAction,
            data,
            tableMsg: ["No Paid Jobs"],
          }}
        />
      </div>

      {[Roles.admin, Roles.superAdmin].includes(getRole()) && (
        <>
          <CustomModal
            {...{
              title: `Reassign ${checkboxData.length} ${
                checkboxData.length > 1 ? "Jobs" : "Job"
              } Manually`,
              close: (_) => dispatch(closeModal()),
              open: modal.open,
            }}
          >
            <VendorAssignment
              {...{
                closeModal: (_) => dispatch(closeModal()),
                data: checkboxData,
                refresh,
              }}
            />
          </CustomModal>

          <CustomModal
            {...{
              title: `Move ${checkboxData.length} ${
                checkboxData.length > 1 ? "Jobs" : "Job"
              }`,
              open: authData.modal.close,
              close: (_) => dispatch(setModal({ close: false })),
            }}
          >
            <MoveJobs
              {...{
                closeModal: (_) => dispatch(setModal({ close: false })),
                data: checkboxData,
                refresh,
              }}
            />
          </CustomModal>

          <ConfirmationModal
            {...{
              open: modal.close,
              close: (_) => dispatch(closeModal("close")),
              refresh,
              data:
                adminAction.title === "Close"
                  ? checkboxData?.map((d) => d?.AcctRefID)
                  : {
                      bankUserId: getId().admin,
                      accountIds: checkboxData?.map((d) => d?.AcctRefID),
                    },
              btnTexts: ["Cancel", adminAction.title],
              title: adminAction.title + " Jobs",
              loading: modal.loader.closedJobs,
              subtitle: `Are you sure you want to ${adminAction?.title?.toLowerCase()} ${
                checkboxData.length > 1 ? "these" : "this"
              } ${checkboxData.length > 1 ? checkboxData.length : ""} ${
                checkboxData.length > 1 ? "jobs" : "job"
              }?`,
              action: adminAction.action,
            }}
          />
        </>
      )}
    </HomeLayout>
  );
};

export default PaidJobs;
