import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import React from "react";
import { getRole, Roles } from "../../utils/helper";

export default function Search({ value, onSearch, placeholder, width }) {
  return (
    <div
      className={`relative mt- rounded-md max-w-[400px] min-w-72`}
      style={{ width: width || "100%" }}
    >
      <div className="absolute inset-y-0 left-0 flex items-center pl-3 mb-1">
        <label htmlFor="search_id" className="cursor-pointer">
          <MagnifyingGlassIcon
            className="h-5 w-5 text-[#535763]"
          />
        </label>
      </div>
      <input
        type="text"
        name="search"
        id="search_id"
        value={value}
        onChange={onSearch}
        className="block w-full bg-[#fdfcfc] rounded-[5px] border-0  py-2 pl-10 text-gray-900 ring-1 ring-inset ring-[#EFEFF2] placeholder:text-gray-400 focus:shadow-md transition-shadow focus:bg-[#fdfcfc] focus:ring-1 focus:outline-none focus:ring-grey sm:text-sm sm:leading-6"
        placeholder={
          placeholder ||
          (getRole() === Roles.vendor
            ? "Search by account name..."
            : "Search by account number...")
        }
      />
    </div>
  );
}
