/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from "@material-tailwind/react";
import CurtainsClosedIcon from "@mui/icons-material/CurtainsClosed";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import RecyclingIcon from "@mui/icons-material/Recycling";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Button, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Search from "../../components/forms/Search";
import ConfirmationModal from "../../components/modals/confirmationModal";
import CustomModal from "../../components/modals/Index";
import DateRangePicker from "../../components/others/DatePicker";
import PrintButton from "../../components/others/Print";
import SelectButton from "../../components/others/SelectBtn";
import TableLayout from "../../components/table/CustomTable";
import { getAuthData } from "../../features/auth/authSlice";
import {
  fetchStates,
  getDefaultState,
  setPrintMod,
} from "../../features/defaultSlice";
import {
  closeJob,
  closeModal,
  fetchData,
  getDownloadData,
  getJobsData,
  openModal,
  setPagination,
} from "../../features/jobs/jobs";
import { clearVendorData } from "../../features/vendor/vendor";
import HomeLayout from "../../layouts/Home";
import {
  AppType,
  Roles,
  camelCasedPathName,
  capitalize,
  getId,
  getRole,
  handleCopy,
} from "../../utils/helper";
import { ICopy } from "../../utils/icons.utils";
import VendorAssignment from "../manualReassignment/VendorAssignment";

const AwaitingVendorAssignment = () => {
  const tableRef = useRef();
  const dispatch = useDispatch(),
    { appType } = useSelector(getAuthData),
    { pathname, state } = useLocation(),
    { loading, pagination, modal, downloadData, ...jobsData } =
      useSelector(getJobsData),
    awaitingJobs = jobsData[camelCasedPathName()],
    [checkboxData, setcheckboxData] = useState([]),
    [adminAction, setAdminAction] = useState({}),
    [showText, setShowText] = useState(true),
    { printMod, states } = useSelector(getDefaultState),
    [filter, setFilter] = useState({
      state: "",
    });

  useEffect(() => {
    dispatch(clearVendorData());

    if (state) {
      dispatch(
        setPagination({
          page: 1,
          search: state,
          stateId: "",
          startDate: "",
          endDate: "",
        })
      );
    } else
      dispatch(
        setPagination({
          page: 1,
          search: "",
          stateId: "",
          startDate: "",
          endDate: "",
        })
      );

    dispatch(setPrintMod({ pageTitle: "Awaiting Vendor Assignment" }));
    dispatch(fetchStates());
  }, []);

  const handleSearch = ({ target: { value } }) => {
    dispatch(setPagination({ ...pagination, search: value }));
  };

  useEffect(() => {
    dispatch(
      getDownloadData({
        url: pathname,
        type: appType === AppType.cs ? "corporate/" : "",
      })
    );
    let cb = () => {};
    if (pagination?.search) {
      dispatch(setPagination({ page: 1 }));
      cb = setTimeout((_) => (async () => await dispatch(fetchData()))(), 700);
    } else cb = (async () => await dispatch(fetchData()))();

    return () => {
      clearTimeout(cb);
    };
  }, [
    pagination.search,
    pagination.page,
    pagination.pageSize,
    pagination.stateId,
  ]);
  useEffect(() => {
    dispatch(
      getDownloadData({
        url: pathname,
        type: appType === AppType.cs ? "corporate/" : "",
      })
    );
    let cb = () => {};
    if (pagination.startDate && pagination.endDate) {
      dispatch(setPagination({ page: 1 }));
      cb = setTimeout((_) => (async () => await dispatch(fetchData()))(), 700);
    }
    return () => {
      clearTimeout(cb);
    };
  }, [pagination.endDate, pagination.startDate]);

  let data = awaitingJobs?.map((d) => {
    let model = {};

    if (AppType.cs === appType) model["CS ID"] = d?.AcctRefID;
    else model["CAV ID"] = d?.AcctRefID;
    if (getRole() !== Roles.vendor)
      model["Account Number"] = d?.AcctNumber || "N/A";
    model["Account Name"] = d?.AcctName || "N/A";
    model["State"] = capitalize(d?.State?.Name) || "N/A";

    if (AppType.cs === appType) {
      model["RC Number"] = d?.RCNumber || "N/A";
      model["Date of Incorporation"] = d?.DateOfIncorporation
        ? dayjs(d?.DateOfIncorporation).format("MMM DD, YYYY")
        : "N/A";
    } else model["Address"] = capitalize(d?.AcctAddress) || "N/A";

    model = {
      ...model,
      ...(getRole() !== Roles.vendor && {
        "Assigned By": d?.InitiatedByUser?.firstName
          ? `${d?.InitiatedByUser?.firstName} ${d?.InitiatedByUser?.lastName}`
          : "N/A",
      }),
      "Assigned Date": d?.DateAssigned
        ? dayjs(d?.DateAssigned).format("DD/MM/YY, hh:mm A")
        : "N/A",
      _data: d,
    };
    return model;
  });

  const checkboxAction = {
    setValues: (data) => setcheckboxData(data),
    values: checkboxData,
  };

  const initBulkAction = (meta, idx) => {
    if (idx) {
      setAdminAction(meta);
      dispatch(openModal("close"));
    } else meta.action();
  };
  const buttonMeta = [
    {
      title: "Assign",
      Icon: RecyclingIcon,
      action: (_) => dispatch(openModal()),
    },
    {
      title: "Close",
      Icon: CurtainsClosedIcon,
      action: closeJob,
      loading: modal.loader.closedJobs,
    },
  ];

  const refresh = async () => {
    dispatch(closeModal());
    setcheckboxData([]);
    await dispatch(fetchData());
  };
  const buttonStyle = useCallback(
    (i) => ({
      borderRadius: 0,
      minWidth: "50px",
      border: `${showText ? "0px" : "1px"} solid red`,
      borderRight:
        i < buttonMeta.length - 1 && `${showText ? "1" : "0"}px solid red`,
      textTransform: "capitalize",
      fontSize: "26px",
      "&:hover": { backgroundColor: "#D70900", color: "white" },
      ".MuiLoadingButton-loadingIndicatorCenter": {
        color: "var(--C_primary) !important",
      },
      "&:disabled": {
        border: `${showText ? "0px" : "1px"} solid grey`,
        borderRight:
          i < buttonMeta.length - 1 && `${showText ? "1" : "0"}px solid grey`,
        textTransform: "capitalize",
      },
    }),
    [showText]
  );

  const handleDateFilter = (date) => {
    dispatch(
      setPagination({ startDate: date?.startDate, endDate: date?.endDate })
    );
  };
  const stateOption = useMemo(() => {
    const result = states?.map((state) => ({
      name: state?.Name,
      url: "#",
      id: state?.Id,
    }));
    filter.state && result.unshift({ name: "All", id: "", url: "#" });

    return result;
  }, [states.length, filter.state]);

  return (
    <HomeLayout {...{ handleSearch, value: pagination.search }}>
      <div className="flex items-end flex-wrap gap-2 justify-between">
        <div className="">
          <Typography className="text-[24px] font-[500]">
            Awaiting Vendor Assignment
          </Typography>
          <Typography className="text-[18px] font-[400]">
            New accounts flagged for{" "}
            {appType === AppType.cav
              ? "address verification"
              : "corporate search"}{" "}
            (submitted by Finacle)
          </Typography>
        </div>
        <div className="flex flex-wrap md:justify-end gap-4 md:mt-0 mt-3">
          <button
            onClick={(_) => handleCopy(data)}
            className="h-[2.3rem] flex  items-center rounded-md px-3 border-2 border-[#E6E6E6] text-sm font-semibold text-dark_red "
          >
            <img src={ICopy} alt="ICopy" />
            Copy
          </button>
          <PrintButton
            {...{ componentRef: tableRef, documentTitle: printMod.pageTitle }}
          />
          <SelectButton
            btTextClass="bg-dark_red text-white hover:bg-light_red"
            options={downloadData}
            title="Download"
          />
        </div>
      </div>
      <div>
        <div className="flex flex-wra md:justify-between items-center gap-4 md:mt-10 mt-3 w-ful">
          {[Roles.admin, Roles.superAdmin].includes(getRole()) && (
            <div className="flex">
              {buttonMeta.map((meta, i) => (
                <Tooltip
                  key={i}
                  className="py-0 px-0"
                  title={meta.title + " Jobs"}
                  placement="top"
                >
                  <span>
                    <Button
                      onClick={(_) => initBulkAction(meta, i)}
                      color="error"
                      disabled={checkboxData.length ? false : true}
                      sx={(_) => buttonStyle(i, showText)}
                    >
                      <meta.Icon />{" "}
                      {showText && (
                        <span className="text-[15px] ml-1"> {meta.title} </span>
                      )}
                    </Button>
                  </span>
                </Tooltip>
              ))}

              <Tooltip
                className="py-0 px-0"
                title={showText ? "Collapse" : "Expand"}
                placement="top"
              >
                <Button
                  color="error"
                  onClick={(_) => setShowText(!showText)}
                  sx={{ minWidth: "20px", borderRadius: "30px", ml: "2em" }}
                  className="ml-5"
                >
                  {showText ? <NavigateBeforeIcon /> : <NavigateNextIcon />}
                </Button>
              </Tooltip>
            </div>
          )}

          {[Roles.admin, Roles.superAdmin, Roles.bank].includes(getRole()) && (
            <div className="flex gap-x-5">
              <DateRangePicker
                {...{
                  handleDateFilter,
                  minDate: new Date(dayjs().subtract(30, "days")),
                }}
              />
              <SelectButton
                btTextClass="bg-white w-[140px] flex items-center justify-between text-dark_red border border-[#F8F7F7]"
                options={stateOption}
                title={filter.state || "State"}
                download={false}
                onClick={(state) => {
                  setFilter({
                    ...filter,
                    state: state?.id === "" ? "" : state?.name,
                  });
                  dispatch(setPagination({ stateId: state?.id }));
                }}
              />
            </div>
          )}

          <Search
            {...{
              value: pagination.search,
              onSearch: handleSearch,
              width: "300px",
            }}
          />
        </div>

        <TableLayout
          {...{
            printMod: { ...printMod, ref: tableRef },
            pagination,
            setPagination,
            isLoading: loading,
            data,
            checkboxAction:
              [Roles.admin, Roles.superAdmin].includes(getRole()) &&
              checkboxAction,
            action: { visible: false, type: "icon", icon: <VisibilityIcon /> },
          }}
        />
      </div>

      {[Roles.admin, Roles.superAdmin].includes(getRole()) && (
        <>
          <CustomModal
            {...{
              title: `Assign ${checkboxData.length} ${
                checkboxData.length > 1 ? "Jobs" : "Job"
              } Manually`,
              close: (_) => dispatch(closeModal()),
              open: modal.open,
            }}
          >
            <VendorAssignment
              {...{
                closeModal: (_) => dispatch(closeModal()),
                data: checkboxData,
                refresh,
                text: "Assign",
              }}
            />
          </CustomModal>

          <ConfirmationModal
            {...{
              open: modal.close,
              close: (_) => dispatch(closeModal("close")),
              refresh,
              data:
                adminAction.title === "Close"
                  ? checkboxData?.map((d) => d?.AcctRefID)
                  : {
                      bankUserId: getId().admin,
                      accountIds: checkboxData?.map((d) => d?.AcctRefID),
                    },
              btnTexts: ["Cancel", adminAction.title],
              title: adminAction.title + " Jobs",
              loading: modal.loader.closedJobs,
              subtitle: `Are you sure you want to ${adminAction?.title?.toLowerCase()} ${
                checkboxData.length > 1 ? "these" : "this"
              } ${checkboxData.length > 1 ? checkboxData.length : ""} ${
                checkboxData.length > 1 ? "jobs" : "job"
              }?`,
              action: adminAction.action,
            }}
          />
        </>
      )}
    </HomeLayout>
  );
};

export default AwaitingVendorAssignment;
