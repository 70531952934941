/* eslint-disable react-hooks/exhaustive-deps */
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TabComponent from "../../components/others/tabCom";
import {
  getAuthData,
  getUserById,
  logout,
  setPreloader,
} from "../../features/auth/authSlice";
import { Roles, getRole } from "../../utils/helper";
import links from "../../utils/linkTree";
import GlobalSearch from "../forms/GlobalSearch";
import Logout from "../modals/Logout";
import NavMenu from "../others/NavMenu";
import Preloader from "../others/Preloader";

const drawerWidth = 340;

export default function HomeSidebar(props) {
  let { navigation } = props;
  navigation = navigation === undefined ? true : false;
  const { children } = props,
    [logouts, setLogout] = React.useState(false),
    dispatch = useDispatch(),
    { user, preloading } = useSelector(getAuthData);

  useEffect(() => {
    !user && dispatch(getUserById());
  }, []);

  useEffect(() => {
    setTimeout((_) => {
      dispatch(setPreloader());
    }, 2000);
  }, [preloading]);

  return (
    <Box className="overflow-x-hidden">
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          background: "#fdfcfc",
          color: "#000",
          boxShadow: "none",
          width: "100%",
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <Box className=" m-auto  w-full">
            <Box className="flex justify-between">
              <div className="">
                <img src="/logo.svg" alt="brand" className="w-[90%] h-[90%]" />
              </div>
              {props?.handleSearch &&
                ![Roles.solicitor, Roles.vendor].includes(getRole()) && (
                  <GlobalSearch />
                )}
              <NavMenu
                {...{
                  user,
                  role:
                    getRole() === Roles.bank
                      ? "Banker"
                      : getRole() === Roles.superAdmin
                      ? "Super Admin"
                      : getRole(),
                  handleLogout: (_) => setLogout(true),
                }}
              />
            </Box>
          </Box>
        </Toolbar>
        {navigation && (
          <TabComponent onClick={(_) => setLogout(true)} {...{ links }} />
        )}
      </AppBar>

      <Box component="main" className="px-7 sm:mt-32 mt-20">
        <br />
        <br />

        {children}
      </Box>

      <Logout {...{ logouts, setLogout, logout }} />
      <Preloader {...{ preloading }} />
    </Box>
  );
}
