import {
  ArrowTopRightOnSquareIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import { CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomModal from "../../components/modals/Index";
import {
  closeGlobalModal,
  getDefaultState,
  openGlobalModal,
} from "../../features/defaultSlice";
import {
  getJobsData,
  searchGlobalData,
  setPagination,
} from "../../features/jobs/jobs";
import * as manualReassignment from "../../features/jobs/manualReassignment";
import { JobStatus } from "../../utils/helper";

export default function GlobalSearch() {
  const [value, setValue] = React.useState("");
  const { _global_modal } = useSelector(getDefaultState);

  const { _globalSearchLoading, _globalSearchResult } =
    useSelector(getJobsData);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSearch = ({ target: { value } }) => {
    setValue(value);
  };

  useEffect(
    () => {
      let cb = () => {};
      if (value) {
        cb = setTimeout(
          (_) =>
            (async () => {
              const res = await dispatch(
                searchGlobalData({ accountNumber: value })
              ).unwrap();
              res?.success && dispatch(openGlobalModal(true));
            })(),
          700
        );
      }

      return () => {
        clearTimeout(cb);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value]
  );

  const digestedResult = useMemo(
    function () {
      let data = null;
      try {
        data = JobStatus.filter(
          (status) => _globalSearchResult?.JobStatus === status?.id
        );
       
        data = data[0];
      } catch (error) {
        console.error(error);
      }
      return data;
    },
    [_globalSearchResult]
  );

  return (
    <div className="relative mt-2 rounded-md w-[35%] hidden md:block">
      <div className="cursor-pointer absolute inset-y-0 left-0 flex items-center pl-3 mb-1">
        {_globalSearchLoading ? (
          <CircularProgress size="19px" sx={{ color: "#D70900" }} />
        ) : (
          <MagnifyingGlassIcon className="h-5 w-5 text-[#535763]" />
        )}
      </div>
      <input
        type="text"
        name="search"
        id="search"
        value={value}
        onChange={handleSearch}
        className="block w-full bg-[#F9F9F9] rounded-[5px] border-0  py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-[#EFEFF2] placeholder:text-[#535763] focus:shadow-md transition-shadow focus:bg-[#fdfcfc] focus:ring-1 focus:outline-none focus:ring-inset focus:ring-[#e8e8ec] sm:text-sm sm:leading-6"
        placeholder={"Global search..."}
      />

      {_globalSearchResult && (
        <CustomModal
          {...{
            title: "Search result",
            open: _global_modal,
            close: (_) => dispatch(closeGlobalModal(false)),
          }}
        >
          <div>
            <Typography>
              The job with account number{" "}
              <span style={{ color: "#D70900" }}>`{value}`</span> was found in{" "}
              <span
                onClick={(_) => {
                  dispatch(closeGlobalModal(false));
                  dispatch(setPagination({ page: 1, search: value }));
                  dispatch(
                    manualReassignment?.setPagination({
                      page: 1,
                      search: value,
                    })
                  );

                  navigate(digestedResult?.url, {
                    state: value,
                    replace: true,
                  });
                }}
                className="flex gap-2 items-center cursor-pointer"
                style={{ color: "#D70900" }}
              >
                <span className="block">{digestedResult?.name}</span>
                <ArrowTopRightOnSquareIcon className="h-4 w-4" />
              </span>
            </Typography>
          </div>
        </CustomModal>
      )}
    </div>
  );
}
