import config from "../../utils/config";
import { Roles, bankerRoutes, getRole, vendorRoutes } from "../../utils/helper";

export default function RBAC() {
  let isPermitted;
  switch (getRole()) {
    case Roles.superAdmin:
    case Roles.admin:
      isPermitted = true; // the above users have access to all routes
      break;
    case Roles.bank:
      isPermitted =
        bankerRoutes.includes(window.location.pathname) ||
        window.location.pathname.indexOf(config.routes.awaitingPayments) > -1;
      break;
    case Roles.vendor:
      isPermitted = vendorRoutes.includes(window.location.pathname);
      break;
    default:
      isPermitted = true;
  }

  if (!isPermitted) window.location.href = config.routes.dashboard;
}
