/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from "@material-tailwind/react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Box, Button } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Search from "../../components/forms/Search";
import CustomModal from "../../components/modals/Index";
import PrintButton from "../../components/others/Print";
import TableLayout from "../../components/table/CustomTable";
import { getAuthData } from "../../features/auth/authSlice";
import {
  closeModal,
  getDefaultState,
  openModal,
  setPrintMod,
} from "../../features/defaultSlice";
import {
  clearVendorData,
  fetchVendorAwaitingPaymentById,
  getVendorsData,
  setPagination,
} from "../../features/vendor/vendor";
import HomeLayout from "../../layouts/Home";
import {
  AppType,
  Roles,
  capitalize,
  getRole,
  handleCopy,
} from "../../utils/helper";
import { ICopy } from "../../utils/icons.utils";
import PayVendor from "./PayVendor";

const VendorJobs = () => {
  const tableRef = useRef();
  const dispatch = useDispatch(),
    { state } = useLocation(),
    navigate = useNavigate(),
    params = useParams(),
    {
      vendors: awaitingPayment,
      loading,
      pagination,
    } = useSelector(getVendorsData),
    { printMod, modal } = useSelector(getDefaultState),
    { appType } = useSelector(getAuthData);

  useEffect(() => {
    dispatch(setPagination({ page: 1, search: "" }));
    dispatch(clearVendorData());
    dispatch(setPrintMod({ pageTitle: "Awaiting Payment" }));
  }, []);

  useEffect(() => {
    let cb = () => {};
    if (pagination?.search) {
      dispatch(setPagination({ page: 1 }));
      cb = setTimeout(
        (_) =>
          (async () =>
            await dispatch(fetchVendorAwaitingPaymentById(params?.id)))(),
        700
      );
    } else
      cb = (async () =>
        await dispatch(fetchVendorAwaitingPaymentById(params?.id)))();

    return () => {
      clearTimeout(cb);
    };
  }, [pagination.search, pagination.page, pagination.pageSize]);

  let data = awaitingPayment?.map((d) => {
    let model = {
      "Account Number": d?.AcctNumber || "N/A",
      "Account Name": capitalize(d?.AcctName) || "N/A",
    };

    if (AppType.cs === appType) model["RC Number"] = d?.RCNumber || "N/A";
    else {
      model["Address"] = capitalize(d?.AcctAddress) || "N/A";
      model["Updated Address"] = capitalize(d?.UpdatedAcctAddress) || "N/A";
    }

    model = {
      ...model,
      State: capitalize(d?.State?.Name) || "N/A",
      "Vendor's Name":
        capitalize(
          d?.AssignedVendor?.VendorPreferredName ||
            d?.AssignedVendor?.VendorName ||
            d?.VendorJobs?.[0]?.Vendor?.VendorPreferredName ||
            d?.VendorJobs?.[0]?.Vendor?.VendorName
        ) || "N/A",
      ...(getRole() !== Roles.vendor && {
        "Assigned By": d?.InitiatedByUser?.firstName
          ? `${d?.InitiatedByUser?.firstName} ${d?.InitiatedByUser?.lastName}`
          : "N/A",
      }),
      "Assigned Date": d?.DateAssigned
        ? dayjs(d?.DateAssigned).format("DD/MM/YY, hh:mm A")
        : "N/A",
      "Confirmed Date": d?.DateConfirmed
        ? dayjs(d?.DateConfirmed).format("MMM DD, YYYY")
        : "N/A",
      "Verify Result":
        d?.AddedInfo?.toLowerCase() === "successful" ? "Yes" : "No" || "N/A",
      _data: d,
    };
    return model;
  });

  const handleSearch = ({ target: { value } }) => {
    dispatch(setPagination({ ...pagination, search: value }));
  };

  return (
    <HomeLayout {...{ handleSearch, value: pagination.search }}>
      <div className=" ">
        <Box className="text-[#201600] flex mb-5">
          <span
            className="flex cursor-pointer items-center"
            onClick={(_) => navigate(-1)}
          >
            <ArrowBackIosNewIcon
              color="#201600"
              className="mr-2"
              fontSize="small"
            />
            <Typography className="text-[#201600] text-[16px] ">
              Back
            </Typography>
          </span>
        </Box>
        <div className="flex items-end flex-wrap gap-2 justify-between">
          <div className="">
            <Typography className="text-[24px] font-[500]">
              Awaiting Payment
            </Typography>
            <Typography className="text-[18px] font-[400]">
              Jobs done by{" "}
              <b>{state?.VendorPreferredName || state?.VendorName}</b>.
            </Typography>
          </div>
          <div className="flex flex-wrap md:justify-end gap-4 md:mt-0 mt-3">
            <button
              onClick={(_) => handleCopy(data)}
              className="h-[2.3rem] flex  items-center rounded-md px-3 border-2 border-[#E6E6E6] text-sm font-semibold text-dark_red "
            >
              <img src={ICopy} alt="ICopy" />
              Copy
            </button>
            <PrintButton
              {...{ componentRef: tableRef, documentTitle: printMod.pageTitle }}
            />
            <Button
              onClick={(_) => {
                dispatch(openModal());
              }}
              variant="outlined"
              color="error"
              type="button"
              sx={{ textTransform: "capitalize" }}
              className="inline-flex h-[2.3rem] items-center rounded-md px-3 text-sm font-semibold bg-dark_red text-white hover:bg-light_red"
            >
              Mark Jobs As Paid
            </Button>
          </div>
        </div>

        <div className="flex justify-end mt-5 w-full">
          <Search
            {...{
              value: pagination.search,
              onSearch: handleSearch,
              width: "338px",
            }}
          />
        </div>
      </div>
      <div className="">
        <TableLayout
          {...{
            printMod: { ...printMod, ref: tableRef },
            pagination,
            setPagination,
            isLoading: loading,
            data,
          }}
        />
      </div>

      <CustomModal
        {...{
          title: "Confirm Payment",
          open: modal.open,
          close: (_) => dispatch(closeModal("open")),
        }}
      >
        <PayVendor
          {...{
            data: state,
            refresh: (_) => navigate(-1),
            closeModal: (_) => dispatch(closeModal("open")),
          }}
        />
      </CustomModal>
    </HomeLayout>
  );
};

export default VendorJobs;
