import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Avatar, Slide, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAuthData, logoutFromServer } from "../../features/auth/authSlice";
import { Ipower } from "../../utils/icons.utils";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Logout = ({ logouts, setLogout, logout }) => {
  const dispatch = useDispatch(),
    { logoutLoading } = useSelector(getAuthData),
    handleLogout = async () => {
      try {
        const res = await dispatch(logoutFromServer()).unwrap();
        if (res?.success) {
          dispatch(logout());
          navigate("/");
        }
      } catch (error) {
        console.error(error);
      }
    },
    navigate = useNavigate();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    logouts && (
      <Dialog
        fullScreen={fullScreen}
        open={logouts}
        onClose={(_) => setLogout(false)}
        aria-labelledby="responsive-dialog-title"
        className="p-10"
        sx={{ zIndex: 5000 }}
        TransitionComponent={Transition}
      >
        <DialogActions style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button color="error" onClick={(_) => setLogout(false)} autoFocus>
            <Close />
          </Button>
        </DialogActions>
        <DialogTitle
          id="responsive-dialog-title"
          display="flex"
          justifyContent="center"
          mt="0em"
        >
          <Avatar src={Ipower} />
        </DialogTitle>
        <DialogContent>
          <Typography textAlign="center" fontSize="24px" fontFamily={"Lota"}>
            Log Out
          </Typography>
          <DialogContentText
            fontSize="20px"
            padding=".5em 1em"
            mb={"20px"}
            fontFamily={"Lota"}
          >
            Are you sure you want to logout?
          </DialogContentText>
          <Stack spacing={7} direction="row" className="normal-case">
            <LoadingButton
              className="normal-case"
              fullWidth
              sx={{
                borderRadius: "8px",
                bgcolor: "#f5ebeb",
                color: "#D70900",
                boxShadow: "none",
                "&:hover": {
                  bgcolor: "#f7dad9",
                  boxShadow: "none",
                },
              }}
              size="large"
              loading={logoutLoading}
              variant="contained"
              onClick={handleLogout}
            >
              Yes
            </LoadingButton>
            <Button
              className="normal-case "
              fullWidth
              sx={{
                borderRadius: "8px",
                bgcolor: "#D70900",
                boxShadow: "none",
                color: "#f5ebeb",
                "&:hover": {
                  bgcolor: "#eb0f03",
                  boxShadow: "none",
                },
              }}
              size="large"
              onClick={(_) => setLogout(false)}
              variant="contained"
            >
              Cancel
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    )
  );
};

export default Logout;
