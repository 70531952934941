/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from "@material-tailwind/react";
import GroupIcon from "@mui/icons-material/Group";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import { Button, Tooltip } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import CustomModal from "../../components/modals/Index";
import PrintButton from "../../components/others/Print";
import SelectButton from "../../components/others/SelectBtn";
import TableLayout from "../../components/table/CustomTable";
import HomeLayout from "../../layouts/Home";
import config from "../../utils/config";
import { ICopy } from "../../utils/icons.utils";
import ViewEmployee from "./ViewEmployee";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Search from "../../components/forms/Search";
import ConfirmationModal from "../../components/modals/confirmationModal";
import {
  closeModal,
  getDefaultState,
  openModal,
  setPrintMod,
} from "../../features/defaultSlice";
import {
  deleteUser,
  fetchEmployees,
  getEmployeesData,
  setCurrentData,
  setPagination,
  setPayload,
} from "../../features/employee/employee";
import { AppType, Roles, capitalize, handleCopy } from "../../utils/helper";

const RegisteredEmployees = () => {
  const tableRef = useRef(),
    navigate = useNavigate(),
    dispatch = useDispatch(),
    [userType, setUserType] = useState(0),
    {
      employees,
      currentData,
      loading,
      pagination,
      downloadPdfUrl,
      downloadExcelUrl,
      modalLoading,
    } = useSelector(getEmployeesData);

  const { printMod, modal } = useSelector(getDefaultState);

  useEffect(() => {
    dispatch(setPagination({ page: 1, search: "" }));
    dispatch(setPrintMod({ pageTitle: "Registered Employees" }));
  }, []);

  const handleSearch = ({ target: { value } }) => {
    dispatch(setPagination({ ...pagination, search: value }));
  };

  const role = useMemo(
    () => (userType === 0 ? Roles.bank : userType === 1 ? Roles.admin : true),
    [userType]
  );
  const options = useMemo(() => {
    let query = `?searchTerm=${pagination.search}&page=${
      pagination.page
    }&pageSize=${pagination.pageSize}&deleted=${userType === 2}&permittedApp=${
      AppType.cav === config.appType ? "CAV" : "CSP"
    }`;

    if (role === true) query += `&role=${Roles.admin}&role=${Roles.bank}`;
    else query += `&role=${role}`;

    return [
      {
        name: "PDF",
        url: downloadPdfUrl + query,
      },
      {
        name: "CSV",
        url: downloadExcelUrl + query,
      },
    ];
  }, [pagination.pageSize, pagination.search, pagination.page, userType]);

  useEffect(() => {
    let cb = () => {};
    if (pagination?.search) {
      dispatch(setPagination({ page: 1 }));
      cb = setTimeout(
        (_) => (async () => await dispatch(fetchEmployees(role)))(),
        700
      );
    } else cb = (async () => await dispatch(fetchEmployees(role)))();

    return () => {
      clearTimeout(cb);
    };
  }, [pagination.search, pagination.page, pagination.pageSize]);

  useEffect(() => {
    dispatch(setPagination({ page: 1 }));
    let cb = () => {};
    if (pagination?.search) {
      cb = setTimeout(
        (_) => (async () => await dispatch(fetchEmployees(role)))(),
        700
      );
    } else cb = (async () => await dispatch(fetchEmployees(role)))();

    return () => {
      clearTimeout(cb);
    };
  }, [userType]);

  let data = employees?.map?.((d) => {
    const model = {
      "Employee ID": d?.employeeId || "" || "N/A",
      "Employee Name": d?.firstName + " " + d?.lastName || "N/A",
      "Email Address": d?.email,
      _data: d,
    };
    if (role === true)
      model.role = d.role === Roles.bank ? "Banker" : capitalize(d.role);
    return model;
  });

  const dropdownData = {
    visible: true,
    type: "dropdown",
    icon: <MoreHorizIcon />,
    data: [
      {
        text: "View",
        action: (_, state) => {
          dispatch(setCurrentData(state));
          dispatch(openModal("open"));
        },
      },
      {
        text: "Edit",
        condition: (a) => !a.IsDeleted,
        action: (_, state) => {
          dispatch(
            setPayload({
              firstName: state?.firstName,
              lastName: state?.lastName,
              email: state?.email,
              employeeId: state?.employeeId,
              branchId: state?.branch?.Id,
              role: state?.role === Roles.bank ? "Banker" : "Admin",
              permittedApps: state?.permittedApps || [],
            })
          );
          navigate(config.routes.createEmployees, { state });
        },
      },
      {
        text: "Delete Employee",
        condition: (a) => !a.IsDeleted,
        action: (_, state) => {
          dispatch(openModal("delete"));
          dispatch(setCurrentData(state));
        },
      },
    ],
  };

  return (
    <HomeLayout
      {...{
        handleSearch,
        value: pagination.search,
        placeholder: "Search by employee's name...",
      }}
    >
      <div className="flex items-end flex-wrap gap-2 justify-between">
        <div className="">
          <Typography className="text-[24px] font-[500]">
            Registered Employees
          </Typography>
          <Typography className="text-[18px] font-[400]">
            Bank staff that should have access to{" "}
            {AppType.cav === config.appType ? "CAV" : "CSP"}.
          </Typography>
        </div>
        <div className="flex md:justify-end flex-wrap gap-4 md:mt-0 mt-3">
          <button
            onClick={(_) => handleCopy(data)}
            className="h-[2.3rem] flex  items-center rounded-md px-3 border-2 border-[#E6E6E6] text-sm font-semibold text-dark_red "
          >
            <img src={ICopy} alt="ICopy" />
            Copy
          </button>
          <PrintButton
            {...{
              componentRef: tableRef,
              documentTitle: printMod.pageTitle,
            }}
          />
          {/* {role && ( */}
          <>
            <SelectButton
              btTextClass="bg-dark_red text-white hover:bg-light_red"
              options={options}
              title="Download"
            />
            <Button
              component={Link}
              to={config.routes.createEmployees}
              variant="outlined"
              color="error"
              type="button"
              sx={{ textTransform: "capitalize" }}
              className="inline-flex h-[2.3rem] items-center rounded-md px-3  text-sm font-semibold bg-dark_red text-white hover:bg-light_red "
            >
              Create New Employee
            </Button>
          </>
          {/* )} */}
        </div>
      </div>

      <br />
      <div className="flex justify-between items-center gap-5">
        <div className="flex">
          <Tooltip className="py-0 h-10" title="Bank Users" placement="top">
            <span>
              <button
                onClick={(_) => {
                  setUserType(0);
                }}
                className={`px-2 py-[6px] hover:border-[#D70900] py- border ${
                  userType === 0 ? "bg-[#D70900] text-white" : "text-[#D70900]"
                }`}
              >
                <GroupIcon />
                <span className="text-[15px] ml-1">Users</span>
              </button>
            </span>
          </Tooltip>

          <Tooltip className="py-0 h-10" title="Admin Users" placement="top">
            <span>
              <button
                onClick={(_) => {
                  setUserType(1);
                }}
                className={`px-2 py-[6px] hover:border-[#D70900] border ${
                  userType === 1 ? "bg-[#D70900] text-white" : "text-[#D70900]"
                }`}
              >
                <PeopleAltIcon />
                <span className="text-[15px] ml-1">Admins</span>
              </button>
            </span>
          </Tooltip>

          <Tooltip className="py-0 h-10" title="Deleted Users" placement="top">
            <span>
              <button
                onClick={(_) => {
                  setUserType(2);
                }}
                className={`px-2 py-[6px] hover:border-[#D70900] border ${
                  userType === 2 ? "bg-[#D70900] text-white" : "text-[#D70900]"
                }`}
              >
                <PersonRemoveIcon />
                <span className="text-[15px] ml-1">Deleted</span>
              </button>
            </span>
          </Tooltip>
        </div>
        <Search
          {...{
            value: pagination.search,
            onSearch: handleSearch,
            width: "303px",
            placeholder: "Search by employee's name...",
          }}
        />
      </div>

      <TableLayout
        {...{
          data,
          printMod: { ...printMod, ref: tableRef },
          pagination,
          setPagination,
          isLoading: loading,
          action: dropdownData,
        }}
      />

      <CustomModal
        {...{
          title: "View Employee",
          open: modal.open,
          close: (_) => dispatch(closeModal("open")),
        }}
      >
        <ViewEmployee
          {...{
            data: currentData,
            closeModal: (_) => dispatch(closeModal("open")),
          }}
        />
      </CustomModal>

      <ConfirmationModal
        {...{
          open: modal.delete,
          close: (_) => dispatch(closeModal("delete")),
          refresh: (_) => dispatch(fetchEmployees(role)),
          data: currentData,
          btnTexts: ["Cancel", "Delete"],
          title: "Delete",
          loading: modalLoading,
          subtitle: "Are you sure you want to delete this employee?",
          action: deleteUser,
        }}
      />
    </HomeLayout>
  );
};

export default RegisteredEmployees;
