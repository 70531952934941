import {
  createAsyncThunk,
 createSlice,
} from "@reduxjs/toolkit";
import BACKEND from "../utils/backend";

export const fetchStates = createAsyncThunk('/states', async (searchTerm, thunkAPI) => {
  if(searchTerm) thunkAPI.dispatch()
  try {
     return new BACKEND().send({
       type: 'get',
       to: `/state?searchTerm=${searchTerm || ''}&pageSize=38`,
       useAlert: false
     })
  
   } catch (error) {
     thunkAPI.rejectWithValue("An error occurred somewhere")
   }
 })

const initialState = {
 loading: false,
 states: [],
 modalLoading: false,
 searchTerm: '',
 printMod: { enable: false, ref: null, pageTitle: '' },
 modal: {open: false, close: false, delete: false, new: false, loader: {rejectReassign: false, reject: false, confirm: false, closedJobs: false, delete: false,}},
 _global_modal: false,
 pagination: {
  page: 1,
  pageSize: 10,
  total: 0,
  length: 0,
  search: ''
 }
}

export const defaultStateSlice = createSlice({
  name: "defaultState",
  initialState,
  reducers: {
    setPrintMod: (state, { payload }) => {
      state.printMod = { ...state.printMod, ...payload };
    },
    openModal: (state, { payload }) => {
      state.modal[payload || "open"] = true;
    },
    closeModal: (state, { payload }) => {
      state.modal[payload || "open"] = false;
    },
    openGlobalModal: (state) => {
      state._global_modal = true;
    },
    closeGlobalModal: (state) => {
      state._global_modal = false;
    },
  },
  extraReducers: (builder) => {
    /** Fetch Vendors Job Builder **/
    builder
      .addCase(fetchStates.pending, (state) => {
        state.modalLoading = true;
      })
      .addCase(fetchStates.fulfilled, (state, { payload }) => {
        state.modalLoading = false;

        if (payload?.success) {
          state.pagination.total = payload?.pagination?.totalCount;
          state.pagination.length =
            state.pagination.pageSize * state.pagination.page;
          state.states = payload?.data;
        }
      })
      .addCase(fetchStates.rejected, (state) => {
        state.modalLoading = false;
      });
    /** Fetch Vendors Job Builder |END| **/
  },
});

export const getDefaultState = state => state.defaultState;
export const {
  setPrintMod,
  openModal,
  closeModal,
  closeGlobalModal,
  openGlobalModal,
} = defaultStateSlice.actions;

export default defaultStateSlice.reducer;