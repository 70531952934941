export const bankDashData = [
  {
    title: "Total Registered Vendors",
    amount: "22,426",
    rate: "32",
    id: 1,
  },
  {
    title: "Total Awaiting Vendors Assignment",
    amount: "58,426",
    rate: "35",
    id: 2,
  },
  {
    title: "Total paid per All Jobs",
    amount: "12,426",
    rate: "34",
    id: 3,
  },
  {
    title: "Total Jobs Awaiting Payment",
    amount: "13,426",
    rate: "38",
    id: 4,
  },
  {
    title: "Total Jobs Awaiting Branch Confirnmation",
    amount: "12,426",
    rate: "33",
    id: 5,
  },
  {
    title: "Total Pending Jobs",
    amount: "11,426",
    rate: "35",
    id: 6,
  },
];

export const vendorDashData = [
  {
    title: "Total Pending Jobs",
    amount: "22,426",
    rate: "32",
    id: 1,
  },
  {
    title: "Total Jobs Awaiting Payments",
    amount: "58,426",
    rate: "35",
    id: 2,
  },
  {
    title: "Total Paid For All Jobs",
    amount: "12,426",
    rate: "34",
    id: 3,
  },
  {
    title: "Total Jobs Awaiting Branch Confirnmation",
    amount: "13,426",
    rate: "38",
    id: 4,
  },
  {
    title: "Total Jobs Pending Acknowledgement",
    amount: "12,426",
    rate: "33",
    id: 5,
  },
];

export const jobMock = [
  {
    AcctRefID: 1,
    CIFID: "R022602588",
    AcctNumber: "2304467310",
    AcctName: "Mrs JANE SUNDAY",
    AcctAddress: "Mrs Jane Sunday residence Awulema ohimini ",
    NeedsAdditionalInfo: false,
    AcctRefSol: null,
    DateImportFinacle: null,
    DateAcctOpened: "2023-06-01",
    AcctType: true,
    AcctRefLG: "OHIMINI",
    AcctRefCity: "OHIMINI",
    AcctRefState: "BENUE",
    AcctRefCountry: "Nigeria",
    AcctReference: null,
    AcctPhone: "09095374217",
    IsFunded: 1,
    IsVerifyAddress: false,
    Pnd: "NOT ELIGIBLE",
    Memo: "2023-06-01 12:47:49.423 +00:00",
    IsVendorAssigned: false,
    DateAssigned: null,
    AssignedVendorID: null,
    AssignedVendorName: null,
    AssignedAgentID: null,
    AssignedAgentName: null,
    IsVendorVerified: null,
    DateVerified: null,
    VerifiedByID: null,
    VerifiedByName: null,
    OptionsVendorVerified: null,
    CommentsVendor: null,
    AddressDescription: "Mrs Jane Sunday residence Awulema ohimini ",
    PersonsMet: null,
    Landmark: null,
    DateConfirmed: "2019-12-23T09:38:00.000Z",
    IsConfirmed: true,
    ConfirmedById: null,
    OptionsConfirmed: null,
    CommentsBank: "",
    IsVendorPaid: true,
    IsAcctDebited: null,
    SLA: null,
    BVN: null,
    Email: "glory.agbochenu@ubagroup.com",
    AccountBalance: "500",
    TAT: null,
    AccountType: "INDIVIDUAL",
    JobStatus: "PENDING",
    FundingCheckCount: 0,
    CreatedAt: "2023-07-10T07:30:00.038Z",
    UpdatedAt: "2023-07-10T07:30:00.038Z",
  },
];

export const JobsChartData = [
  { value: 335, name: "30% Closed Jobs" },
  { value: 310, name: "70% Rejected Jobs" },
];

export const JobsColorData = ["#3068F9", "#D70900"];

export const registeredVendorsData = [
  { value: 335, name: "62% SLA Compliance" },
  { value: 210, name: "23% SLA Compliance" },
  { value: 110, name: "13% SLA Compliance" },
];

export const registeredVendorsColorData = ["green", "#F79E1B", "#D70900"];

export const lockedVendorsData = [
  { value: 355, name: "62% SLA Compliance" },
  { value: 5, name: "23% SLA Compliance" },
];

export const lockedVendorsColorData = ["#D70900", "white"];

export const awaitingPaymentColorData = ["#FFC837", "white"];

export const rejectedJobsMock = [
  // 10 Jobs
  {
    AcctRefID: 15055,
    CIFID: null,
    AcctNumber: "2989254636",
    AcctName: "Henry Harris",
    AcctAddress: "3 Boye Street",
    UpdatedAcctAddress: null,
    NeedsAdditionalInfo: false,
    AcctRefSol: null,
    DateImportFinacle: null,
    DateAcctOpened: "2022-01-01",
    AcctType: true,
    AcctRefLG: null,
    AcctRefCity: "OSHODI",
    AcctRefState: "LAGOS",
    StateId: 24,
    AcctRefCountry: null,
    AcctReference: null,
    AcctPhone: null,
    IsFunded: 1,
    IsVerifyAddress: true,
    Pnd: null,
    Memo: null,
    IsVendorAssigned: true,
    DateAssigned: "2023-10-26T12:50:44.927Z",
    AssignedVendorID: 22,
    AssignedAgentID: null,
    AssignedAgentName: "Richard Chikezie",
    IsVendorVerified: true,
    DateVerified: null,
    VerifiedByID: null,
    VerifiedByName: null,
    OptionsVendorVerified: null,
    CommentsVendor: "Okay",
    AddressDescription: "Valid",
    AddressVerificationPhotos: [],
    PersonsMet: null,
    DateConfirmed: "2023-10-26T12:53:29.159Z",
    IsConfirmed: true,
    ConfirmedById: null,
    OptionsConfirmed: null,
    CommentsBank: null,
    IsVendorPaid: null,
    IsAcctDebited: null,
    SLA: 0,
    BVN: null,
    Email: null,
    AccountBalance: "5000",
    TAT: null,
    AccountType: "INDIVIDUAL",
    JobStatus: "COMPLETED",
    AddedInfo: "SUCCESSFUL",
    FundingCheckCount: 3,
    CreatedAt: "2023-10-05T12:52:00.035Z",
    UpdatedAt: "2023-10-26T13:25:34.077Z",
    AssignedVendor: {
      VendorID: 22,
      VendorName: "Richard Chikezie",
      VendorEmail: "richardsprojects@gmail.com",
      VendorPassword: null,
      VendorRefState: null,
      SLA: 0,
      UserId: "702b5ecc-c6e5-48a6-b878-2ce6f0b4a666",
      Type: "vendor",
      VendorAcctNo: "0107052522",
      Approved: true,
      VendorReset: false,
      VendorAddress: null,
      VendorRefSol: null,
      VendorRefLG: null,
      IsDeleted: false,
      AccountType: "INDIVIDUAL",
      CreatedAt: "2023-10-26T11:43:48.375Z",
      UpdatedAt: "2023-10-26T22:01:34.709Z",
      VendorId: null,
    },
    State: {
      Id: 24,
      Name: "LAGOS",
      Code: "LA",
      CreatedAt: "2023-10-03T05:45:44.785Z",
      UpdatedAt: "2023-10-03T05:45:44.785Z",
    },
    VendorJobLedgers: [
      {
        Id: 57,
        VendorId: 22,
        Comment: "Job confirmed",
        AccountId: 15055,
        JobStatus: "COMPLETED",
        BankUserId: "5119b758-e9cf-426e-bf19-488a0f7519c6",
        CreatedAt: "2023-10-26T13:25:34.068Z",
        UpdatedAt: "2023-10-26T13:25:34.068Z",
        Vendor: {
          VendorID: 22,
          VendorName: "Richard Chikezie",
          VendorEmail: "richardsprojects@gmail.com",
          VendorPassword: null,
          VendorRefState: null,
          SLA: 0,
          UserId: "702b5ecc-c6e5-48a6-b878-2ce6f0b4a666",
          Type: "vendor",
          VendorAcctNo: "0107052522",
          Approved: true,
          VendorReset: false,
          VendorAddress: null,
          VendorRefSol: null,
          VendorRefLG: null,
          IsDeleted: false,
          AccountType: "INDIVIDUAL",
          CreatedAt: "2023-10-26T11:43:48.375Z",
          UpdatedAt: "2023-10-26T22:01:34.709Z",
          VendorId: null,
        },
      },
      {
        Id: 53,
        VendorId: 4,
        Comment: "",
        AccountId: 15055,
        JobStatus: "REJECTED",
        BankUserId: "1678f8f6-abf7-4ed1-b2a8-a523be8ad127",
        CreatedAt: "2023-10-26T12:50:44.910Z",
        UpdatedAt: "2023-10-26T12:50:44.910Z",
        Vendor: {
          VendorID: 4,
          VendorName: "Esther Okon",
          VendorEmail: "eltoshcollection@gmail.com",
          VendorPassword: null,
          VendorRefState: null,
          SLA: 0,
          UserId: "8ed93a58-8393-46b5-b39a-1ae56f47866e",
          Type: "vendor",
          VendorAcctNo: null,
          Approved: true,
          VendorReset: false,
          VendorAddress: null,
          VendorRefSol: null,
          VendorRefLG: null,
          IsDeleted: false,
          AccountType: "INDIVIDUAL",
          CreatedAt: "2023-10-04T13:11:49.328Z",
          UpdatedAt: "2023-10-26T22:03:12.431Z",
          VendorId: null,
        },
      },
    ],
    VendorJobs: [],
  },

  {
    AcctRefID: 15049,
    CIFID: null,
    AcctNumber: "2276044041",
    AcctName: "Diana Anderson",
    AcctAddress: "3 Fair View Appartment, Dolphin Estate",
    UpdatedAcctAddress: null,
    NeedsAdditionalInfo: false,
    AcctRefSol: null,
    DateImportFinacle: null,
    DateAcctOpened: "2022-01-01",
    AcctType: true,
    AcctRefLG: null,
    AcctRefCity: "IKOYI",
    AcctRefState: "LAGOS",
    StateId: 24,
    AcctRefCountry: null,
    AcctReference: null,
    AcctPhone: null,
    IsFunded: 1,
    IsVerifyAddress: true,
    Pnd: null,
    Memo: null,
    IsVendorAssigned: true,
    DateAssigned: "2023-10-26T12:58:34.820Z",
    AssignedVendorID: 22,
    AssignedAgentID: null,
    AssignedAgentName: "Richard Chikezie",
    IsVendorVerified: true,
    DateVerified: null,
    VerifiedByID: null,
    VerifiedByName: null,
    OptionsVendorVerified: null,
    CommentsVendor: "Nil",
    AddressDescription: "Nil",
    AddressVerificationPhotos: [],
    PersonsMet: null,
    DateConfirmed: "2023-10-26T12:59:27.152Z",
    IsConfirmed: true,
    ConfirmedById: null,
    OptionsConfirmed: null,
    CommentsBank: null,
    IsVendorPaid: null,
    IsAcctDebited: null,
    SLA: 0,
    BVN: null,
    Email: null,
    AccountBalance: "5075",
    TAT: null,
    AccountType: "INDIVIDUAL",
    JobStatus: "CLOSED",
    AddedInfo: "UNSUCCESSFUL",
    FundingCheckCount: 3,
    CreatedAt: "2023-10-05T12:52:00.034Z",
    UpdatedAt: "2023-10-26T13:00:25.223Z",
    AssignedVendor: {
      VendorID: 22,
      VendorName: "Richard Chikezie",
      VendorEmail: "richardsprojects@gmail.com",
      VendorPassword: null,
      VendorRefState: null,
      SLA: 0,
      UserId: "702b5ecc-c6e5-48a6-b878-2ce6f0b4a666",
      Type: "vendor",
      VendorAcctNo: "0107052522",
      Approved: true,
      VendorReset: false,
      VendorAddress: null,
      VendorRefSol: null,
      VendorRefLG: null,
      IsDeleted: false,
      AccountType: "INDIVIDUAL",
      CreatedAt: "2023-10-26T11:43:48.375Z",
      UpdatedAt: "2023-10-26T22:01:34.709Z",
      VendorId: null,
    },
    State: {
      Id: 24,
      Name: "LAGOS",
      Code: "LA",
      CreatedAt: "2023-10-03T05:45:44.785Z",
      UpdatedAt: "2023-10-03T05:45:44.785Z",
    },
    VendorJobLedgers: [
      {
        Id: 55,
        VendorId: 4,
        Comment: "",
        AccountId: 15049,
        JobStatus: "REJECTED",
        BankUserId: "1678f8f6-abf7-4ed1-b2a8-a523be8ad127",
        CreatedAt: "2023-10-26T12:58:34.801Z",
        UpdatedAt: "2023-10-26T12:58:34.801Z",
        Vendor: {
          VendorID: 4,
          VendorName: "Esther Okon",
          VendorEmail: "eltoshcollection@gmail.com",
          VendorPassword: null,
          VendorRefState: null,
          SLA: 0,
          UserId: "8ed93a58-8393-46b5-b39a-1ae56f47866e",
          Type: "vendor",
          VendorAcctNo: null,
          Approved: true,
          VendorReset: false,
          VendorAddress: null,
          VendorRefSol: null,
          VendorRefLG: null,
          IsDeleted: false,
          AccountType: "INDIVIDUAL",
          CreatedAt: "2023-10-04T13:11:49.328Z",
          UpdatedAt: "2023-10-26T22:03:12.431Z",
          VendorId: null,
        },
      },
      {
        Id: 56,
        VendorId: 22,
        Comment: "Job closed by bank",
        AccountId: 15049,
        JobStatus: "CLOSED",
        BankUserId: "1678f8f6-abf7-4ed1-b2a8-a523be8ad127",
        CreatedAt: "2023-10-26T13:00:25.216Z",
        UpdatedAt: "2023-10-26T13:00:25.217Z",
        Vendor: {
          VendorID: 22,
          VendorName: "Richard Chikezie",
          VendorEmail: "richardsprojects@gmail.com",
          VendorPassword: null,
          VendorRefState: null,
          SLA: 0,
          UserId: "702b5ecc-c6e5-48a6-b878-2ce6f0b4a666",
          Type: "vendor",
          VendorAcctNo: "0107052522",
          Approved: true,
          VendorReset: false,
          VendorAddress: null,
          VendorRefSol: null,
          VendorRefLG: null,
          IsDeleted: false,
          AccountType: "INDIVIDUAL",
          CreatedAt: "2023-10-26T11:43:48.375Z",
          UpdatedAt: "2023-10-26T22:01:34.709Z",
          VendorId: null,
        },
      },
    ],
    VendorJobs: [
      {
        Id: 16620,
        VendorId: 22,
        AccountId: 15049,
        StateId: null,
        Completed: false,
        CreatedAt: "2023-10-26T12:58:34.815Z",
        UpdatedAt: "2023-10-26T12:58:34.815Z",
        Vendor: {
          VendorID: 22,
          VendorName: "Richard Chikezie",
          VendorEmail: "richardsprojects@gmail.com",
          VendorPassword: null,
          VendorRefState: null,
          SLA: 0,
          UserId: "702b5ecc-c6e5-48a6-b878-2ce6f0b4a666",
          Type: "vendor",
          VendorAcctNo: "0107052522",
          Approved: true,
          VendorReset: false,
          VendorAddress: null,
          VendorRefSol: null,
          VendorRefLG: null,
          IsDeleted: false,
          AccountType: "INDIVIDUAL",
          CreatedAt: "2023-10-26T11:43:48.375Z",
          UpdatedAt: "2023-10-26T22:01:34.709Z",
          VendorId: null,
        },
      },
    ],
  },

  {
    AcctRefID: 19883,
    CIFID: null,
    AcctNumber: "2645731069",
    AcctName: "Ryan Smith",
    AcctAddress: "Suite 172, All Seasons' Place",
    UpdatedAcctAddress: null,
    NeedsAdditionalInfo: false,
    AcctRefSol: null,
    DateImportFinacle: null,
    DateAcctOpened: "2021-12-31",
    AcctType: true,
    AcctRefLG: null,
    AcctRefCity: "OJODU-BERGER",
    AcctRefState: "LAGOS",
    StateId: 24,
    AcctRefCountry: null,
    AcctReference: null,
    AcctPhone: null,
    IsFunded: 1,
    IsVerifyAddress: true,
    Pnd: null,
    Memo: null,
    IsVendorAssigned: false,
    DateAssigned: "2023-10-26T12:47:13.187Z",
    AssignedVendorID: null,
    AssignedAgentID: null,
    AssignedAgentName: "Richard Chikezie",
    IsVendorVerified: true,
    DateVerified: null,
    VerifiedByID: null,
    VerifiedByName: null,
    OptionsVendorVerified: null,
    CommentsVendor: "Okay",
    AddressDescription: "Valid",
    AddressVerificationPhotos: [],
    PersonsMet: null,
    DateConfirmed: "2023-10-26T12:53:48.265Z",
    IsConfirmed: true,
    ConfirmedById: null,
    OptionsConfirmed: null,
    CommentsBank: null,
    IsVendorPaid: null,
    IsAcctDebited: null,
    SLA: 0,
    BVN: null,
    Email: null,
    AccountBalance: "5075",
    TAT: null,
    AccountType: "INDIVIDUAL",
    JobStatus: "REJECTED",
    AddedInfo: "SUCCESSFUL",
    FundingCheckCount: 3,
    CreatedAt: "2023-10-05T13:28:00.069Z",
    UpdatedAt: "2023-10-26T12:55:10.856Z",
    AssignedVendor: null,
    State: {
      Id: 24,
      Name: "LAGOS",
      Code: "LA",
      CreatedAt: "2023-10-03T05:45:44.785Z",
      UpdatedAt: "2023-10-03T05:45:44.785Z",
    },
    VendorJobLedgers: [
      {
        Id: 54,
        VendorId: 22,
        Comment: "Job wasn't completed as expected",
        AccountId: 19883,
        JobStatus: "REJECTED",
        BankUserId: "1678f8f6-abf7-4ed1-b2a8-a523be8ad127",
        CreatedAt: "2023-10-26T12:55:10.838Z",
        UpdatedAt: "2023-10-26T12:55:10.838Z",
        Vendor: {
          VendorID: 22,
          VendorName: "Richard Chikezie",
          VendorEmail: "richardsprojects@gmail.com",
          VendorPassword: null,
          VendorRefState: null,
          SLA: 0,
          UserId: "702b5ecc-c6e5-48a6-b878-2ce6f0b4a666",
          Type: "vendor",
          VendorAcctNo: "0107052522",
          Approved: true,
          VendorReset: false,
          VendorAddress: null,
          VendorRefSol: null,
          VendorRefLG: null,
          IsDeleted: false,
          AccountType: "INDIVIDUAL",
          CreatedAt: "2023-10-26T11:43:48.375Z",
          UpdatedAt: "2023-10-26T22:01:34.709Z",
          VendorId: null,
        },
      },
      {
        Id: 52,
        VendorId: 4,
        Comment: "",
        AccountId: 19883,
        JobStatus: "REJECTED",
        BankUserId: "1678f8f6-abf7-4ed1-b2a8-a523be8ad127",
        CreatedAt: "2023-10-26T12:47:13.166Z",
        UpdatedAt: "2023-10-26T12:47:13.166Z",
        Vendor: {
          VendorID: 4,
          VendorName: "Esther Okon",
          VendorEmail: "eltoshcollection@gmail.com",
          VendorPassword: null,
          VendorRefState: null,
          SLA: 0,
          UserId: "8ed93a58-8393-46b5-b39a-1ae56f47866e",
          Type: "vendor",
          VendorAcctNo: null,
          Approved: true,
          VendorReset: false,
          VendorAddress: null,
          VendorRefSol: null,
          VendorRefLG: null,
          IsDeleted: false,
          AccountType: "INDIVIDUAL",
          CreatedAt: "2023-10-04T13:11:49.328Z",
          UpdatedAt: "2023-10-26T22:03:12.431Z",
          VendorId: null,
        },
      },
    ],
    VendorJobs: [
      {
        Id: 16618,
        VendorId: 22,
        AccountId: 19883,
        StateId: null,
        Completed: false,
        CreatedAt: "2023-10-26T12:47:13.181Z",
        UpdatedAt: "2023-10-26T12:47:13.181Z",
        Vendor: {
          VendorID: 22,
          VendorName: "Richard Chikezie",
          VendorEmail: "richardsprojects@gmail.com",
          VendorPassword: null,
          VendorRefState: null,
          SLA: 0,
          UserId: "702b5ecc-c6e5-48a6-b878-2ce6f0b4a666",
          Type: "vendor",
          VendorAcctNo: "0107052522",
          Approved: true,
          VendorReset: false,
          VendorAddress: null,
          VendorRefSol: null,
          VendorRefLG: null,
          IsDeleted: false,
          AccountType: "INDIVIDUAL",
          CreatedAt: "2023-10-26T11:43:48.375Z",
          UpdatedAt: "2023-10-26T22:01:34.709Z",
          VendorId: null,
        },
      },
    ],
  },

  {
    AcctRefID: 26031,
    CIFID: null,
    AcctNumber: "2521429074",
    AcctName: "Ola Lewis",
    AcctAddress: "1, Hussey Street",
    UpdatedAcctAddress: null,
    NeedsAdditionalInfo: false,
    AcctRefSol: null,
    DateImportFinacle: null,
    DateAcctOpened: "2023-01-01",
    AcctType: true,
    AcctRefLG: null,
    AcctRefCity: "YABA",
    AcctRefState: "LAGOS",
    StateId: 24,
    AcctRefCountry: null,
    AcctReference: null,
    AcctPhone: null,
    IsFunded: 1,
    IsVerifyAddress: true,
    Pnd: null,
    Memo: null,
    IsVendorAssigned: false,
    DateAssigned: "2023-10-06T07:24:56.304Z",
    AssignedVendorID: null,
    AssignedAgentID: null,
    AssignedAgentName: null,
    IsVendorVerified: null,
    DateVerified: null,
    VerifiedByID: null,
    VerifiedByName: null,
    OptionsVendorVerified: null,
    CommentsVendor: "make sense",
    AddressDescription: "make sense",
    AddressVerificationPhotos: [],
    PersonsMet: null,
    DateConfirmed: "2023-10-25T08:45:09.089Z",
    IsConfirmed: true,
    ConfirmedById: null,
    OptionsConfirmed: null,
    CommentsBank: null,
    IsVendorPaid: null,
    IsAcctDebited: null,
    SLA: 0,
    BVN: null,
    Email: null,
    AccountBalance: "502300",
    TAT: null,
    AccountType: "INDIVIDUAL",
    JobStatus: "REJECTED",
    AddedInfo: "SUCCESSFUL",
    FundingCheckCount: 3,
    CreatedAt: "2023-10-05T13:55:00.062Z",
    UpdatedAt: "2023-10-25T08:46:09.074Z",
    AssignedVendor: null,
    State: {
      Id: 24,
      Name: "LAGOS",
      Code: "LA",
      CreatedAt: "2023-10-03T05:45:44.785Z",
      UpdatedAt: "2023-10-03T05:45:44.785Z",
    },
    VendorJobLedgers: [
      {
        Id: 50,
        VendorId: 4,
        Comment: "not nice",
        AccountId: 26031,
        JobStatus: "REJECTED",
        BankUserId: "5119b758-e9cf-426e-bf19-488a0f7519c6",
        CreatedAt: "2023-10-25T08:46:09.051Z",
        UpdatedAt: "2023-10-25T08:46:09.053Z",
        Vendor: {
          VendorID: 4,
          VendorName: "Esther Okon",
          VendorEmail: "eltoshcollection@gmail.com",
          VendorPassword: null,
          VendorRefState: null,
          SLA: 0,
          UserId: "8ed93a58-8393-46b5-b39a-1ae56f47866e",
          Type: "vendor",
          VendorAcctNo: null,
          Approved: true,
          VendorReset: false,
          VendorAddress: null,
          VendorRefSol: null,
          VendorRefLG: null,
          IsDeleted: false,
          AccountType: "INDIVIDUAL",
          CreatedAt: "2023-10-04T13:11:49.328Z",
          UpdatedAt: "2023-10-26T22:03:12.431Z",
          VendorId: null,
        },
      },
    ],
    VendorJobs: [
      {
        Id: 9258,
        VendorId: 4,
        AccountId: 26031,
        StateId: 24,
        Completed: false,
        CreatedAt: "2023-10-06T07:24:56.092Z",
        UpdatedAt: "2023-10-06T07:24:56.092Z",
        Vendor: {
          VendorID: 4,
          VendorName: "Esther Okon",
          VendorEmail: "eltoshcollection@gmail.com",
          VendorPassword: null,
          VendorRefState: null,
          SLA: 0,
          UserId: "8ed93a58-8393-46b5-b39a-1ae56f47866e",
          Type: "vendor",
          VendorAcctNo: null,
          Approved: true,
          VendorReset: false,
          VendorAddress: null,
          VendorRefSol: null,
          VendorRefLG: null,
          IsDeleted: false,
          AccountType: "INDIVIDUAL",
          CreatedAt: "2023-10-04T13:11:49.328Z",
          UpdatedAt: "2023-10-26T22:03:12.431Z",
          VendorId: null,
        },
      },
    ],
  },

  {
    AcctRefID: 29561,
    CIFID: null,
    AcctNumber: "2371798693",
    AcctName: "Frank White",
    AcctAddress: "1st/2nd Floor winner's plaza, 17 Balogun St, Lagos Island",
    UpdatedAcctAddress: null,
    NeedsAdditionalInfo: false,
    AcctRefSol: null,
    DateImportFinacle: null,
    DateAcctOpened: "2021-01-01",
    AcctType: true,
    AcctRefLG: null,
    AcctRefCity: "LAGOS ISLAND",
    AcctRefState: "LAGOS",
    StateId: 24,
    AcctRefCountry: null,
    AcctReference: null,
    AcctPhone: null,
    IsFunded: 1,
    IsVerifyAddress: true,
    Pnd: null,
    Memo: null,
    IsVendorAssigned: false,
    DateAssigned: "2023-10-06T07:27:37.217Z",
    AssignedVendorID: null,
    AssignedAgentID: null,
    AssignedAgentName: null,
    IsVendorVerified: null,
    DateVerified: null,
    VerifiedByID: null,
    VerifiedByName: null,
    OptionsVendorVerified: null,
    CommentsVendor: "",
    AddressDescription: "14 oshinowo",
    AddressVerificationPhotos: [],
    PersonsMet: null,
    DateConfirmed: "2023-10-24T22:33:59.325Z",
    IsConfirmed: true,
    ConfirmedById: null,
    OptionsConfirmed: null,
    CommentsBank: null,
    IsVendorPaid: null,
    IsAcctDebited: null,
    SLA: 0,
    BVN: null,
    Email: null,
    AccountBalance: "5000",
    TAT: null,
    AccountType: "INDIVIDUAL",
    JobStatus: "REJECTED",
    AddedInfo: "UNSUCCESSFUL",
    FundingCheckCount: 3,
    CreatedAt: "2023-10-05T14:13:03.258Z",
    UpdatedAt: "2023-10-24T22:34:30.042Z",
    AssignedVendor: null,
    State: {
      Id: 24,
      Name: "LAGOS",
      Code: "LA",
      CreatedAt: "2023-10-03T05:45:44.785Z",
      UpdatedAt: "2023-10-03T05:45:44.785Z",
    },
    VendorJobLedgers: [
      {
        Id: 48,
        VendorId: 4,
        Comment: "Testing ",
        AccountId: 29561,
        JobStatus: "REJECTED",
        BankUserId: "5119b758-e9cf-426e-bf19-488a0f7519c6",
        CreatedAt: "2023-10-24T22:34:30.026Z",
        UpdatedAt: "2023-10-24T22:34:30.027Z",
        Vendor: {
          VendorID: 4,
          VendorName: "Esther Okon",
          VendorEmail: "eltoshcollection@gmail.com",
          VendorPassword: null,
          VendorRefState: null,
          SLA: 0,
          UserId: "8ed93a58-8393-46b5-b39a-1ae56f47866e",
          Type: "vendor",
          VendorAcctNo: null,
          Approved: true,
          VendorReset: false,
          VendorAddress: null,
          VendorRefSol: null,
          VendorRefLG: null,
          IsDeleted: false,
          AccountType: "INDIVIDUAL",
          CreatedAt: "2023-10-04T13:11:49.328Z",
          UpdatedAt: "2023-10-26T22:03:12.431Z",
          VendorId: null,
        },
      },
    ],
    VendorJobs: [
      {
        Id: 9262,
        VendorId: 4,
        AccountId: 29561,
        StateId: 24,
        Completed: false,
        CreatedAt: "2023-10-06T07:27:37.049Z",
        UpdatedAt: "2023-10-06T07:27:37.049Z",
        Vendor: {
          VendorID: 4,
          VendorName: "Esther Okon",
          VendorEmail: "eltoshcollection@gmail.com",
          VendorPassword: null,
          VendorRefState: null,
          SLA: 0,
          UserId: "8ed93a58-8393-46b5-b39a-1ae56f47866e",
          Type: "vendor",
          VendorAcctNo: null,
          Approved: true,
          VendorReset: false,
          VendorAddress: null,
          VendorRefSol: null,
          VendorRefLG: null,
          IsDeleted: false,
          AccountType: "INDIVIDUAL",
          CreatedAt: "2023-10-04T13:11:49.328Z",
          UpdatedAt: "2023-10-26T22:03:12.431Z",
          VendorId: null,
        },
      },
    ],
  },

  {
    AcctRefID: 15438,
    CIFID: null,
    AcctNumber: "2405055977",
    AcctName: "Obefemi Udeh",
    AcctAddress: "10, Christ the king Estate",
    UpdatedAcctAddress: null,
    NeedsAdditionalInfo: false,
    AcctRefSol: null,
    DateImportFinacle: null,
    DateAcctOpened: "2021-12-31",
    AcctType: true,
    AcctRefLG: null,
    AcctRefCity: "IKOYI",
    AcctRefState: "LAGOS",
    StateId: 24,
    AcctRefCountry: null,
    AcctReference: null,
    AcctPhone: null,
    IsFunded: 1,
    IsVerifyAddress: true,
    Pnd: null,
    Memo: null,
    IsVendorAssigned: false,
    DateAssigned: "2023-10-06T09:12:33.611Z",
    AssignedVendorID: null,
    AssignedAgentID: null,
    AssignedAgentName: null,
    IsVendorVerified: null,
    DateVerified: null,
    VerifiedByID: null,
    VerifiedByName: null,
    OptionsVendorVerified: null,
    CommentsVendor: "Closed area with a barb fence",
    AddressDescription: "First turning by the Estate gate",
    AddressVerificationPhotos: [
      "https://uba-mobile.s3.us-east-2.amazonaws.com/%27public%27/uploads/Ailment%20configuration.JPG",
    ],
    PersonsMet: null,
    DateConfirmed: "2023-10-19T09:46:48.426Z",
    IsConfirmed: true,
    ConfirmedById: null,
    OptionsConfirmed: null,
    CommentsBank: null,
    IsVendorPaid: null,
    IsAcctDebited: null,
    SLA: 0,
    BVN: null,
    Email: null,
    AccountBalance: "5088",
    TAT: null,
    AccountType: "INDIVIDUAL",
    JobStatus: "REJECTED",
    AddedInfo: "SUCCESSFUL",
    FundingCheckCount: 3,
    CreatedAt: "2023-10-05T12:55:00.075Z",
    UpdatedAt: "2023-10-19T15:43:26.719Z",
    AssignedVendor: null,
    State: {
      Id: 24,
      Name: "LAGOS",
      Code: "LA",
      CreatedAt: "2023-10-03T05:45:44.785Z",
      UpdatedAt: "2023-10-03T05:45:44.785Z",
    },
    VendorJobLedgers: [
      {
        Id: 41,
        VendorId: 4,
        Comment: "rrrr",
        AccountId: 15438,
        JobStatus: "REJECTED",
        BankUserId: "5119b758-e9cf-426e-bf19-488a0f7519c6",
        CreatedAt: "2023-10-19T15:43:26.635Z",
        UpdatedAt: "2023-10-19T15:43:26.636Z",
        Vendor: {
          VendorID: 4,
          VendorName: "Esther Okon",
          VendorEmail: "eltoshcollection@gmail.com",
          VendorPassword: null,
          VendorRefState: null,
          SLA: 0,
          UserId: "8ed93a58-8393-46b5-b39a-1ae56f47866e",
          Type: "vendor",
          VendorAcctNo: null,
          Approved: true,
          VendorReset: false,
          VendorAddress: null,
          VendorRefSol: null,
          VendorRefLG: null,
          IsDeleted: false,
          AccountType: "INDIVIDUAL",
          CreatedAt: "2023-10-04T13:11:49.328Z",
          UpdatedAt: "2023-10-26T22:03:12.431Z",
          VendorId: null,
        },
      },
    ],
    VendorJobs: [
      {
        Id: 9282,
        VendorId: 4,
        AccountId: 15438,
        StateId: 24,
        Completed: false,
        CreatedAt: "2023-10-06T09:12:33.456Z",
        UpdatedAt: "2023-10-06T09:12:33.456Z",
        Vendor: {
          VendorID: 4,
          VendorName: "Esther Okon",
          VendorEmail: "eltoshcollection@gmail.com",
          VendorPassword: null,
          VendorRefState: null,
          SLA: 0,
          UserId: "8ed93a58-8393-46b5-b39a-1ae56f47866e",
          Type: "vendor",
          VendorAcctNo: null,
          Approved: true,
          VendorReset: false,
          VendorAddress: null,
          VendorRefSol: null,
          VendorRefLG: null,
          IsDeleted: false,
          AccountType: "INDIVIDUAL",
          CreatedAt: "2023-10-04T13:11:49.328Z",
          UpdatedAt: "2023-10-26T22:03:12.431Z",
          VendorId: null,
        },
      },
    ],
  },

  {
    AcctRefID: 29215,
    CIFID: null,
    AcctNumber: "2446148156",
    AcctName: "Oladurun Wilson",
    AcctAddress: " F9 Lagos City Mall, opp. Muson Center",
    UpdatedAcctAddress: null,
    NeedsAdditionalInfo: false,
    AcctRefSol: null,
    DateImportFinacle: null,
    DateAcctOpened: "2023-01-01",
    AcctType: true,
    AcctRefLG: null,
    AcctRefCity: "LAGOS ISLAND",
    AcctRefState: "LAGOS",
    StateId: 24,
    AcctRefCountry: null,
    AcctReference: null,
    AcctPhone: null,
    IsFunded: 1,
    IsVerifyAddress: true,
    Pnd: null,
    Memo: null,
    IsVendorAssigned: false,
    DateAssigned: "2023-10-06T07:36:48.257Z",
    AssignedVendorID: null,
    AssignedAgentID: null,
    AssignedAgentName: null,
    IsVendorVerified: null,
    DateVerified: null,
    VerifiedByID: null,
    VerifiedByName: null,
    OptionsVendorVerified: null,
    CommentsVendor: "",
    AddressDescription: "Km 46 Redemption city Lagos Ibadan expressway",
    AddressVerificationPhotos: [],
    PersonsMet: null,
    DateConfirmed: "2023-10-19T09:48:12.452Z",
    IsConfirmed: true,
    ConfirmedById: null,
    OptionsConfirmed: null,
    CommentsBank: null,
    IsVendorPaid: null,
    IsAcctDebited: null,
    SLA: 0,
    BVN: null,
    Email: null,
    AccountBalance: "700",
    TAT: null,
    AccountType: "INDIVIDUAL",
    JobStatus: "REJECTED",
    AddedInfo: "SUCCESSFUL",
    FundingCheckCount: 3,
    CreatedAt: "2023-10-05T14:11:32.431Z",
    UpdatedAt: "2023-10-19T12:22:49.364Z",
    AssignedVendor: null,
    State: {
      Id: 24,
      Name: "LAGOS",
      Code: "LA",
      CreatedAt: "2023-10-03T05:45:44.785Z",
      UpdatedAt: "2023-10-03T05:45:44.785Z",
    },
    VendorJobLedgers: [
      {
        Id: 38,
        VendorId: 4,
        Comment: "Incomplete",
        AccountId: 29215,
        JobStatus: "REJECTED",
        BankUserId: "2bbc0be3-10a1-4210-a4af-0e1f5abb1987",
        CreatedAt: "2023-10-19T12:22:49.342Z",
        UpdatedAt: "2023-10-19T12:22:49.342Z",
        Vendor: {
          VendorID: 4,
          VendorName: "Esther Okon",
          VendorEmail: "eltoshcollection@gmail.com",
          VendorPassword: null,
          VendorRefState: null,
          SLA: 0,
          UserId: "8ed93a58-8393-46b5-b39a-1ae56f47866e",
          Type: "vendor",
          VendorAcctNo: null,
          Approved: true,
          VendorReset: false,
          VendorAddress: null,
          VendorRefSol: null,
          VendorRefLG: null,
          IsDeleted: false,
          AccountType: "INDIVIDUAL",
          CreatedAt: "2023-10-04T13:11:49.328Z",
          UpdatedAt: "2023-10-26T22:03:12.431Z",
          VendorId: null,
        },
      },
    ],
    VendorJobs: [
      {
        Id: 9251,
        VendorId: 4,
        AccountId: 29215,
        StateId: 24,
        Completed: false,
        CreatedAt: "2023-10-06T07:20:39.961Z",
        UpdatedAt: "2023-10-06T07:20:39.961Z",
        Vendor: {
          VendorID: 4,
          VendorName: "Esther Okon",
          VendorEmail: "eltoshcollection@gmail.com",
          VendorPassword: null,
          VendorRefState: null,
          SLA: 0,
          UserId: "8ed93a58-8393-46b5-b39a-1ae56f47866e",
          Type: "vendor",
          VendorAcctNo: null,
          Approved: true,
          VendorReset: false,
          VendorAddress: null,
          VendorRefSol: null,
          VendorRefLG: null,
          IsDeleted: false,
          AccountType: "INDIVIDUAL",
          CreatedAt: "2023-10-04T13:11:49.328Z",
          UpdatedAt: "2023-10-26T22:03:12.431Z",
          VendorId: null,
        },
      },
      {
        Id: 9276,
        VendorId: 4,
        AccountId: 29215,
        StateId: 24,
        Completed: false,
        CreatedAt: "2023-10-06T07:36:48.098Z",
        UpdatedAt: "2023-10-06T07:36:48.098Z",
        Vendor: {
          VendorID: 4,
          VendorName: "Esther Okon",
          VendorEmail: "eltoshcollection@gmail.com",
          VendorPassword: null,
          VendorRefState: null,
          SLA: 0,
          UserId: "8ed93a58-8393-46b5-b39a-1ae56f47866e",
          Type: "vendor",
          VendorAcctNo: null,
          Approved: true,
          VendorReset: false,
          VendorAddress: null,
          VendorRefSol: null,
          VendorRefLG: null,
          IsDeleted: false,
          AccountType: "INDIVIDUAL",
          CreatedAt: "2023-10-04T13:11:49.328Z",
          UpdatedAt: "2023-10-26T22:03:12.431Z",
          VendorId: null,
        },
      },
    ],
  },

  {
    AcctRefID: 109589,
    CIFID: null,
    AcctNumber: "2665342660",
    AcctName: "Obefemi White",
    AcctAddress: "1st/2nd Floor winner's plaza, 17 Balogun St, Lagos Island",
    UpdatedAcctAddress: null,
    NeedsAdditionalInfo: false,
    AcctRefSol: null,
    DateImportFinacle: null,
    DateAcctOpened: "2021-01-01",
    AcctType: true,
    AcctRefLG: null,
    AcctRefCity: "LAGOS ISLAND",
    AcctRefState: "LAGOS",
    StateId: 24,
    AcctRefCountry: null,
    AcctReference: null,
    AcctPhone: null,
    IsFunded: 1,
    IsVerifyAddress: true,
    Pnd: null,
    Memo: null,
    IsVendorAssigned: false,
    DateAssigned: "2023-10-06T10:34:14.058Z",
    AssignedVendorID: null,
    AssignedAgentID: null,
    AssignedAgentName: null,
    IsVendorVerified: null,
    DateVerified: null,
    VerifiedByID: null,
    VerifiedByName: null,
    OptionsVendorVerified: null,
    CommentsVendor: "Real address",
    AddressDescription:
      "1st/2nd Floor winner's plaza, 17 Balogun St, Lagos Island",
    AddressVerificationPhotos: [
      "https://uba-mobile.s3.us-east-2.amazonaws.com/%27public%27/uploads/Screenshot%20from%202023-04-02%2008-47-25.png",
    ],
    PersonsMet: null,
    DateConfirmed: "2023-10-16T22:11:18.341Z",
    IsConfirmed: true,
    ConfirmedById: null,
    OptionsConfirmed: null,
    CommentsBank: null,
    IsVendorPaid: null,
    IsAcctDebited: null,
    SLA: 0,
    BVN: null,
    Email: null,
    AccountBalance: "5000",
    TAT: null,
    AccountType: "INDIVIDUAL",
    JobStatus: "REJECTED",
    AddedInfo: "SUCCESSFUL",
    FundingCheckCount: 3,
    CreatedAt: "2023-10-06T01:29:30.092Z",
    UpdatedAt: "2023-10-17T13:15:06.554Z",
    AssignedVendor: null,
    State: {
      Id: 24,
      Name: "LAGOS",
      Code: "LA",
      CreatedAt: "2023-10-03T05:45:44.785Z",
      UpdatedAt: "2023-10-03T05:45:44.785Z",
    },
    VendorJobLedgers: [
      {
        Id: 30,
        VendorId: 4,
        Comment: "Can not finalise this",
        AccountId: 109589,
        JobStatus: "REJECTED",
        BankUserId: "2bbc0be3-10a1-4210-a4af-0e1f5abb1987",
        CreatedAt: "2023-10-17T13:15:06.536Z",
        UpdatedAt: "2023-10-17T13:15:06.536Z",
        Vendor: {
          VendorID: 4,
          VendorName: "Esther Okon",
          VendorEmail: "eltoshcollection@gmail.com",
          VendorPassword: null,
          VendorRefState: null,
          SLA: 0,
          UserId: "8ed93a58-8393-46b5-b39a-1ae56f47866e",
          Type: "vendor",
          VendorAcctNo: null,
          Approved: true,
          VendorReset: false,
          VendorAddress: null,
          VendorRefSol: null,
          VendorRefLG: null,
          IsDeleted: false,
          AccountType: "INDIVIDUAL",
          CreatedAt: "2023-10-04T13:11:49.328Z",
          UpdatedAt: "2023-10-26T22:03:12.431Z",
          VendorId: null,
        },
      },
    ],
    VendorJobs: [
      {
        Id: 9303,
        VendorId: 4,
        AccountId: 109589,
        StateId: 24,
        Completed: false,
        CreatedAt: "2023-10-06T10:34:13.905Z",
        UpdatedAt: "2023-10-06T10:34:13.906Z",
        Vendor: {
          VendorID: 4,
          VendorName: "Esther Okon",
          VendorEmail: "eltoshcollection@gmail.com",
          VendorPassword: null,
          VendorRefState: null,
          SLA: 0,
          UserId: "8ed93a58-8393-46b5-b39a-1ae56f47866e",
          Type: "vendor",
          VendorAcctNo: null,
          Approved: true,
          VendorReset: false,
          VendorAddress: null,
          VendorRefSol: null,
          VendorRefLG: null,
          IsDeleted: false,
          AccountType: "INDIVIDUAL",
          CreatedAt: "2023-10-04T13:11:49.328Z",
          UpdatedAt: "2023-10-26T22:03:12.431Z",
          VendorId: null,
        },
      },
    ],
  },

  {
    AcctRefID: 307892,
    CIFID: null,
    AcctNumber: "2737084741",
    AcctName: "Charlie Harris",
    AcctAddress: "1st/2nd Floor winner's plaza, 17 Balogun St, Lagos Island",
    UpdatedAcctAddress: null,
    NeedsAdditionalInfo: false,
    AcctRefSol: null,
    DateImportFinacle: null,
    DateAcctOpened: "2021-01-01",
    AcctType: true,
    AcctRefLG: null,
    AcctRefCity: "LAGOS ISLAND",
    AcctRefState: "LAGOS",
    StateId: 24,
    AcctRefCountry: null,
    AcctReference: null,
    AcctPhone: null,
    IsFunded: 1,
    IsVerifyAddress: true,
    Pnd: null,
    Memo: null,
    IsVendorAssigned: false,
    DateAssigned: "2023-10-07T17:11:22.257Z",
    AssignedVendorID: null,
    AssignedAgentID: null,
    AssignedAgentName: null,
    IsVendorVerified: null,
    DateVerified: null,
    VerifiedByID: null,
    VerifiedByName: null,
    OptionsVendorVerified: null,
    CommentsVendor: "done",
    AddressDescription: "Close",
    AddressVerificationPhotos: [],
    PersonsMet: null,
    DateConfirmed: "2023-10-16T09:07:06.598Z",
    IsConfirmed: true,
    ConfirmedById: null,
    OptionsConfirmed: null,
    CommentsBank: null,
    IsVendorPaid: null,
    IsAcctDebited: null,
    SLA: 0,
    BVN: null,
    Email: null,
    AccountBalance: "5000",
    TAT: null,
    AccountType: "INDIVIDUAL",
    JobStatus: "REJECTED",
    AddedInfo: "UNSUCCESSFUL",
    FundingCheckCount: 3,
    CreatedAt: "2023-10-07T05:57:00.122Z",
    UpdatedAt: "2023-10-16T09:13:29.389Z",
    AssignedVendor: null,
    State: {
      Id: 24,
      Name: "LAGOS",
      Code: "LA",
      CreatedAt: "2023-10-03T05:45:44.785Z",
      UpdatedAt: "2023-10-03T05:45:44.785Z",
    },
    VendorJobLedgers: [
      {
        Id: 24,
        VendorId: 4,
        Comment: "Closed",
        AccountId: 307892,
        JobStatus: "REJECTED",
        BankUserId: "2bbc0be3-10a1-4210-a4af-0e1f5abb1987",
        CreatedAt: "2023-10-16T09:13:29.371Z",
        UpdatedAt: "2023-10-16T09:13:29.371Z",
        Vendor: {
          VendorID: 4,
          VendorName: "Esther Okon",
          VendorEmail: "eltoshcollection@gmail.com",
          VendorPassword: null,
          VendorRefState: null,
          SLA: 0,
          UserId: "8ed93a58-8393-46b5-b39a-1ae56f47866e",
          Type: "vendor",
          VendorAcctNo: null,
          Approved: true,
          VendorReset: false,
          VendorAddress: null,
          VendorRefSol: null,
          VendorRefLG: null,
          IsDeleted: false,
          AccountType: "INDIVIDUAL",
          CreatedAt: "2023-10-04T13:11:49.328Z",
          UpdatedAt: "2023-10-26T22:03:12.431Z",
          VendorId: null,
        },
      },
    ],
    VendorJobs: [
      {
        Id: 9307,
        VendorId: 4,
        AccountId: 307892,
        StateId: 24,
        Completed: false,
        CreatedAt: "2023-10-07T17:11:14.101Z",
        UpdatedAt: "2023-10-07T17:11:14.103Z",
        Vendor: {
          VendorID: 4,
          VendorName: "Esther Okon",
          VendorEmail: "eltoshcollection@gmail.com",
          VendorPassword: null,
          VendorRefState: null,
          SLA: 0,
          UserId: "8ed93a58-8393-46b5-b39a-1ae56f47866e",
          Type: "vendor",
          VendorAcctNo: null,
          Approved: true,
          VendorReset: false,
          VendorAddress: null,
          VendorRefSol: null,
          VendorRefLG: null,
          IsDeleted: false,
          AccountType: "INDIVIDUAL",
          CreatedAt: "2023-10-04T13:11:49.328Z",
          UpdatedAt: "2023-10-26T22:03:12.431Z",
          VendorId: null,
        },
      },
    ],
  },

  {
    AcctRefID: 205580,
    CIFID: null,
    AcctNumber: "2974987609",
    AcctName: "Obefemi Peter",
    AcctAddress: "4 lafiaji drive",
    UpdatedAcctAddress: "House 2, Road 4 Omolayo EState.",
    NeedsAdditionalInfo: false,
    AcctRefSol: null,
    DateImportFinacle: null,
    DateAcctOpened: "2021-01-01",
    AcctType: true,
    AcctRefLG: null,
    AcctRefCity: "IKOYI",
    AcctRefState: "LAGOS",
    StateId: 24,
    AcctRefCountry: null,
    AcctReference: null,
    AcctPhone: null,
    IsFunded: 1,
    IsVerifyAddress: true,
    Pnd: null,
    Memo: null,
    IsVendorAssigned: false,
    DateAssigned: "2023-10-10T09:34:19.696Z",
    AssignedVendorID: null,
    AssignedAgentID: null,
    AssignedAgentName: null,
    IsVendorVerified: null,
    DateVerified: null,
    VerifiedByID: null,
    VerifiedByName: null,
    OptionsVendorVerified: null,
    CommentsVendor: "A darkbrown house by the left",
    AddressDescription: "rumukurushi close",
    AddressVerificationPhotos: [],
    PersonsMet: null,
    DateConfirmed: "2023-10-16T06:50:44.229Z",
    IsConfirmed: true,
    ConfirmedById: null,
    OptionsConfirmed: null,
    CommentsBank: null,
    IsVendorPaid: null,
    IsAcctDebited: null,
    SLA: 0,
    BVN: null,
    Email: null,
    AccountBalance: "5075",
    TAT: null,
    AccountType: "INDIVIDUAL",
    JobStatus: "REJECTED",
    AddedInfo: "SUCCESSFUL",
    FundingCheckCount: 3,
    CreatedAt: "2023-10-06T12:41:00.154Z",
    UpdatedAt: "2023-10-16T06:58:33.599Z",
    AssignedVendor: null,
    State: {
      Id: 24,
      Name: "LAGOS",
      Code: "LA",
      CreatedAt: "2023-10-03T05:45:44.785Z",
      UpdatedAt: "2023-10-03T05:45:44.785Z",
    },
    VendorJobLedgers: [
      {
        Id: 18,
        VendorId: 4,
        Comment: "Not thorough",
        AccountId: 205580,
        JobStatus: "REJECTED",
        BankUserId: "2bbc0be3-10a1-4210-a4af-0e1f5abb1987",
        CreatedAt: "2023-10-16T06:58:33.585Z",
        UpdatedAt: "2023-10-16T06:58:33.586Z",
        Vendor: {
          VendorID: 4,
          VendorName: "Esther Okon",
          VendorEmail: "eltoshcollection@gmail.com",
          VendorPassword: null,
          VendorRefState: null,
          SLA: 0,
          UserId: "8ed93a58-8393-46b5-b39a-1ae56f47866e",
          Type: "vendor",
          VendorAcctNo: null,
          Approved: true,
          VendorReset: false,
          VendorAddress: null,
          VendorRefSol: null,
          VendorRefLG: null,
          IsDeleted: false,
          AccountType: "INDIVIDUAL",
          CreatedAt: "2023-10-04T13:11:49.328Z",
          UpdatedAt: "2023-10-26T22:03:12.431Z",
          VendorId: null,
        },
      },
    ],
    VendorJobs: [
      {
        Id: 9342,
        VendorId: 4,
        AccountId: 205580,
        StateId: 24,
        Completed: false,
        CreatedAt: "2023-10-10T09:34:17.731Z",
        UpdatedAt: "2023-10-10T09:34:17.736Z",
        Vendor: {
          VendorID: 4,
          VendorName: "Esther Okon",
          VendorEmail: "eltoshcollection@gmail.com",
          VendorPassword: null,
          VendorRefState: null,
          SLA: 0,
          UserId: "8ed93a58-8393-46b5-b39a-1ae56f47866e",
          Type: "vendor",
          VendorAcctNo: null,
          Approved: true,
          VendorReset: false,
          VendorAddress: null,
          VendorRefSol: null,
          VendorRefLG: null,
          IsDeleted: false,
          AccountType: "INDIVIDUAL",
          CreatedAt: "2023-10-04T13:11:49.328Z",
          UpdatedAt: "2023-10-26T22:03:12.431Z",
          VendorId: null,
        },
      },
    ],
  },
]; // 10 Jobs

export const awaitingPaymentsMock = [
  // 5 Jobs
  {
    AcctRefID: 29242,
    CIFID: null,
    AcctNumber: "2695207583",
    AcctName: "Ursula Miller",
    AcctAddress: "3 Fair View Appartment, Dolphin Estate",
    UpdatedAcctAddress: null,
    NeedsAdditionalInfo: false,
    AcctRefSol: null,
    DateImportFinacle: null,
    DateAcctOpened: "2022-01-01",
    AcctType: true,
    AcctRefLG: null,
    AcctRefCity: "IKOYI",
    AcctRefState: "LAGOS",
    StateId: 24,
    AcctRefCountry: null,
    AcctReference: null,
    AcctPhone: null,
    IsFunded: 1,
    IsVerifyAddress: true,
    Pnd: null,
    Memo: null,
    IsVendorAssigned: true,
    DateAssigned: "2023-10-06T07:22:50.229Z",
    AssignedVendorID: 4,
    AssignedAgentID: null,
    AssignedAgentName: null,
    IsVendorVerified: null,
    DateVerified: null,
    VerifiedByID: null,
    VerifiedByName: null,
    OptionsVendorVerified: null,
    CommentsVendor: "No comment available",
    AddressDescription: "No description available",
    AddressVerificationPhotos: [],
    PersonsMet: null,
    DateConfirmed: "2023-10-26T12:42:23.860Z",
    IsConfirmed: true,
    ConfirmedById: null,
    OptionsConfirmed: null,
    CommentsBank: null,
    IsVendorPaid: null,
    IsAcctDebited: null,
    SLA: 0,
    BVN: null,
    Email: null,
    AccountBalance: "5075",
    TAT: null,
    AccountType: "INDIVIDUAL",
    JobStatus: "COMPLETED",
    AddedInfo: "SUCCESSFUL",
    FundingCheckCount: 3,
    CreatedAt: "2023-10-05T14:12:00.057Z",
    UpdatedAt: "2023-10-26T13:25:49.034Z",
    AssignedVendor: {
      VendorID: 4,
      VendorName: "Esther Okon",
      VendorEmail: "eltoshcollection@gmail.com",
      VendorPassword: null,
      VendorRefState: null,
      SLA: 0,
      UserId: "8ed93a58-8393-46b5-b39a-1ae56f47866e",
      Type: "vendor",
      VendorAcctNo: null,
      Approved: true,
      VendorReset: false,
      VendorAddress: null,
      VendorRefSol: null,
      VendorRefLG: null,
      IsDeleted: false,
      AccountType: "INDIVIDUAL",
      CreatedAt: "2023-10-04T13:11:49.328Z",
      UpdatedAt: "2023-10-26T22:03:12.431Z",
      VendorId: null,
    },
    State: {
      Id: 24,
      Name: "LAGOS",
      Code: "LA",
      CreatedAt: "2023-10-03T05:45:44.785Z",
      UpdatedAt: "2023-10-03T05:45:44.785Z",
    },
    VendorJobLedgers: [
      {
        Id: 58,
        VendorId: 4,
        Comment: "Job confirmed",
        AccountId: 29242,
        JobStatus: "COMPLETED",
        BankUserId: "5119b758-e9cf-426e-bf19-488a0f7519c6",
        CreatedAt: "2023-10-26T13:25:49.027Z",
        UpdatedAt: "2023-10-26T13:25:49.027Z",
        Vendor: {
          VendorID: 4,
          VendorName: "Esther Okon",
          VendorEmail: "eltoshcollection@gmail.com",
          VendorPassword: null,
          VendorRefState: null,
          SLA: 0,
          UserId: "8ed93a58-8393-46b5-b39a-1ae56f47866e",
          Type: "vendor",
          VendorAcctNo: null,
          Approved: true,
          VendorReset: false,
          VendorAddress: null,
          VendorRefSol: null,
          VendorRefLG: null,
          IsDeleted: false,
          AccountType: "INDIVIDUAL",
          CreatedAt: "2023-10-04T13:11:49.328Z",
          UpdatedAt: "2023-10-26T22:03:12.431Z",
          VendorId: null,
        },
      },
    ],
    VendorJobs: [],
    JobRejections: [],
  },
  {
    AcctRefID: 15055,
    CIFID: null,
    AcctNumber: "2989254636",
    AcctName: "Henry Harris",
    AcctAddress: "3 Boye Street",
    UpdatedAcctAddress: null,
    NeedsAdditionalInfo: false,
    AcctRefSol: null,
    DateImportFinacle: null,
    DateAcctOpened: "2022-01-01",
    AcctType: true,
    AcctRefLG: null,
    AcctRefCity: "OSHODI",
    AcctRefState: "LAGOS",
    StateId: 24,
    AcctRefCountry: null,
    AcctReference: null,
    AcctPhone: null,
    IsFunded: 1,
    IsVerifyAddress: true,
    Pnd: null,
    Memo: null,
    IsVendorAssigned: true,
    DateAssigned: "2023-10-26T12:50:44.927Z",
    AssignedVendorID: 22,
    AssignedAgentID: null,
    AssignedAgentName: "Richard Chikezie",
    IsVendorVerified: true,
    DateVerified: null,
    VerifiedByID: null,
    VerifiedByName: null,
    OptionsVendorVerified: null,
    CommentsVendor: "Okay",
    AddressDescription: "Valid",
    AddressVerificationPhotos: [],
    PersonsMet: null,
    DateConfirmed: "2023-10-26T12:53:29.159Z",
    IsConfirmed: true,
    ConfirmedById: null,
    OptionsConfirmed: null,
    CommentsBank: null,
    IsVendorPaid: null,
    IsAcctDebited: null,
    SLA: 0,
    BVN: null,
    Email: null,
    AccountBalance: "5000",
    TAT: null,
    AccountType: "INDIVIDUAL",
    JobStatus: "COMPLETED",
    AddedInfo: "SUCCESSFUL",
    FundingCheckCount: 3,
    CreatedAt: "2023-10-05T12:52:00.035Z",
    UpdatedAt: "2023-10-26T13:25:34.077Z",
    AssignedVendor: {
      VendorID: 22,
      VendorName: "Richard Chikezie",
      VendorEmail: "richardsprojects@gmail.com",
      VendorPassword: null,
      VendorRefState: null,
      SLA: 0,
      UserId: "702b5ecc-c6e5-48a6-b878-2ce6f0b4a666",
      Type: "vendor",
      VendorAcctNo: "0107052522",
      Approved: true,
      VendorReset: false,
      VendorAddress: null,
      VendorRefSol: null,
      VendorRefLG: null,
      IsDeleted: false,
      AccountType: "INDIVIDUAL",
      CreatedAt: "2023-10-26T11:43:48.375Z",
      UpdatedAt: "2023-10-26T22:01:34.709Z",
      VendorId: null,
    },
    State: {
      Id: 24,
      Name: "LAGOS",
      Code: "LA",
      CreatedAt: "2023-10-03T05:45:44.785Z",
      UpdatedAt: "2023-10-03T05:45:44.785Z",
    },
    VendorJobLedgers: [
      {
        Id: 57,
        VendorId: 22,
        Comment: "Job confirmed",
        AccountId: 15055,
        JobStatus: "COMPLETED",
        BankUserId: "5119b758-e9cf-426e-bf19-488a0f7519c6",
        CreatedAt: "2023-10-26T13:25:34.068Z",
        UpdatedAt: "2023-10-26T13:25:34.068Z",
        Vendor: {
          VendorID: 22,
          VendorName: "Richard Chikezie",
          VendorEmail: "richardsprojects@gmail.com",
          VendorPassword: null,
          VendorRefState: null,
          SLA: 0,
          UserId: "702b5ecc-c6e5-48a6-b878-2ce6f0b4a666",
          Type: "vendor",
          VendorAcctNo: "0107052522",
          Approved: true,
          VendorReset: false,
          VendorAddress: null,
          VendorRefSol: null,
          VendorRefLG: null,
          IsDeleted: false,
          AccountType: "INDIVIDUAL",
          CreatedAt: "2023-10-26T11:43:48.375Z",
          UpdatedAt: "2023-10-26T22:01:34.709Z",
          VendorId: null,
        },
      },
      {
        Id: 53,
        VendorId: 4,
        Comment: "",
        AccountId: 15055,
        JobStatus: "REJECTED",
        BankUserId: "1678f8f6-abf7-4ed1-b2a8-a523be8ad127",
        CreatedAt: "2023-10-26T12:50:44.910Z",
        UpdatedAt: "2023-10-26T12:50:44.910Z",
        Vendor: {
          VendorID: 4,
          VendorName: "Esther Okon",
          VendorEmail: "eltoshcollection@gmail.com",
          VendorPassword: null,
          VendorRefState: null,
          SLA: 0,
          UserId: "8ed93a58-8393-46b5-b39a-1ae56f47866e",
          Type: "vendor",
          VendorAcctNo: null,
          Approved: true,
          VendorReset: false,
          VendorAddress: null,
          VendorRefSol: null,
          VendorRefLG: null,
          IsDeleted: false,
          AccountType: "INDIVIDUAL",
          CreatedAt: "2023-10-04T13:11:49.328Z",
          UpdatedAt: "2023-10-26T22:03:12.431Z",
          VendorId: null,
        },
      },
    ],
    VendorJobs: [],
  },
  {
    AcctRefID: 307892,
    CIFID: null,
    AcctNumber: "2737084741",
    AcctName: "Charlie Harris",
    AcctAddress: "1st/2nd Floor winner's plaza, 17 Balogun St, Lagos Island",
    UpdatedAcctAddress: null,
    NeedsAdditionalInfo: false,
    AcctRefSol: null,
    DateImportFinacle: null,
    DateAcctOpened: "2021-01-01",
    AcctType: true,
    AcctRefLG: null,
    AcctRefCity: "LAGOS ISLAND",
    AcctRefState: "LAGOS",
    StateId: 24,
    AcctRefCountry: null,
    AcctReference: null,
    AcctPhone: null,
    IsFunded: 1,
    IsVerifyAddress: true,
    Pnd: null,
    Memo: null,
    IsVendorAssigned: false,
    DateAssigned: "2023-10-07T17:11:22.257Z",
    AssignedVendorID: null,
    AssignedAgentID: null,
    AssignedAgentName: null,
    IsVendorVerified: null,
    DateVerified: null,
    VerifiedByID: null,
    VerifiedByName: null,
    OptionsVendorVerified: null,
    CommentsVendor: "done",
    AddressDescription: "Close",
    AddressVerificationPhotos: [],
    PersonsMet: null,
    DateConfirmed: "2023-10-16T09:07:06.598Z",
    IsConfirmed: true,
    ConfirmedById: null,
    OptionsConfirmed: null,
    CommentsBank: null,
    IsVendorPaid: null,
    IsAcctDebited: null,
    SLA: 0,
    BVN: null,
    Email: null,
    AccountBalance: "5000",
    TAT: null,
    AccountType: "INDIVIDUAL",
    JobStatus: "REJECTED",
    AddedInfo: "UNSUCCESSFUL",
    FundingCheckCount: 3,
    CreatedAt: "2023-10-07T05:57:00.122Z",
    UpdatedAt: "2023-10-16T09:13:29.389Z",
    AssignedVendor: null,
    State: {
      Id: 24,
      Name: "LAGOS",
      Code: "LA",
      CreatedAt: "2023-10-03T05:45:44.785Z",
      UpdatedAt: "2023-10-03T05:45:44.785Z",
    },
    VendorJobLedgers: [
      {
        Id: 24,
        VendorId: 4,
        Comment: "Closed",
        AccountId: 307892,
        JobStatus: "REJECTED",
        BankUserId: "2bbc0be3-10a1-4210-a4af-0e1f5abb1987",
        CreatedAt: "2023-10-16T09:13:29.371Z",
        UpdatedAt: "2023-10-16T09:13:29.371Z",
        Vendor: {
          VendorID: 4,
          VendorName: "Esther Okon",
          VendorEmail: "eltoshcollection@gmail.com",
          VendorPassword: null,
          VendorRefState: null,
          SLA: 0,
          UserId: "8ed93a58-8393-46b5-b39a-1ae56f47866e",
          Type: "vendor",
          VendorAcctNo: null,
          Approved: true,
          VendorReset: false,
          VendorAddress: null,
          VendorRefSol: null,
          VendorRefLG: null,
          IsDeleted: false,
          AccountType: "INDIVIDUAL",
          CreatedAt: "2023-10-04T13:11:49.328Z",
          UpdatedAt: "2023-10-26T22:03:12.431Z",
          VendorId: null,
        },
      },
    ],
    VendorJobs: [
      {
        Id: 9307,
        VendorId: 4,
        AccountId: 307892,
        StateId: 24,
        Completed: false,
        CreatedAt: "2023-10-07T17:11:14.101Z",
        UpdatedAt: "2023-10-07T17:11:14.103Z",
        Vendor: {
          VendorID: 4,
          VendorName: "Esther Okon",
          VendorEmail: "eltoshcollection@gmail.com",
          VendorPassword: null,
          VendorRefState: null,
          SLA: 0,
          UserId: "8ed93a58-8393-46b5-b39a-1ae56f47866e",
          Type: "vendor",
          VendorAcctNo: null,
          Approved: true,
          VendorReset: false,
          VendorAddress: null,
          VendorRefSol: null,
          VendorRefLG: null,
          IsDeleted: false,
          AccountType: "INDIVIDUAL",
          CreatedAt: "2023-10-04T13:11:49.328Z",
          UpdatedAt: "2023-10-26T22:03:12.431Z",
          VendorId: null,
        },
      },
    ],
  },
  {
    AcctRefID: 205580,
    CIFID: null,
    AcctNumber: "2974987609",
    AcctName: "Obefemi Peter",
    AcctAddress: "4 lafiaji drive",
    UpdatedAcctAddress: "House 2, Road 4 Omolayo EState.",
    NeedsAdditionalInfo: false,
    AcctRefSol: null,
    DateImportFinacle: null,
    DateAcctOpened: "2021-01-01",
    AcctType: true,
    AcctRefLG: null,
    AcctRefCity: "IKOYI",
    AcctRefState: "LAGOS",
    StateId: 24,
    AcctRefCountry: null,
    AcctReference: null,
    AcctPhone: null,
    IsFunded: 1,
    IsVerifyAddress: true,
    Pnd: null,
    Memo: null,
    IsVendorAssigned: false,
    DateAssigned: "2023-10-10T09:34:19.696Z",
    AssignedVendorID: null,
    AssignedAgentID: null,
    AssignedAgentName: null,
    IsVendorVerified: null,
    DateVerified: null,
    VerifiedByID: null,
    VerifiedByName: null,
    OptionsVendorVerified: null,
    CommentsVendor: "A darkbrown house by the left",
    AddressDescription: "rumukurushi close",
    AddressVerificationPhotos: [],
    PersonsMet: null,
    DateConfirmed: "2023-10-16T06:50:44.229Z",
    IsConfirmed: true,
    ConfirmedById: null,
    OptionsConfirmed: null,
    CommentsBank: null,
    IsVendorPaid: null,
    IsAcctDebited: null,
    SLA: 0,
    BVN: null,
    Email: null,
    AccountBalance: "5075",
    TAT: null,
    AccountType: "INDIVIDUAL",
    JobStatus: "REJECTED",
    AddedInfo: "SUCCESSFUL",
    FundingCheckCount: 3,
    CreatedAt: "2023-10-06T12:41:00.154Z",
    UpdatedAt: "2023-10-16T06:58:33.599Z",
    AssignedVendor: null,
    State: {
      Id: 24,
      Name: "LAGOS",
      Code: "LA",
      CreatedAt: "2023-10-03T05:45:44.785Z",
      UpdatedAt: "2023-10-03T05:45:44.785Z",
    },
    VendorJobLedgers: [
      {
        Id: 18,
        VendorId: 4,
        Comment: "Not thorough",
        AccountId: 205580,
        JobStatus: "REJECTED",
        BankUserId: "2bbc0be3-10a1-4210-a4af-0e1f5abb1987",
        CreatedAt: "2023-10-16T06:58:33.585Z",
        UpdatedAt: "2023-10-16T06:58:33.586Z",
        Vendor: {
          VendorID: 4,
          VendorName: "Esther Okon",
          VendorEmail: "eltoshcollection@gmail.com",
          VendorPassword: null,
          VendorRefState: null,
          SLA: 0,
          UserId: "8ed93a58-8393-46b5-b39a-1ae56f47866e",
          Type: "vendor",
          VendorAcctNo: null,
          Approved: true,
          VendorReset: false,
          VendorAddress: null,
          VendorRefSol: null,
          VendorRefLG: null,
          IsDeleted: false,
          AccountType: "INDIVIDUAL",
          CreatedAt: "2023-10-04T13:11:49.328Z",
          UpdatedAt: "2023-10-26T22:03:12.431Z",
          VendorId: null,
        },
      },
    ],
    VendorJobs: [
      {
        Id: 9342,
        VendorId: 4,
        AccountId: 205580,
        StateId: 24,
        Completed: false,
        CreatedAt: "2023-10-10T09:34:17.731Z",
        UpdatedAt: "2023-10-10T09:34:17.736Z",
        Vendor: {
          VendorID: 4,
          VendorName: "Esther Okon",
          VendorEmail: "eltoshcollection@gmail.com",
          VendorPassword: null,
          VendorRefState: null,
          SLA: 0,
          UserId: "8ed93a58-8393-46b5-b39a-1ae56f47866e",
          Type: "vendor",
          VendorAcctNo: null,
          Approved: true,
          VendorReset: false,
          VendorAddress: null,
          VendorRefSol: null,
          VendorRefLG: null,
          IsDeleted: false,
          AccountType: "INDIVIDUAL",
          CreatedAt: "2023-10-04T13:11:49.328Z",
          UpdatedAt: "2023-10-26T22:03:12.431Z",
          VendorId: null,
        },
      },
    ],
  },
  {
    AcctRefID: 109589,
    CIFID: null,
    AcctNumber: "2665342660",
    AcctName: "Obefemi White",
    AcctAddress: "1st/2nd Floor winner's plaza, 17 Balogun St, Lagos Island",
    UpdatedAcctAddress: null,
    NeedsAdditionalInfo: false,
    AcctRefSol: null,
    DateImportFinacle: null,
    DateAcctOpened: "2021-01-01",
    AcctType: true,
    AcctRefLG: null,
    AcctRefCity: "LAGOS ISLAND",
    AcctRefState: "LAGOS",
    StateId: 24,
    AcctRefCountry: null,
    AcctReference: null,
    AcctPhone: null,
    IsFunded: 1,
    IsVerifyAddress: true,
    Pnd: null,
    Memo: null,
    IsVendorAssigned: false,
    DateAssigned: "2023-10-06T10:34:14.058Z",
    AssignedVendorID: null,
    AssignedAgentID: null,
    AssignedAgentName: null,
    IsVendorVerified: null,
    DateVerified: null,
    VerifiedByID: null,
    VerifiedByName: null,
    OptionsVendorVerified: null,
    CommentsVendor: "Real address",
    AddressDescription:
      "1st/2nd Floor winner's plaza, 17 Balogun St, Lagos Island",
    AddressVerificationPhotos: [
      "https://uba-mobile.s3.us-east-2.amazonaws.com/%27public%27/uploads/Screenshot%20from%202023-04-02%2008-47-25.png",
    ],
    PersonsMet: null,
    DateConfirmed: "2023-10-16T22:11:18.341Z",
    IsConfirmed: true,
    ConfirmedById: null,
    OptionsConfirmed: null,
    CommentsBank: null,
    IsVendorPaid: null,
    IsAcctDebited: null,
    SLA: 0,
    BVN: null,
    Email: null,
    AccountBalance: "5000",
    TAT: null,
    AccountType: "INDIVIDUAL",
    JobStatus: "REJECTED",
    AddedInfo: "SUCCESSFUL",
    FundingCheckCount: 3,
    CreatedAt: "2023-10-06T01:29:30.092Z",
    UpdatedAt: "2023-10-17T13:15:06.554Z",
    AssignedVendor: null,
    State: {
      Id: 24,
      Name: "LAGOS",
      Code: "LA",
      CreatedAt: "2023-10-03T05:45:44.785Z",
      UpdatedAt: "2023-10-03T05:45:44.785Z",
    },
    VendorJobLedgers: [
      {
        Id: 30,
        VendorId: 4,
        Comment: "Can not finalise this",
        AccountId: 109589,
        JobStatus: "REJECTED",
        BankUserId: "2bbc0be3-10a1-4210-a4af-0e1f5abb1987",
        CreatedAt: "2023-10-17T13:15:06.536Z",
        UpdatedAt: "2023-10-17T13:15:06.536Z",
        Vendor: {
          VendorID: 4,
          VendorName: "Esther Okon",
          VendorEmail: "eltoshcollection@gmail.com",
          VendorPassword: null,
          VendorRefState: null,
          SLA: 0,
          UserId: "8ed93a58-8393-46b5-b39a-1ae56f47866e",
          Type: "vendor",
          VendorAcctNo: null,
          Approved: true,
          VendorReset: false,
          VendorAddress: null,
          VendorRefSol: null,
          VendorRefLG: null,
          IsDeleted: false,
          AccountType: "INDIVIDUAL",
          CreatedAt: "2023-10-04T13:11:49.328Z",
          UpdatedAt: "2023-10-26T22:03:12.431Z",
          VendorId: null,
        },
      },
    ],
    VendorJobs: [
      {
        Id: 9303,
        VendorId: 4,
        AccountId: 109589,
        StateId: 24,
        Completed: false,
        CreatedAt: "2023-10-06T10:34:13.905Z",
        UpdatedAt: "2023-10-06T10:34:13.906Z",
        Vendor: {
          VendorID: 4,
          VendorName: "Esther Okon",
          VendorEmail: "eltoshcollection@gmail.com",
          VendorPassword: null,
          VendorRefState: null,
          SLA: 0,
          UserId: "8ed93a58-8393-46b5-b39a-1ae56f47866e",
          Type: "vendor",
          VendorAcctNo: null,
          Approved: true,
          VendorReset: false,
          VendorAddress: null,
          VendorRefSol: null,
          VendorRefLG: null,
          IsDeleted: false,
          AccountType: "INDIVIDUAL",
          CreatedAt: "2023-10-04T13:11:49.328Z",
          UpdatedAt: "2023-10-26T22:03:12.431Z",
          VendorId: null,
        },
      },
    ],
  },
]; // 5 Jobs

export const vendorsMock = [
  // 2 Jobs
  {
    VendorID: 4,
    VendorName: "Esther Okon",
    VendorEmail: "eltoshcollection@gmail.com",
    VendorPassword: null,
    VendorRefState: null,
    SLA: 0,
    UserId: "8ed93a58-8393-46b5-b39a-1ae56f47866e",
    Type: "vendor",
    VendorAcctNo: 7382615370,
    totalBill: 10000,
    totalJobs: 2,
    Approved: true,
    VendorReset: false,
    VendorAddress: null,
    VendorRefSol: null,
    VendorRefLG: null,
    IsDeleted: false,
    AccountType: "INDIVIDUAL",
    CreatedAt: "2023-10-04T13:11:49.328Z",
    UpdatedAt: "2023-10-26T22:03:12.431Z",
    VendorId: null,
  },
  {
    VendorID: 22,
    VendorName: "Richard Chikezie",
    VendorEmail: "richardsprojects@gmail.com",
    VendorPassword: null,
    VendorRefState: null,
    SLA: 0,
    UserId: "702b5ecc-c6e5-48a6-b878-2ce6f0b4a666",
    Type: "vendor",
    VendorAcctNo: "0107052522",
    totalBill: 15000,
    totalJobs: 3,
    Approved: true,
    VendorReset: false,
    VendorAddress: null,
    VendorRefSol: null,
    VendorRefLG: null,
    IsDeleted: false,
    AccountType: "INDIVIDUAL",
    CreatedAt: "2023-10-26T11:43:48.375Z",
    UpdatedAt: "2023-10-26T22:01:34.709Z",
    VendorId: null,
  },
]; // 2 Jobs

export const pendingJobsMock = [
  // 10 Jobs
  {
    AcctRefID: 33550,
    CIFID: null,
    AcctNumber: "2305068987",
    AcctName: "Mia Wilson",
    AcctAddress: "13 Dolphin Estate",
    UpdatedAcctAddress: null,
    NeedsAdditionalInfo: false,
    AcctRefSol: null,
    DateImportFinacle: null,
    DateAcctOpened: "2023-01-01",
    AcctType: true,
    AcctRefLG: null,
    AcctRefCity: "AJAH",
    AcctRefState: "LAGOS",
    StateId: 24,
    AcctRefCountry: null,
    AcctReference: null,
    AcctPhone: null,
    IsFunded: 1,
    IsVerifyAddress: false,
    Pnd: null,
    Memo: null,
    IsVendorAssigned: true,
    DateAssigned: "2023-10-06T06:59:01.698Z",
    AssignedVendorID: 4,
    AssignedAgentID: null,
    AssignedAgentName: null,
    IsVendorVerified: null,
    DateVerified: null,
    VerifiedByID: null,
    VerifiedByName: null,
    OptionsVendorVerified: null,
    CommentsVendor: null,
    AddressDescription: null,
    AddressVerificationPhotos: [],
    PersonsMet: null,
    DateConfirmed: null,
    IsConfirmed: true,
    ConfirmedById: null,
    OptionsConfirmed: null,
    CommentsBank: null,
    IsVendorPaid: null,
    IsAcctDebited: null,
    SLA: 0,
    BVN: null,
    Email: null,
    AccountBalance: "5000",
    TAT: null,
    AccountType: "INDIVIDUAL",
    JobStatus: "PENDING",
    AddedInfo: null,
    FundingCheckCount: 3,
    CreatedAt: "2023-10-05T14:32:30.055Z",
    UpdatedAt: "2023-10-06T06:59:01.698Z",
    AssignedVendor: {
      VendorID: 4,
      VendorName: "Esther Okon",
      VendorEmail: "eltoshcollection@gmail.com",
      VendorPassword: null,
      VendorRefState: null,
      SLA: 0,
      UserId: "8ed93a58-8393-46b5-b39a-1ae56f47866e",
      Type: "vendor",
      VendorAcctNo: null,
      Approved: true,
      VendorReset: false,
      VendorAddress: null,
      VendorRefSol: null,
      VendorRefLG: null,
      IsDeleted: false,
      AccountType: "INDIVIDUAL",
      CreatedAt: "2023-10-04T13:11:49.328Z",
      UpdatedAt: "2023-10-26T22:03:12.431Z",
      VendorId: null,
    },
    State: {
      Id: 24,
      Name: "LAGOS",
      Code: "LA",
      CreatedAt: "2023-10-03T05:45:44.785Z",
      UpdatedAt: "2023-10-03T05:45:44.785Z",
    },
    VendorJobLedgers: [],
    VendorJobs: [
      {
        Id: 9227,
        VendorId: 4,
        AccountId: 33550,
        StateId: 24,
        Completed: false,
        CreatedAt: "2023-10-06T06:59:01.547Z",
        UpdatedAt: "2023-10-06T06:59:01.547Z",
        Vendor: {
          VendorID: 4,
          VendorName: "Esther Okon",
          VendorEmail: "eltoshcollection@gmail.com",
          VendorPassword: null,
          VendorRefState: null,
          SLA: 0,
          UserId: "8ed93a58-8393-46b5-b39a-1ae56f47866e",
          Type: "vendor",
          VendorAcctNo: null,
          Approved: true,
          VendorReset: false,
          VendorAddress: null,
          VendorRefSol: null,
          VendorRefLG: null,
          IsDeleted: false,
          AccountType: "INDIVIDUAL",
          CreatedAt: "2023-10-04T13:11:49.328Z",
          UpdatedAt: "2023-10-26T22:03:12.431Z",
          VendorId: null,
        },
      },
    ],
    JobRejections: [],
  },

  {
    AcctRefID: 65623,
    CIFID: null,
    AcctNumber: "2576476029",
    AcctName: "Ola Lewis",
    AcctAddress: "3, patrick Wow Estate",
    UpdatedAcctAddress: null,
    NeedsAdditionalInfo: false,
    AcctRefSol: null,
    DateImportFinacle: null,
    DateAcctOpened: "2022-01-01",
    AcctType: true,
    AcctRefLG: null,
    AcctRefCity: "AJAH",
    AcctRefState: "LAGOS",
    StateId: 24,
    AcctRefCountry: null,
    AcctReference: null,
    AcctPhone: null,
    IsFunded: 1,
    IsVerifyAddress: false,
    Pnd: null,
    Memo: null,
    IsVendorAssigned: true,
    DateAssigned: "2023-10-06T06:56:38.237Z",
    AssignedVendorID: 4,
    AssignedAgentID: null,
    AssignedAgentName: null,
    IsVendorVerified: null,
    DateVerified: null,
    VerifiedByID: null,
    VerifiedByName: null,
    OptionsVendorVerified: null,
    CommentsVendor: null,
    AddressDescription: null,
    AddressVerificationPhotos: [],
    PersonsMet: null,
    DateConfirmed: null,
    IsConfirmed: true,
    ConfirmedById: null,
    OptionsConfirmed: null,
    CommentsBank: null,
    IsVendorPaid: null,
    IsAcctDebited: null,
    SLA: 0,
    BVN: null,
    Email: null,
    AccountBalance: "5000",
    TAT: null,
    AccountType: "INDIVIDUAL",
    JobStatus: "PENDING",
    AddedInfo: null,
    FundingCheckCount: 3,
    CreatedAt: "2023-10-05T17:17:00.078Z",
    UpdatedAt: "2023-10-06T06:56:38.237Z",
    AssignedVendor: {
      VendorID: 4,
      VendorName: "Esther Okon",
      VendorEmail: "eltoshcollection@gmail.com",
      VendorPassword: null,
      VendorRefState: null,
      SLA: 0,
      UserId: "8ed93a58-8393-46b5-b39a-1ae56f47866e",
      Type: "vendor",
      VendorAcctNo: null,
      Approved: true,
      VendorReset: false,
      VendorAddress: null,
      VendorRefSol: null,
      VendorRefLG: null,
      IsDeleted: false,
      AccountType: "INDIVIDUAL",
      CreatedAt: "2023-10-04T13:11:49.328Z",
      UpdatedAt: "2023-10-26T22:03:12.431Z",
      VendorId: null,
    },
    State: {
      Id: 24,
      Name: "LAGOS",
      Code: "LA",
      CreatedAt: "2023-10-03T05:45:44.785Z",
      UpdatedAt: "2023-10-03T05:45:44.785Z",
    },
    VendorJobLedgers: [],
    VendorJobs: [
      {
        Id: 9225,
        VendorId: 4,
        AccountId: 65623,
        StateId: 24,
        Completed: false,
        CreatedAt: "2023-10-06T06:56:38.095Z",
        UpdatedAt: "2023-10-06T06:56:38.095Z",
        Vendor: {
          VendorID: 4,
          VendorName: "Esther Okon",
          VendorEmail: "eltoshcollection@gmail.com",
          VendorPassword: null,
          VendorRefState: null,
          SLA: 0,
          UserId: "8ed93a58-8393-46b5-b39a-1ae56f47866e",
          Type: "vendor",
          VendorAcctNo: null,
          Approved: true,
          VendorReset: false,
          VendorAddress: null,
          VendorRefSol: null,
          VendorRefLG: null,
          IsDeleted: false,
          AccountType: "INDIVIDUAL",
          CreatedAt: "2023-10-04T13:11:49.328Z",
          UpdatedAt: "2023-10-26T22:03:12.431Z",
          VendorId: null,
        },
      },
    ],
    JobRejections: [],
  },

  {
    AcctRefID: 26179,
    CIFID: null,
    AcctNumber: "2641698539",
    AcctName: "Yara Martin",
    AcctAddress: "3, patrick Wow Estate",
    UpdatedAcctAddress: null,
    NeedsAdditionalInfo: false,
    AcctRefSol: null,
    DateImportFinacle: null,
    DateAcctOpened: "2022-01-01",
    AcctType: true,
    AcctRefLG: null,
    AcctRefCity: "AJAH",
    AcctRefState: "LAGOS",
    StateId: 24,
    AcctRefCountry: null,
    AcctReference: null,
    AcctPhone: null,
    IsFunded: 1,
    IsVerifyAddress: false,
    Pnd: null,
    Memo: null,
    IsVendorAssigned: true,
    DateAssigned: "2023-10-06T06:55:14.742Z",
    AssignedVendorID: 4,
    AssignedAgentID: null,
    AssignedAgentName: null,
    IsVendorVerified: null,
    DateVerified: null,
    VerifiedByID: null,
    VerifiedByName: null,
    OptionsVendorVerified: null,
    CommentsVendor: null,
    AddressDescription: null,
    AddressVerificationPhotos: [],
    PersonsMet: null,
    DateConfirmed: null,
    IsConfirmed: true,
    ConfirmedById: null,
    OptionsConfirmed: null,
    CommentsBank: null,
    IsVendorPaid: null,
    IsAcctDebited: null,
    SLA: 0,
    BVN: null,
    Email: null,
    AccountBalance: "5000",
    TAT: null,
    AccountType: "INDIVIDUAL",
    JobStatus: "PENDING",
    AddedInfo: null,
    FundingCheckCount: 3,
    CreatedAt: "2023-10-05T13:55:31.561Z",
    UpdatedAt: "2023-10-06T06:55:14.742Z",
    AssignedVendor: {
      VendorID: 4,
      VendorName: "Esther Okon",
      VendorEmail: "eltoshcollection@gmail.com",
      VendorPassword: null,
      VendorRefState: null,
      SLA: 0,
      UserId: "8ed93a58-8393-46b5-b39a-1ae56f47866e",
      Type: "vendor",
      VendorAcctNo: null,
      Approved: true,
      VendorReset: false,
      VendorAddress: null,
      VendorRefSol: null,
      VendorRefLG: null,
      IsDeleted: false,
      AccountType: "INDIVIDUAL",
      CreatedAt: "2023-10-04T13:11:49.328Z",
      UpdatedAt: "2023-10-26T22:03:12.431Z",
      VendorId: null,
    },
    State: {
      Id: 24,
      Name: "LAGOS",
      Code: "LA",
      CreatedAt: "2023-10-03T05:45:44.785Z",
      UpdatedAt: "2023-10-03T05:45:44.785Z",
    },
    VendorJobLedgers: [],
    VendorJobs: [
      {
        Id: 9223,
        VendorId: 4,
        AccountId: 26179,
        StateId: 24,
        Completed: false,
        CreatedAt: "2023-10-06T06:55:14.559Z",
        UpdatedAt: "2023-10-06T06:55:14.559Z",
        Vendor: {
          VendorID: 4,
          VendorName: "Esther Okon",
          VendorEmail: "eltoshcollection@gmail.com",
          VendorPassword: null,
          VendorRefState: null,
          SLA: 0,
          UserId: "8ed93a58-8393-46b5-b39a-1ae56f47866e",
          Type: "vendor",
          VendorAcctNo: null,
          Approved: true,
          VendorReset: false,
          VendorAddress: null,
          VendorRefSol: null,
          VendorRefLG: null,
          IsDeleted: false,
          AccountType: "INDIVIDUAL",
          CreatedAt: "2023-10-04T13:11:49.328Z",
          UpdatedAt: "2023-10-26T22:03:12.431Z",
          VendorId: null,
        },
      },
    ],
    JobRejections: [],
  },

  {
    AcctRefID: 26174,
    CIFID: null,
    AcctNumber: "2897783844",
    AcctName: "Diana Thompson",
    AcctAddress: "3 Alhaja Humuani Memorial Mall, Kudirat Abiola Road",
    UpdatedAcctAddress: null,
    NeedsAdditionalInfo: false,
    AcctRefSol: null,
    DateImportFinacle: null,
    DateAcctOpened: "2023-01-01",
    AcctType: true,
    AcctRefLG: null,
    AcctRefCity: "IKEJA",
    AcctRefState: "LAGOS",
    StateId: 24,
    AcctRefCountry: null,
    AcctReference: null,
    AcctPhone: null,
    IsFunded: 1,
    IsVerifyAddress: false,
    Pnd: null,
    Memo: null,
    IsVendorAssigned: true,
    DateAssigned: "2023-10-06T06:52:40.706Z",
    AssignedVendorID: 4,
    AssignedAgentID: null,
    AssignedAgentName: null,
    IsVendorVerified: null,
    DateVerified: null,
    VerifiedByID: null,
    VerifiedByName: null,
    OptionsVendorVerified: null,
    CommentsVendor: null,
    AddressDescription: null,
    AddressVerificationPhotos: [],
    PersonsMet: null,
    DateConfirmed: null,
    IsConfirmed: true,
    ConfirmedById: null,
    OptionsConfirmed: null,
    CommentsBank: null,
    IsVendorPaid: null,
    IsAcctDebited: null,
    SLA: 0,
    BVN: null,
    Email: null,
    AccountBalance: "6300",
    TAT: null,
    AccountType: "INDIVIDUAL",
    JobStatus: "PENDING",
    AddedInfo: null,
    FundingCheckCount: 3,
    CreatedAt: "2023-10-05T13:55:31.560Z",
    UpdatedAt: "2023-10-06T06:52:40.706Z",
    AssignedVendor: {
      VendorID: 4,
      VendorName: "Esther Okon",
      VendorEmail: "eltoshcollection@gmail.com",
      VendorPassword: null,
      VendorRefState: null,
      SLA: 0,
      UserId: "8ed93a58-8393-46b5-b39a-1ae56f47866e",
      Type: "vendor",
      VendorAcctNo: null,
      Approved: true,
      VendorReset: false,
      VendorAddress: null,
      VendorRefSol: null,
      VendorRefLG: null,
      IsDeleted: false,
      AccountType: "INDIVIDUAL",
      CreatedAt: "2023-10-04T13:11:49.328Z",
      UpdatedAt: "2023-10-26T22:03:12.431Z",
      VendorId: null,
    },
    State: {
      Id: 24,
      Name: "LAGOS",
      Code: "LA",
      CreatedAt: "2023-10-03T05:45:44.785Z",
      UpdatedAt: "2023-10-03T05:45:44.785Z",
    },
    VendorJobLedgers: [],
    VendorJobs: [
      {
        Id: 9221,
        VendorId: 4,
        AccountId: 26174,
        StateId: 24,
        Completed: false,
        CreatedAt: "2023-10-06T06:52:40.562Z",
        UpdatedAt: "2023-10-06T06:52:40.562Z",
        Vendor: {
          VendorID: 4,
          VendorName: "Esther Okon",
          VendorEmail: "eltoshcollection@gmail.com",
          VendorPassword: null,
          VendorRefState: null,
          SLA: 0,
          UserId: "8ed93a58-8393-46b5-b39a-1ae56f47866e",
          Type: "vendor",
          VendorAcctNo: null,
          Approved: true,
          VendorReset: false,
          VendorAddress: null,
          VendorRefSol: null,
          VendorRefLG: null,
          IsDeleted: false,
          AccountType: "INDIVIDUAL",
          CreatedAt: "2023-10-04T13:11:49.328Z",
          UpdatedAt: "2023-10-26T22:03:12.431Z",
          VendorId: null,
        },
      },
    ],
    JobRejections: [],
  },

  {
    AcctRefID: 26167,
    CIFID: null,
    AcctNumber: "2127822207",
    AcctName: "Xavier Davis",
    AcctAddress: "Akanbi Adeniran Street,Oko Oba",
    UpdatedAcctAddress: null,
    NeedsAdditionalInfo: false,
    AcctRefSol: null,
    DateImportFinacle: null,
    DateAcctOpened: "2022-01-01",
    AcctType: true,
    AcctRefLG: null,
    AcctRefCity: "AGEGE",
    AcctRefState: "LAGOS",
    StateId: 24,
    AcctRefCountry: null,
    AcctReference: null,
    AcctPhone: null,
    IsFunded: 1,
    IsVerifyAddress: false,
    Pnd: null,
    Memo: null,
    IsVendorAssigned: true,
    DateAssigned: "2023-10-06T06:51:23.908Z",
    AssignedVendorID: 4,
    AssignedAgentID: null,
    AssignedAgentName: null,
    IsVendorVerified: null,
    DateVerified: null,
    VerifiedByID: null,
    VerifiedByName: null,
    OptionsVendorVerified: null,
    CommentsVendor: null,
    AddressDescription: null,
    AddressVerificationPhotos: [],
    PersonsMet: null,
    DateConfirmed: null,
    IsConfirmed: true,
    ConfirmedById: null,
    OptionsConfirmed: null,
    CommentsBank: null,
    IsVendorPaid: null,
    IsAcctDebited: null,
    SLA: 0,
    BVN: null,
    Email: null,
    AccountBalance: "700",
    TAT: null,
    AccountType: "INDIVIDUAL",
    JobStatus: "PENDING",
    AddedInfo: null,
    FundingCheckCount: 3,
    CreatedAt: "2023-10-05T13:55:31.560Z",
    UpdatedAt: "2023-10-06T06:51:23.908Z",
    AssignedVendor: {
      VendorID: 4,
      VendorName: "Esther Okon",
      VendorEmail: "eltoshcollection@gmail.com",
      VendorPassword: null,
      VendorRefState: null,
      SLA: 0,
      UserId: "8ed93a58-8393-46b5-b39a-1ae56f47866e",
      Type: "vendor",
      VendorAcctNo: null,
      Approved: true,
      VendorReset: false,
      VendorAddress: null,
      VendorRefSol: null,
      VendorRefLG: null,
      IsDeleted: false,
      AccountType: "INDIVIDUAL",
      CreatedAt: "2023-10-04T13:11:49.328Z",
      UpdatedAt: "2023-10-26T22:03:12.431Z",
      VendorId: null,
    },
    State: {
      Id: 24,
      Name: "LAGOS",
      Code: "LA",
      CreatedAt: "2023-10-03T05:45:44.785Z",
      UpdatedAt: "2023-10-03T05:45:44.785Z",
    },
    VendorJobLedgers: [],
    VendorJobs: [
      {
        Id: 9219,
        VendorId: 4,
        AccountId: 26167,
        StateId: 24,
        Completed: false,
        CreatedAt: "2023-10-06T06:51:23.762Z",
        UpdatedAt: "2023-10-06T06:51:23.762Z",
        Vendor: {
          VendorID: 4,
          VendorName: "Esther Okon",
          VendorEmail: "eltoshcollection@gmail.com",
          VendorPassword: null,
          VendorRefState: null,
          SLA: 0,
          UserId: "8ed93a58-8393-46b5-b39a-1ae56f47866e",
          Type: "vendor",
          VendorAcctNo: null,
          Approved: true,
          VendorReset: false,
          VendorAddress: null,
          VendorRefSol: null,
          VendorRefLG: null,
          IsDeleted: false,
          AccountType: "INDIVIDUAL",
          CreatedAt: "2023-10-04T13:11:49.328Z",
          UpdatedAt: "2023-10-26T22:03:12.431Z",
          VendorId: null,
        },
      },
    ],
    JobRejections: [],
  },
  {
    AcctRefID: 26156,
    CIFID: null,
    AcctNumber: "2760522032",
    AcctName: "Ryan John",
    AcctAddress: "1649A,7th Avenue",
    UpdatedAcctAddress: null,
    NeedsAdditionalInfo: false,
    AcctRefSol: null,
    DateImportFinacle: null,
    DateAcctOpened: "2023-01-01",
    AcctType: true,
    AcctRefLG: null,
    AcctRefCity: "FESTAC TOWN",
    AcctRefState: "LAGOS",
    StateId: 24,
    AcctRefCountry: null,
    AcctReference: null,
    AcctPhone: null,
    IsFunded: 1,
    IsVerifyAddress: false,
    Pnd: null,
    Memo: null,
    IsVendorAssigned: true,
    DateAssigned: "2023-10-06T06:49:55.658Z",
    AssignedVendorID: 4,
    AssignedAgentID: null,
    AssignedAgentName: null,
    IsVendorVerified: null,
    DateVerified: null,
    VerifiedByID: null,
    VerifiedByName: null,
    OptionsVendorVerified: null,
    CommentsVendor: null,
    AddressDescription: null,
    AddressVerificationPhotos: [],
    PersonsMet: null,
    DateConfirmed: null,
    IsConfirmed: true,
    ConfirmedById: null,
    OptionsConfirmed: null,
    CommentsBank: null,
    IsVendorPaid: null,
    IsAcctDebited: null,
    SLA: 0,
    BVN: null,
    Email: null,
    AccountBalance: "1000",
    TAT: null,
    AccountType: "INDIVIDUAL",
    JobStatus: "PENDING",
    AddedInfo: null,
    FundingCheckCount: 3,
    CreatedAt: "2023-10-05T13:55:30.088Z",
    UpdatedAt: "2023-10-06T06:49:55.658Z",
    AssignedVendor: {
      VendorID: 4,
      VendorName: "Esther Okon",
      VendorEmail: "eltoshcollection@gmail.com",
      VendorPassword: null,
      VendorRefState: null,
      SLA: 0,
      UserId: "8ed93a58-8393-46b5-b39a-1ae56f47866e",
      Type: "vendor",
      VendorAcctNo: null,
      Approved: true,
      VendorReset: false,
      VendorAddress: null,
      VendorRefSol: null,
      VendorRefLG: null,
      IsDeleted: false,
      AccountType: "INDIVIDUAL",
      CreatedAt: "2023-10-04T13:11:49.328Z",
      UpdatedAt: "2023-10-26T22:03:12.431Z",
      VendorId: null,
    },
    State: {
      Id: 24,
      Name: "LAGOS",
      Code: "LA",
      CreatedAt: "2023-10-03T05:45:44.785Z",
      UpdatedAt: "2023-10-03T05:45:44.785Z",
    },
    VendorJobLedgers: [],
    VendorJobs: [
      {
        Id: 9217,
        VendorId: 4,
        AccountId: 26156,
        StateId: 24,
        Completed: false,
        CreatedAt: "2023-10-06T06:49:55.494Z",
        UpdatedAt: "2023-10-06T06:49:55.494Z",
        Vendor: {
          VendorID: 4,
          VendorName: "Esther Okon",
          VendorEmail: "eltoshcollection@gmail.com",
          VendorPassword: null,
          VendorRefState: null,
          SLA: 0,
          UserId: "8ed93a58-8393-46b5-b39a-1ae56f47866e",
          Type: "vendor",
          VendorAcctNo: null,
          Approved: true,
          VendorReset: false,
          VendorAddress: null,
          VendorRefSol: null,
          VendorRefLG: null,
          IsDeleted: false,
          AccountType: "INDIVIDUAL",
          CreatedAt: "2023-10-04T13:11:49.328Z",
          UpdatedAt: "2023-10-26T22:03:12.431Z",
          VendorId: null,
        },
      },
    ],
    JobRejections: [],
  },
  {
    AcctRefID: 26154,
    CIFID: null,
    AcctNumber: "2776907140",
    AcctName: "Victoria Martinez",
    AcctAddress: "10, Christ the king Estate",
    UpdatedAcctAddress: null,
    NeedsAdditionalInfo: false,
    AcctRefSol: null,
    DateImportFinacle: null,
    DateAcctOpened: "2021-12-31",
    AcctType: true,
    AcctRefLG: null,
    AcctRefCity: "IKOYI",
    AcctRefState: "LAGOS",
    StateId: 24,
    AcctRefCountry: null,
    AcctReference: null,
    AcctPhone: null,
    IsFunded: 1,
    IsVerifyAddress: false,
    Pnd: null,
    Memo: null,
    IsVendorAssigned: true,
    DateAssigned: "2023-10-06T06:47:59.806Z",
    AssignedVendorID: 4,
    AssignedAgentID: null,
    AssignedAgentName: null,
    IsVendorVerified: null,
    DateVerified: null,
    VerifiedByID: null,
    VerifiedByName: null,
    OptionsVendorVerified: null,
    CommentsVendor: null,
    AddressDescription: null,
    AddressVerificationPhotos: [],
    PersonsMet: null,
    DateConfirmed: null,
    IsConfirmed: true,
    ConfirmedById: null,
    OptionsConfirmed: null,
    CommentsBank: null,
    IsVendorPaid: null,
    IsAcctDebited: null,
    SLA: 0,
    BVN: null,
    Email: null,
    AccountBalance: "5088",
    TAT: null,
    AccountType: "INDIVIDUAL",
    JobStatus: "PENDING",
    AddedInfo: null,
    FundingCheckCount: 3,
    CreatedAt: "2023-10-05T13:55:30.088Z",
    UpdatedAt: "2023-10-06T06:47:59.806Z",
    AssignedVendor: {
      VendorID: 4,
      VendorName: "Esther Okon",
      VendorEmail: "eltoshcollection@gmail.com",
      VendorPassword: null,
      VendorRefState: null,
      SLA: 0,
      UserId: "8ed93a58-8393-46b5-b39a-1ae56f47866e",
      Type: "vendor",
      VendorAcctNo: null,
      Approved: true,
      VendorReset: false,
      VendorAddress: null,
      VendorRefSol: null,
      VendorRefLG: null,
      IsDeleted: false,
      AccountType: "INDIVIDUAL",
      CreatedAt: "2023-10-04T13:11:49.328Z",
      UpdatedAt: "2023-10-26T22:03:12.431Z",
      VendorId: null,
    },
    State: {
      Id: 24,
      Name: "LAGOS",
      Code: "LA",
      CreatedAt: "2023-10-03T05:45:44.785Z",
      UpdatedAt: "2023-10-03T05:45:44.785Z",
    },
    VendorJobLedgers: [],
    VendorJobs: [
      {
        Id: 9215,
        VendorId: 4,
        AccountId: 26154,
        StateId: 24,
        Completed: false,
        CreatedAt: "2023-10-06T06:47:59.619Z",
        UpdatedAt: "2023-10-06T06:47:59.619Z",
        Vendor: {
          VendorID: 4,
          VendorName: "Esther Okon",
          VendorEmail: "eltoshcollection@gmail.com",
          VendorPassword: null,
          VendorRefState: null,
          SLA: 0,
          UserId: "8ed93a58-8393-46b5-b39a-1ae56f47866e",
          Type: "vendor",
          VendorAcctNo: null,
          Approved: true,
          VendorReset: false,
          VendorAddress: null,
          VendorRefSol: null,
          VendorRefLG: null,
          IsDeleted: false,
          AccountType: "INDIVIDUAL",
          CreatedAt: "2023-10-04T13:11:49.328Z",
          UpdatedAt: "2023-10-26T22:03:12.431Z",
          VendorId: null,
        },
      },
    ],
    JobRejections: [],
  },
  {
    AcctRefID: 42703,
    CIFID: null,
    AcctNumber: "2380773578",
    AcctName: "Yara John",
    AcctAddress: "6 oshodi drive, Penisual Graden Estate",
    UpdatedAcctAddress: null,
    NeedsAdditionalInfo: false,
    AcctRefSol: null,
    DateImportFinacle: null,
    DateAcctOpened: "2023-01-01",
    AcctType: true,
    AcctRefLG: null,
    AcctRefCity: "IKOYI",
    AcctRefState: "LAGOS",
    StateId: 24,
    AcctRefCountry: null,
    AcctReference: null,
    AcctPhone: null,
    IsFunded: 1,
    IsVerifyAddress: false,
    Pnd: null,
    Memo: null,
    IsVendorAssigned: true,
    DateAssigned: "2023-10-06T06:46:12.804Z",
    AssignedVendorID: 4,
    AssignedAgentID: null,
    AssignedAgentName: null,
    IsVendorVerified: null,
    DateVerified: null,
    VerifiedByID: null,
    VerifiedByName: null,
    OptionsVendorVerified: null,
    CommentsVendor: null,
    AddressDescription: null,
    AddressVerificationPhotos: [],
    PersonsMet: null,
    DateConfirmed: null,
    IsConfirmed: true,
    ConfirmedById: null,
    OptionsConfirmed: null,
    CommentsBank: null,
    IsVendorPaid: null,
    IsAcctDebited: null,
    SLA: 0,
    BVN: null,
    Email: null,
    AccountBalance: "700005",
    TAT: null,
    AccountType: "INDIVIDUAL",
    JobStatus: "PENDING",
    AddedInfo: null,
    FundingCheckCount: 3,
    CreatedAt: "2023-10-05T15:25:30.088Z",
    UpdatedAt: "2023-10-06T06:46:12.804Z",
    AssignedVendor: {
      VendorID: 4,
      VendorName: "Esther Okon",
      VendorEmail: "eltoshcollection@gmail.com",
      VendorPassword: null,
      VendorRefState: null,
      SLA: 0,
      UserId: "8ed93a58-8393-46b5-b39a-1ae56f47866e",
      Type: "vendor",
      VendorAcctNo: null,
      Approved: true,
      VendorReset: false,
      VendorAddress: null,
      VendorRefSol: null,
      VendorRefLG: null,
      IsDeleted: false,
      AccountType: "INDIVIDUAL",
      CreatedAt: "2023-10-04T13:11:49.328Z",
      UpdatedAt: "2023-10-26T22:03:12.431Z",
      VendorId: null,
    },
    State: {
      Id: 24,
      Name: "LAGOS",
      Code: "LA",
      CreatedAt: "2023-10-03T05:45:44.785Z",
      UpdatedAt: "2023-10-03T05:45:44.785Z",
    },
    VendorJobLedgers: [],
    VendorJobs: [
      {
        Id: 9213,
        VendorId: 4,
        AccountId: 42703,
        StateId: 24,
        Completed: false,
        CreatedAt: "2023-10-06T06:46:12.589Z",
        UpdatedAt: "2023-10-06T06:46:12.589Z",
        Vendor: {
          VendorID: 4,
          VendorName: "Esther Okon",
          VendorEmail: "eltoshcollection@gmail.com",
          VendorPassword: null,
          VendorRefState: null,
          SLA: 0,
          UserId: "8ed93a58-8393-46b5-b39a-1ae56f47866e",
          Type: "vendor",
          VendorAcctNo: null,
          Approved: true,
          VendorReset: false,
          VendorAddress: null,
          VendorRefSol: null,
          VendorRefLG: null,
          IsDeleted: false,
          AccountType: "INDIVIDUAL",
          CreatedAt: "2023-10-04T13:11:49.328Z",
          UpdatedAt: "2023-10-26T22:03:12.431Z",
          VendorId: null,
        },
      },
    ],
    JobRejections: [],
  },
  {
    AcctRefID: 64327,
    CIFID: null,
    AcctNumber: "2339796477",
    AcctName: "Katherine Buhari",
    AcctAddress: " F9 Lagos City Mall, opp. Muson Center",
    UpdatedAcctAddress: null,
    NeedsAdditionalInfo: false,
    AcctRefSol: null,
    DateImportFinacle: null,
    DateAcctOpened: "2023-01-01",
    AcctType: true,
    AcctRefLG: null,
    AcctRefCity: "LAGOS ISLAND",
    AcctRefState: "LAGOS",
    StateId: 24,
    AcctRefCountry: null,
    AcctReference: null,
    AcctPhone: null,
    IsFunded: 1,
    IsVerifyAddress: false,
    Pnd: null,
    Memo: null,
    IsVendorAssigned: true,
    DateAssigned: "2023-10-06T06:44:49.752Z",
    AssignedVendorID: 4,
    AssignedAgentID: null,
    AssignedAgentName: null,
    IsVendorVerified: null,
    DateVerified: null,
    VerifiedByID: null,
    VerifiedByName: null,
    OptionsVendorVerified: null,
    CommentsVendor: null,
    AddressDescription: null,
    AddressVerificationPhotos: [],
    PersonsMet: null,
    DateConfirmed: null,
    IsConfirmed: true,
    ConfirmedById: null,
    OptionsConfirmed: null,
    CommentsBank: null,
    IsVendorPaid: null,
    IsAcctDebited: null,
    SLA: 0,
    BVN: null,
    Email: null,
    AccountBalance: "700",
    TAT: null,
    AccountType: "INDIVIDUAL",
    JobStatus: "PENDING",
    AddedInfo: null,
    FundingCheckCount: 3,
    CreatedAt: "2023-10-05T17:08:40.300Z",
    UpdatedAt: "2023-10-06T06:44:49.752Z",
    AssignedVendor: {
      VendorID: 4,
      VendorName: "Esther Okon",
      VendorEmail: "eltoshcollection@gmail.com",
      VendorPassword: null,
      VendorRefState: null,
      SLA: 0,
      UserId: "8ed93a58-8393-46b5-b39a-1ae56f47866e",
      Type: "vendor",
      VendorAcctNo: null,
      Approved: true,
      VendorReset: false,
      VendorAddress: null,
      VendorRefSol: null,
      VendorRefLG: null,
      IsDeleted: false,
      AccountType: "INDIVIDUAL",
      CreatedAt: "2023-10-04T13:11:49.328Z",
      UpdatedAt: "2023-10-26T22:03:12.431Z",
      VendorId: null,
    },
    State: {
      Id: 24,
      Name: "LAGOS",
      Code: "LA",
      CreatedAt: "2023-10-03T05:45:44.785Z",
      UpdatedAt: "2023-10-03T05:45:44.785Z",
    },
    VendorJobLedgers: [],
    VendorJobs: [
      {
        Id: 9211,
        VendorId: 4,
        AccountId: 64327,
        StateId: 24,
        Completed: false,
        CreatedAt: "2023-10-06T06:44:49.522Z",
        UpdatedAt: "2023-10-06T06:44:49.522Z",
        Vendor: {
          VendorID: 4,
          VendorName: "Esther Okon",
          VendorEmail: "eltoshcollection@gmail.com",
          VendorPassword: null,
          VendorRefState: null,
          SLA: 0,
          UserId: "8ed93a58-8393-46b5-b39a-1ae56f47866e",
          Type: "vendor",
          VendorAcctNo: null,
          Approved: true,
          VendorReset: false,
          VendorAddress: null,
          VendorRefSol: null,
          VendorRefLG: null,
          IsDeleted: false,
          AccountType: "INDIVIDUAL",
          CreatedAt: "2023-10-04T13:11:49.328Z",
          UpdatedAt: "2023-10-26T22:03:12.431Z",
          VendorId: null,
        },
      },
    ],
    JobRejections: [],
  },
  {
    AcctRefID: 26140,
    CIFID: null,
    AcctNumber: "2396406252",
    AcctName: "Jack Brown",
    AcctAddress: "13 Dolphin Estate",
    UpdatedAcctAddress: null,
    NeedsAdditionalInfo: false,
    AcctRefSol: null,
    DateImportFinacle: null,
    DateAcctOpened: "2023-01-01",
    AcctType: true,
    AcctRefLG: null,
    AcctRefCity: "AJAH",
    AcctRefState: "LAGOS",
    StateId: 24,
    AcctRefCountry: null,
    AcctReference: null,
    AcctPhone: null,
    IsFunded: 1,
    IsVerifyAddress: false,
    Pnd: null,
    Memo: null,
    IsVendorAssigned: true,
    DateAssigned: "2023-10-06T06:43:13.290Z",
    AssignedVendorID: 4,
    AssignedAgentID: null,
    AssignedAgentName: null,
    IsVendorVerified: null,
    DateVerified: null,
    VerifiedByID: null,
    VerifiedByName: null,
    OptionsVendorVerified: null,
    CommentsVendor: null,
    AddressDescription: null,
    AddressVerificationPhotos: [],
    PersonsMet: null,
    DateConfirmed: null,
    IsConfirmed: true,
    ConfirmedById: null,
    OptionsConfirmed: null,
    CommentsBank: null,
    IsVendorPaid: null,
    IsAcctDebited: null,
    SLA: 0,
    BVN: null,
    Email: null,
    AccountBalance: "5000",
    TAT: null,
    AccountType: "INDIVIDUAL",
    JobStatus: "PENDING",
    AddedInfo: null,
    FundingCheckCount: 3,
    CreatedAt: "2023-10-05T13:55:30.085Z",
    UpdatedAt: "2023-10-06T06:43:13.290Z",
    AssignedVendor: {
      VendorID: 4,
      VendorName: "Esther Okon",
      VendorEmail: "eltoshcollection@gmail.com",
      VendorPassword: null,
      VendorRefState: null,
      SLA: 0,
      UserId: "8ed93a58-8393-46b5-b39a-1ae56f47866e",
      Type: "vendor",
      VendorAcctNo: null,
      Approved: true,
      VendorReset: false,
      VendorAddress: null,
      VendorRefSol: null,
      VendorRefLG: null,
      IsDeleted: false,
      AccountType: "INDIVIDUAL",
      CreatedAt: "2023-10-04T13:11:49.328Z",
      UpdatedAt: "2023-10-26T22:03:12.431Z",
      VendorId: null,
    },
    State: {
      Id: 24,
      Name: "LAGOS",
      Code: "LA",
      CreatedAt: "2023-10-03T05:45:44.785Z",
      UpdatedAt: "2023-10-03T05:45:44.785Z",
    },
    VendorJobLedgers: [],
    VendorJobs: [
      {
        Id: 9209,
        VendorId: 4,
        AccountId: 26140,
        StateId: 24,
        Completed: false,
        CreatedAt: "2023-10-06T06:43:13.119Z",
        UpdatedAt: "2023-10-06T06:43:13.119Z",
        Vendor: {
          VendorID: 4,
          VendorName: "Esther Okon",
          VendorEmail: "eltoshcollection@gmail.com",
          VendorPassword: null,
          VendorRefState: null,
          SLA: 0,
          UserId: "8ed93a58-8393-46b5-b39a-1ae56f47866e",
          Type: "vendor",
          VendorAcctNo: null,
          Approved: true,
          VendorReset: false,
          VendorAddress: null,
          VendorRefSol: null,
          VendorRefLG: null,
          IsDeleted: false,
          AccountType: "INDIVIDUAL",
          CreatedAt: "2023-10-04T13:11:49.328Z",
          UpdatedAt: "2023-10-26T22:03:12.431Z",
          VendorId: null,
        },
      },
    ],
    JobRejections: [],
  },
]; // 10 Jobs

export const dashboardMock = {
  loading: false,
  filter: "30_DAYS",
  stats: {
    jobsAwaitingPayment: 1,
    jobsAwaitingBranchConfirmation: 1,
    jobsPendingAcknowledgement: 0,
    pendingJobs: 38,
    totalJobs: 70,
    percentageClosedJobs: 8.571428571428571,
    percentageRejectedJobs: 0,
    totalAmountPaidForJobs: 95000,
  },
};

export const paginationMock = {
  page: 1,
  pageSize: 10,
  total: 10,
  length: 10,
  search: "",
};
