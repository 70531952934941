/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from "@material-tailwind/react";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import { Button, Tooltip } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import PrintButton from "../../components/others/Print";
import SelectButton from "../../components/others/SelectBtn";
import TableLayout from "../../components/table/CustomTable";
import HomeLayout from "../../layouts/Home";
import { AppType, Roles, getRole, handleCopy } from "../../utils/helper";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Search from "../../components/forms/Search";
import ConfirmationModal from "../../components/modals/confirmationModal";
import CustomModal from "../../components/modals/Index";
import { getAuthData, resetPassword } from "../../features/auth/authSlice";
import {
  closeModal,
  getDefaultState,
  openModal,
  setPrintMod,
} from "../../features/defaultSlice";
import {
  deleteUser,
  getEmployeesData,
  updateUser,
} from "../../features/employee/employee";
import {
  fetchVendors,
  getVendorsData,
  setCurrentData,
  setPagination,
  setPayload,
} from "../../features/vendor/vendor";
import config from "../../utils/config";
import { ICopy } from "../../utils/icons.utils";
import ViewVendor from "./ViewVendor";

const RegisteredVendor = () => {
  const tableRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch(),
    { appType } = useSelector(getAuthData),
    { modalLoading } = useSelector(getEmployeesData),
    [filter, setFilter] = useState({
      status: "",
    }),
    {
      vendors,
      currentData,
      loading,
      pagination,
      downloadPdfUrl,
      downloadExcelUrl,
    } = useSelector(getVendorsData),
    { printMod, modal } = useSelector(getDefaultState);

  const options = useMemo(() => {
    let type = "&type=vendor",
      query = `?searchTerm=${pagination.search}&page=${pagination.page}&pageSize=${pagination.pageSize}&deleted=${pagination.deleted}`;
    if (pagination.status === "") query += "&status=true&status=false";
    else query += `&status=${pagination.status}`;
    if (appType === AppType.cs) type = "&type=solicitor";

    return [
      {
        name: "PDF",
        url: downloadPdfUrl + query + type,
      },
      {
        name: "CSV",
        url: downloadExcelUrl + query + type,
      },
    ];
  }, [
    pagination.pageSize,
    pagination.search,
    pagination.page,
    pagination.status,
    pagination.deleted,
  ]);

  useEffect(() => {
    dispatch(setPagination({ page: 1, search: "" }));
    dispatch(setPrintMod({ pageTitle: "Registered Vendor" }));
  }, [pagination.deleted]);

  const handleSearch = ({ target: { value } }) => {
    dispatch(setPagination({ ...pagination, search: value }));
  };

  useEffect(() => {
    let cb = () => {};
    if (pagination?.search) {
      dispatch(setPagination({ page: 1 }));
      cb = setTimeout(
        (_) => (async () => await dispatch(fetchVendors()))(),
        700
      );
    } else cb = (async () => await dispatch(fetchVendors()))();

    return () => {
      clearTimeout(cb);
    };
  }, [
    pagination.search,
    pagination.page,
    pagination.pageSize,
    pagination.status,
    pagination.deleted,
  ]);

  let data = vendors?.map?.((d, i) => {
    let color =
      d?.SLA > 0 && d?.SLA <= 30
        ? "#D70900"
        : d?.SLA > 30 && d?.SLA <= 60
        ? "#F79E1B"
        : d?.SLA > 60
        ? "#27AE60"
        : "#777";

    const model = {
      "Vendor Name": d?.VendorName || "N/A",
      "Preferred Name": d?.VendorPreferredName || "N/A",
      "Vendor’s Email Address": d?.VendorEmail || "N/A",
      // "Account Number": d?.VendorAcctNo ?? "N/A",
      "SLA Compliance":
        (
          <span className="flex items-center">
            <span
              id="circle__"
              className="rounded-full inline-block mr-2"
              style={{ border: `7px solid ${color}` }}
            ></span>
            <span>{d?.SLA + "%"}</span>
          </span>
        ) ?? "N/A",
      Status: d?.Approved ? "Active" : "Inactive",
      _data: d,
    };
    return model;
  });

  const dropdownData = {
    visible: true,
    type: "dropdown",
    icon: <MoreHorizIcon />,
    data: [
      {
        text: "View",
        action: (_, state) => {
          dispatch(setCurrentData(state));
          dispatch(openModal("open"));
        },
      },
      {
        text: "Unlock",
        condition: (state) =>
          state?.User?.Status === false && !state?.IsDeleted,
        action: (_, state) => {
          dispatch(openModal("close"));
          dispatch(
            setCurrentData({
              userId: state.UserId,
              status: !state?.User?.Status,
            })
          );
        },
      },
      {
        text: "Lock",
        condition: (state) => state?.User?.Status === true && !state?.IsDeleted,
        action: (_, state) => {
          dispatch(openModal("close"));
          dispatch(
            setCurrentData({
              userId: state.UserId,
              status: !state?.User?.Status,
            })
          );
        },
      },
      {
        text: "Edit",
        condition: (a) => !a?.IsDeleted,
        action: (_, state) => {
          const {
            User,
            VendorEmail,
            MapStateVendors,
            VendorAcctNo,
            VendorPreferredName,
            VendorName,
          } = state;
          dispatch(
            setPayload({
              firstName: User.firstName,
              vendorPreferredName: VendorPreferredName,
              companyName: VendorName,
              lastName: User.lastName,
              email: VendorEmail,
              accountNumber: VendorAcctNo,
              stateIds: MapStateVendors?.map((vendor) => vendor?.State?.Name),
            })
          );
          navigate(config.routes.createVendor, { state });
        },
      },
      {
        text: "Delete",
        condition: (a) => !a?.IsDeleted,
        action: (_, state) => {
          dispatch(openModal("delete"));
          dispatch(setCurrentData({ id: state?.UserId }));
        },
      },
      {
        text: "Reset Password",
        condition: (a) => !a?.IsDeleted,
        action: (_, state) => {
          dispatch(openModal("new"));
          dispatch(setCurrentData(state));
        },
      },
    ],
  };

  const statusOption = useMemo(() => {
    const result = [
      { name: "Active", url: "#", id: true },
      { name: "Inactive", url: "#", id: false },
    ];
    filter.status && result.unshift({ name: "All", id: "", url: "#" });

    return result;
  }, [filter.status]);

  return (
    <HomeLayout
      {...{
        handleSearch,
        value: pagination.search,
      }}
    >
      <div className="flex items-end flex-wrap gap-2 justify-between">
        <div className="">
          <Typography className="text-[24px] font-[500]">
            Registered Vendors
          </Typography>
          <Typography className="text-[18px] font-[400]">
            Vendors registered by bank.
          </Typography>
        </div>
        <div className="flex flex-wrap md:justify-end gap-4 md:mt-0 mt-3">
          <button
            onClick={(_) => handleCopy(data)}
            className="h-[2.3rem] flex  items-center rounded-md px-3 border-2 border-[#E6E6E6] text-sm font-semibold text-dark_red "
          >
            <img src={ICopy} alt="ICopy" />
            Copy
          </button>
          <PrintButton
            {...{ componentRef: tableRef, documentTitle: printMod.pageTitle }}
          />
          <SelectButton
            btTextClass="bg-dark_red text-white hover:bg-light_red"
            options={options}
            title="Download"
          />

          <SelectButton
            btTextClass="bg-white text-dark_red border border-[#F8F7F7]"
            options={statusOption}
            title={filter.status || "Status"}
            download={false}
            onClick={(status) => {
              setFilter({
                ...filter,
                status: status?.id === "" ? "" : status?.name,
              });
              dispatch(
                setPagination({
                  status: status?.id,
                })
              );
            }}
          />

          <Button
            component={Link}
            variant="outlined"
            to={config.routes.createVendor}
            color="error"
            type="button"
            sx={{ textTransform: "capitalize" }}
            className="inline-flex h-[2.3rem] items-center rounded-md px-3  text-sm font-semibold bg-dark_red text-white hover:bg-light_red "
          >
            Create Vendor
          </Button>
        </div>
      </div>

      <br />
      <div className="flex justify-between items-center gap-5">
        <div className="flex">
          <Tooltip
            className="py-0 h-10"
            title="Present Vendors"
            placement="top"
          >
            <span>
              <button
                onClick={(_) => {
                  dispatch(setPagination({ deleted: false }));
                }}
                className={`px-2 py-[6px] hover:border-[#D70900] py- border ${
                  !pagination.deleted
                    ? "bg-[#D70900] text-white"
                    : "text-[#D70900]"
                }`}
              >
                <PeopleAltIcon />
                <span className="text-[15px] ml-1">Present</span>
              </button>
            </span>
          </Tooltip>

          <Tooltip
            className="py-0 h-10"
            title="Deleted Vendors"
            placement="top"
          >
            <span>
              <button
                onClick={(_) => {
                  dispatch(setPagination({ deleted: true }));
                }}
                className={`px-2 py-[6px] hover:border-[#D70900] border ${
                  pagination.deleted
                    ? "bg-[#D70900] text-white"
                    : "text-[#D70900]"
                }`}
              >
                <PersonRemoveIcon />
                <span className="text-[15px] ml-1">Deleted</span>
              </button>
            </span>
          </Tooltip>
        </div>
        <Search
          {...{
            value: pagination.search,
            onSearch: handleSearch,
            width: "303px",
            placeholder: "Search by vendor's name...",
          }}
        />
      </div>

      <div className="">
        <TableLayout
          {...{
            printMod: { ...printMod, ref: tableRef },
            pagination,
            setPagination,
            isLoading: loading,
            data,
            xclass: "",
            action:
              [Roles.admin, Roles.superAdmin].includes(getRole()) &&
              dropdownData,
          }}
        />
      </div>

      <CustomModal
        {...{
          title: "View Vendor",
          open: modal.open,
          close: (_) => dispatch(closeModal("open")),
        }}
      >
        <ViewVendor
          {...{
            data: currentData,
            closeModal: (_) => dispatch(closeModal("open")),
          }}
        />
      </CustomModal>
      <ConfirmationModal
        {...{
          open: modal.delete,
          close: (_) => dispatch(closeModal("delete")),
          refresh: (_) => dispatch(fetchVendors()),
          data: currentData,
          btnTexts: ["Cancel", "Delete"],
          title: "Delete User",
          loading: modalLoading,
          subtitle: "Are you sure you want to delete this vendor?",
          action: deleteUser,
        }}
      />
      <ConfirmationModal
        {...{
          open: modal.new,
          close: (_) => dispatch(closeModal("new")),
          refresh: (_) => dispatch(fetchVendors()),
          data: { email: currentData.VendorEmail },
          btnTexts: ["No", "Yes"],
          title: "Reset Password",
          loading: modalLoading,
          subtitle: (
            <>
              <p>This action will reset this users' password immediately</p>
              <p>and send the new one to their email. Continue?</p>
            </>
          ),
          action: resetPassword,
        }}
      />

      <ConfirmationModal
        {...{
          open: modal.close,
          close: (_) => dispatch(closeModal("close")),
          refresh: (_) => dispatch(fetchVendors()),
          data: currentData,
          btnTexts: ["Cancel", currentData?.status ? "Unlock" : "Lock"],
          title: currentData?.status ? "Unlock" : "Lock",
          loading: modalLoading,
          subtitle: `Are you sure you want to ${
            currentData?.status ? "unlock" : "lock"
          } this vendor?`,
          action: updateUser,
        }}
      />
    </HomeLayout>
  );
};

export default RegisteredVendor;
